import {Auth} from '../components/Auth';

const Service = {

    get(url, params, cb=null){
        params.Auth = Auth;

        fetch(`${process.env.REACT_APP_API}${url}`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            //headers: {'Content-Type':'application/x-www-form-urlencoded'},
            // Content-Type: text/html; charset=utf-8
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {
                cb(error);
                console.log('error', error);
            }
        )
    }

}

export default Service;