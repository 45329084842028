import React, { Component } from 'react';
import Service from '../components/Service';
import Popup from "reactjs-popup";
import duix from 'duix';
import Search from '../components/Search';

// getAllBusinessAssociates,
// getAllCustomers
// getAllCities
// getAllStores
// getAllProducts

class DataSet extends Component {

    constructor(props){
        super(props);
        this.state = {
            customerId: 'all',
            storeId: 'all',
            productId: 'all',
            straussCode: 'all',
            businessAssociateId: 'all',
            businessAssociateName: 'all',
            customers: [],
            selectedCustomer: 'all',
            stores: [],
            selectedStore: 'all',
            type: 'sell-out',
            name: 'Data set 1',
            canceled: '0',
            selectedProducts: [],
            class: '',
            visibilityIcon: <i class="far fa-eye"></i>,
            subBrand: 'all',
            business_associates_filter: this.props.filters.business_associates,
            bafSearch: '',
            prediction: false,
            salesChannels: 'all'
        }

    }

    sellOptions = ['sell-out', 'sell-in', 'leaflets']
    salesChannelsOptions = ['all', 'mp', 'vp']

    canceledOptions = [['all','svi'], ['0','nestornirano'], ['1','stornirano']]

    changeCanceled=(event)=>{
        this.setState({canceled: event.target.value});
    }
    changeSalesChannels=(event)=>{
        this.setState({salesChannels: event.target.value});
    }
    

    handlePrediction=()=>{
        this.setState({prediction: !this.state.prediction});
    }

    unsubscribe = []

    componentDidMount=()=>{
        // Service.get('/test/getRegions',{},(res)=>{
        //     this.setState({regions: res.data.regions});
        // });
        console.log(this.props.filters);
        //get customers
        this.getCustomers('all');
        this.getStores('all');
        // this.getCustomers();
        this.unsubscribe[0] = duix.subscribe('update_dataset', () => this.updateDataset(this.props.item, this.props.index));
    }

    componentWillUnmount(){
        //clearInterval(this.interval);
        this.unsubscribe[0]();
    }

    getCustomers = (businessAssociateId) =>{
        // Service.get('/analytics/getCustomers',{businessAssociateId: businessAssociateId},(res)=>{
        //     this.setState({customers: res.data.customers});
        // })
    }

    getStores =(customerId)=>{
        // Service.get('/analytics/getStores',{customerId: customerId},(res)=>{
        //     this.setState({stores: res.data.stores},()=>{
        //         console.log('state', this.state);
        //     })
        // })
    }

  removeDataset=(id)=>{
      console.log('remove data set', id);
      this.props.removeDataset(id);
  }

    updateDataset=(id, index)=>{
        console.log('update data set', id, index);
        this.props.updateDataset({
            customerId: this.state.customerId,
            storeId: this.state.storeId,
            productId: this.state.productId,
            straussCode: this.state.straussCode,
            businessAssociateId: this.state.businessAssociateId,
            type: this.state.type,
            name: this.state.name,
            canceled: this.state.canceled,
            products: this.state.selectedProducts,
            prediction: this.state.prediction,
            salesChannels: this.state.salesChannels
        },index);
        
    }

    changeBusinessAssociates=(event)=>{

        //  console.log(event.target.innerHTML);
        // this.setState({
        //     businessAssociateId: event.target.id, 
        //     businessAssociateName: event.target.innerHTML,
        //     selectedCustomer: 'all',
        //     // selectedStore: 'all'
        // },()=>{
        //     this.getCustomers(this.state.businessAssociateId);
        //     console.log(this.state.businessAssociateId);
            
        // });

        // let f = parseInt(event.target.value.split(' = ')[0]);
        // this.setState({businessAssociateId: f},()=>{
        //     this.getCustomers(this.state.businessAssociateId);
        // });
        
    }

    changeCustomers=(event)=>{
        // let f = parseInt(event.target.value.split(' = ')[0]);
        // this.setState({customerId: f},()=>{
        //     this.getStores(this.state.customerId);
        // });
        //let f = parseInt(event.target.value.split(' = ')[0]);
        // this.setState({
        //     customerId: event.target.id, 
        //     selectedCustomer: event.target.innerHTML,
        //     // selectedStore: 'all'
        //     },()=>{
        //     // this.getStores(this.state.customerId);
        // });
    }

    
    changeStore=(event)=>{
        // let f = parseInt(event.target.value.split(' = ')[0]);
        //this.setState({storeId: event.target.id, 
        // selectedStore: event.target.innerHTML
        // });
        // console.log(event.target.id);
    }

    addProduct=(event)=>{
        //let f = parseInt(event.target.value.split(' = ')[0]);
        this.setState({straussCode: event.target.id});
        let sp = this.state.selectedProducts;
        if(sp.includes(event.target.id)){
           return alert('Proizvod je vec odabran!');
        }
        sp.push(event.target.id);
        this.setState({selectedProducts: sp})

    }

    clearSelectedProducts=()=>{
        this.setState({selectedProducts: []})
    }

    removeProduct=(id)=>{
        console.log('product',id)
        let sp = this.state.selectedProducts;
        for(var i in sp){
            if(sp[i] == id){
                sp.splice(i,1);
            }
        }
        this.setState({selectedProducts: sp})
    }

    changeType=(event)=>{
        this.setState({type: event.target.value});
    }

    changeName=(event)=>{
        this.setState({name: event.target.value});
    }

    toggleVisibility=()=>{
        if(this.state.class === ''){
            this.setState({class: ' dataset-hidden', visibilityIcon: <i class="fas fa-eye-slash"></i>});
            
        }else{
            this.setState({class: '', visibilityIcon: <i class="far fa-eye"></i>});
        }
    }

    changeSubBrand=(event)=>{
        this.setState({subBrand: event.target.value})
    }

    searchStringInArray =(str, strArray) => {
        console.log(str,strArray);
        let r = []
        for (var j=0; j<strArray.length; j++) {
            if (strArray[j].name.toLowerCase().match(str)) r.push(strArray[j]);
            // console.log(strArray[j].name);
        }
        console.log('r',r);
        return r;
    }

    updateBaf = (event) => {
        this.setState({bafSearch: event.target.value});
        // if(event.target.value.length > 0){
        //     console.log('search');
        //     this.setState({business_associates_filter: this.searchStringInArray(event.target.value, this.props.filters.business_associates)});
        // }else{
        //     let r = []
        //     for (var j=0; j<this.props.filters.business_associates.length; j++) {
        //         r.push(this.props.filters.business_associates[j]);
        //     }
        //     this.setState({business_associates_filter: r});
        // }
    }

    updateStores=(res)=>{
        console.log('test run', res);
        if(res){
            this.setState({storeId: res.value});
        }else{
            this.setState({storeId: ''});
        }
    }
    updateCustomers=(res)=>{
        console.log('test customer', res);
        if(res){
            this.setState({customerId: res.value});
        }else{
            this.setState({customerId: ''});
        }
    }
    updateBusinessAssociates=(res)=>{
        console.log('test bassoc', res);
        if(res){
            this.setState({businessAssociateId: res.value});
        }else{
            this.setState({businessAssociateId: ''});
        }
    }
    updateSubBrands=(res)=>{
        console.log('test subbrands', res);
        if(res){
            this.setState({subBrand: res.value});
        }else{
            this.setState({subBrand: ''});
        }
    }
    updateProducts=(res)=>{
        console.log('test product', res);
        if(res){
            let p = []
            res.map((item)=>{
                p.push(item.value)
            })
            this.setState({selectedProducts: p});
        }else{
            this.setState({selectedProducts: []});
        }
    }

    // getAllBusinessAssociates,
// getAllCustomers
// getAllCities
// getAllStores
// getAllProducts

  render() {
    return (

        <div className="data-set" >
            
            <input type="text" value={this.state.name} onChange={this.changeName}/><button className="keyword-gray keyword-no-width" onClick={this.toggleVisibility}>{this.state.visibilityIcon}</button>
            <br />
            <span className={this.state.class}>
                
                
                <Search onChange={this.updateBusinessAssociates} route='/analytics/getAllBusinessAssociates' type='single' placeholder="Prodavci..."/>
                <Search onChange={this.updateCustomers} route='/analytics/getAllCustomers' type='single' placeholder="Kupci..."/>
                <Search onChange={this.updateStores} route='/analytics/getAllStores' type='single' placeholder="Prodavnice..."/>
                <Search onChange={this.updateSubBrands} route='/analytics/getAllSubBrands' type='single' placeholder="Subbrands..."/>
                <Search onChange={this.updateProducts} route='/analytics/getAllProducts' placeholder="Proizvodi..."/>
                
                <hr/>
                <select value={this.state.type} onChange={this.changeType}>
                    <option >Type:</option>
                        {this.sellOptions.map(function(item, index){
                        return(
                            <option value={item} key={index}>{item}</option>
                        )},this
                        )};
                </select>

                <select value={this.state.canceled} onChange={this.changeCanceled}>
                    <option >Type:</option>
                        {this.canceledOptions.map(function(item, index){
                        return(
                            <option value={item[0]} key={index}>{item[1]}</option>
                        )},this
                        )};
                </select>
                <select value={this.state.salesChannels} onChange={this.changeSalesChannels}>
                    <option >Kanal Prodaje:</option>
                        {this.salesChannelsOptions.map(function(item, index){
                        return(
                            <option value={item} key={index}>{item}</option>
                        )},this
                        )};
                </select>
                <br/>
                {this.state.prediction === true &&
                <button className='keyword' onClick={this.handlePrediction}>predikcija</button>
                }
                {this.state.prediction === false &&
                <button className='keyword' style={{background: '#ddd'}} onClick={this.handlePrediction}>predikcija</button>
                }
                <button className="keyword-gray" onClick={() => this.updateDataset(this.props.item, this.props.index)} key={this.props.item.id} ><i className="fas fa-sync-alt"></i> Update</button>
                <button className="keyword-gray keyword-no-width" onClick={() => this.removeDataset(this.props.index)} key={this.props.item.id} ><i className="fas fa-trash-alt"></i></button>
            </span>
        </div>
      
    )
  }
}

export default DataSet;