import React, { Component } from 'react';
import Service from '../components/Service';

import ImportMisData from '../components/ImportMisData';
import ImportMisReturnData from '../components/ImportMisReturnData';
import ImportProducts from '../components/ImportProducts';
import ImportCustomers from '../components/ImportCustomers';
import ImportStores from '../components/ImportStores';
import ImportPartners from '../components/ImportPartners';
import ImportSellOut from '../components/ImportSellOut';
import ImportSellOutDev from '../components/ImportSellOutDev'; // IZBACITI OVO
import ImportGeoLocations from '../components/ImportGeoLocations';
import ImportBusinessAssociates from '../components/ImportBusinessAssociates';
import ImportCustom from '../components/ImportCustom';
import ImportCustomCsv from '../components/ImportCustomCsv';
import ImportSellOutMulti from '../components/ImportSellOutMulti';
import ImportActions from '../components/ImportActions';
import ImportLeaflets from '../components/ImportLeaflets';
import ImportSurvey from '../components/ImportSurvey';
import ImportRoutes from '../components/ImportRoutes';

function ToRender(props) {
  const component = props.component;
  if(component === 'mis') {
    return <ImportMisData />;
  }
  if(component === 'mis-povratnice'){
      return <ImportMisReturnData />;
  }
  if(component === 'products'){
    return <ImportProducts />;
  }
  if(component === 'partners'){
    return <ImportPartners />;
  }
  if(component === 'customers'){
    return <ImportCustomers />;
  }
  if(component === 'stores'){
    return <ImportStores />;
  }
  if(component === 'sell-out'){
    return <ImportSellOut />;
  }
  if(component === 'sell-out-multi'){
    return <ImportSellOutMulti />;
  }
  if(component === 'sell-out-dev'){
    return <ImportSellOutDev />;
  }
  if(component === 'geo-locations'){
    return <ImportGeoLocations />;
  }
  if(component === 'business-associates'){
    return <ImportBusinessAssociates />;
  }
  if(component === 'custom'){
    return <ImportCustom />;
  }
  if(component === 'custom-csv'){
    return <ImportCustomCsv />;
  }
  if(component === 'actions'){
    return <ImportActions />;
  }
  if(component === 'leaflets'){
    return <ImportLeaflets />;
  }
  if(component === 'survey'){
    return <ImportSurvey />;
  }
  if(component === 'routes'){
    return <ImportRoutes />;
  }


  

  
  return <p>Choose action...</p>
}

class Import extends Component {
constructor() {
    super();
    this.state = {
      name: 'React',
      component: false
    };
  }

  render() {
    return (
      <div>
        <div className="submenu">
            {/* <h2>SELL IN</h2>
            <ul>
                <li onClick={()=>{this.setState({component: 'mis'})}}>Import Otpremnice</li>
                <li onClick={()=>{this.setState({component: 'mis-povratnice'})}}>Import Povratnice</li>
                <li onClick={()=>{this.setState({component: 'products'})}}>Import Products</li>
                <li onClick={()=>{this.setState({component: 'customers'})}}>Import Customers</li>
                <li onClick={()=>{this.setState({component: 'stores'})}}>Import Stores</li>
                <li onClick={()=>{this.setState({component: 'partners'})}}>Import Partners</li>
                <li onClick={()=>{this.setState({component: 'geo-locations'})}}>Import Geo Locations</li>
                <li onClick={()=>{this.setState({component: 'business-associates'})}}>Import Associates</li>
                
            </ul> */}
            <h2>SELL OUT</h2>
            <ul>
                <li onClick={()=>{this.setState({component: 'sell-out'})}}>Import SellOut</li>
                <li onClick={()=>{this.setState({component: 'sell-out-multi'})}}>Import SellOut MULTI</li>
                <li onClick={()=>{this.setState({component: 'sell-out-dev'})}}>Import SELL OUT DEV</li>
                <li onClick={()=>{this.setState({component: 'custom'})}}>Import Custom Text</li>
                <li onClick={()=>{this.setState({component: 'custom-csv'})}}>Import Custom CSV</li>
                <li onClick={()=>{this.setState({component: 'actions'})}}>Import Actions</li>
                <li onClick={()=>{this.setState({component: 'leaflets'})}}>Import Leaflets</li>
                <li onClick={()=>{this.setState({component: 'survey'})}}>Import Survey</li>
                <li onClick={()=>{this.setState({component: 'routes'})}}>Import Routes</li>
                
            </ul>
        </div>
        <div className="right-content">
            <ToRender component={this.state.component}/>
        </div>
      </div>
    );
  }

}

export default Import;