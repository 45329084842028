import React, { Component } from 'react';
import Service from '../components/Service';
import Popup from "reactjs-popup";
import Selector from '../components/Selector';

const requestToken = {
    currentToken: 0
}


class Products extends Component {

	constructor(props) {
	    super(props);
        this.state = {
            products: [],
            open: false,
            selectedProduct: {},
            filter: '',
            openCreate: false,
            createProduct: {},
            producers: [],
            subBrands:[],
            selectSubBrand:{},
            openEditCodes: false,
            selectedEditCode: null,
            selectedProductId: null,
            currentCodes: []
            
        }
	}

    componentDidMount(){
        this.getProducts();
        this.getAllSubBrands();
        this.getAllProducers();
    }

    getProducts=()=>{
        requestToken.currentToken ++;
        let ct = requestToken.currentToken;
        Service.get('/import/getProductsList',{},(res)=>{
            if(res.success){
                if(ct === requestToken.currentToken){
                    console.log('main request rendered')
                    this.setState({products: res.data.products},()=>{
                        
                    })
                }
            }
        })
    }

    getAllSubBrands=()=>{
        Service.get('/analytics/getAllSubBrands',{filters: {competition: true}},(res)=>{
            if(res.success){
                this.setState({subBrands: res.data},()=>{
                    
                })
            }
        })
    }

    getAllProducers=()=>{
        Service.get('/analytics/getAllProducers',{},(res)=>{
            if(res.success){
                this.setState({producers: res.data},()=>{
                    
                })
            }
        })
    }

    editProductValue=(event)=>{
        console.log(event.target.value);
        let sp = this.state.selectedProduct;
        sp[event.target.id] = event.target.value
        this.setState({selectedProduct: sp});
    }

    createProductValue=(event)=>{
        console.log(event.target.value);
        let sp = this.state.createProduct;
        sp[event.target.id] = event.target.value
        this.setState({createProduct: sp});
    }

    createProductSelect=(event)=>{
        console.log(event.target.value);
        const subBrandId = event.target.value;
        const brandId = this.state.subBrands.find(u => u.id === subBrandId);
        this.setState({
            selectSubBrand: {
                sub_brand_id_create: brandId.id,
                sub_brand_code_create: brandId.sub_brands_code
              }
        });
    }

    updateProduct=()=>{
        console.log('up', this.state.selectedProduct);

        Service.get('/import/updateProduct',{ product: {
                id: this.state.selectedProduct.id,
                sku: this.state.selectedProduct.sku,
                name: this.state.selectedProduct.name,
                artical: this.state.selectedProduct.artical,
                straussCode: this.state.selectedProduct.strauss_code,
                straussCodesId: this.state.selectedProduct.strauss_codes_id,
                weight: this.state.selectedProduct.weight,
                price: this.state.selectedProduct.price,
                subBrandsId: this.state.selectedProduct.sub_brands_id,
                subBrandsCode: this.state.selectedProduct.sub_brands_code
            }
        },(res)=>{
            if(res.success){
                this.refreshUpdatedProduct(res.productUpdated[0]);
            }
        })
    }

    createProductToggle=()=>{
        this.setState({openCreate: true});
    }

    createProduct=()=>{
        // console.log('input', this.state.createProduct);
        // console.log('select', this.state.selectSubBrand);
        
        Service.get('/import/createProduct',{ product: {
            sku: this.state.createProduct.sku_create,
            name: this.state.createProduct.name_create,
            straussCode: this.state.createProduct.strauss_code_create,                        
            weight: this.state.createProduct.weight_create,
            price: this.state.createProduct.price_create,
            amanCode: this.state.createProduct.aman_code_create,
            aromaCode: this.state.createProduct.aroma_code_create,
            delhaizeCode: this.state.createProduct.delhaize_code_create,
            disCode: this.state.createProduct.dis_code_create,
            gomexCode: this.state.createProduct.gomex_code_create,
            mercatorCode: this.state.createProduct.mercator_code_create,
            europromCode: this.state.createProduct.europrom_code_create,
            univerexportCode: this.state.createProduct.univerexport_code_create,
            subBrandId: this.state.selectSubBrand.sub_brand_id_create,
            subBrandCode: this.state.selectSubBrand.sub_brand_code_create,
            producerId: this.state.createProduct.producer_id_create,
            productTypeId: this.state.createProduct.product_type_id_create
            }
        },(res)=>{
            if(res.success){
                this.setState({openCreate: false});
                this.getProducts();
            }
            if(res.info){
                alert(res.info);
            }
        })
    }

    refreshUpdatedProduct=(product)=>{
        let products = this.state.products;
        products.map(obj => product.id || obj);
        this.setState({products: products},()=>{
            this.setState({selectedProduct: {}, open: false});
        });
    }

    cancelUpdate=()=>{
        this.setState({selectedProduct: {}, open: false});
    }
    cancelCreate=()=>{
        this.setState({createProudct: {}, openCreate: false});
    }

    editProduct=(event)=>{
        console.log(event.target.id);
        this.setState({selectedProduct: this.state.products.find(o => o.id === event.target.id), open: true});
    }

    updateFilteredProducts=(event)=>{
        console.log(event.target.value)
        requestToken.currentToken ++;
        let ct = requestToken.currentToken;
        if(event.target.value){
            
            console.log(requestToken.currentToken);
            Service.get('/import/getProductsList',{filter: event.target.value, requestToken: ct},(res)=>{
                //Service.get('/analytics/getAllProductsBySku',{filter: event.target.value},(res)=>{
                
                if(res.success){
                    if(ct ===  requestToken.currentToken){
                        console.log('request rendered');
                        this.setState({products: res.data.products},()=>{
                            
                        })
                    }
                }
            })
        }else{
            this.getProducts();
        }
    }

    parseCodes(codes){
        //console.log('codes', codes);
        if(!codes){
            return '';
        }
        let r = [];
        codes.map(function(item, index){
            r.push(item.code);
        })
        return r.join(', ');
    }


    getProductCodes=()=>{
        Service.get('/import/getProductCodesByProductId',{productId: this.state.selectedProductId},(res)=>{
            this.setState({currentCodes: res.data.codes},()=>{
                console.log(this.state.currentCodes)
            });
            
        })
    }

    openCodePopup=(event)=>{
        this.setState({openEditCodes: true, 
            selectedEditCode: event.target.getAttribute('data-code-name'),
            selectedProductId: event.target.getAttribute('data-product-id'),
            },()=>{
                this.getProductCodes();
            });
    }

    cancelCodeEdit=()=>{
        this.setState({openEditCodes: false});
        this.getProducts();
    }

    updateProductCode=(event)=>{
        
        let val = document.getElementById('cid_'+event.target.getAttribute('data-code-id')).value;
        //console.log(val);
        console.log({productId: event.target.getAttribute('data-product-id'),codeId: event.target.getAttribute('data-code-id'), value: val})

        Service.get('/import/updateProductCode',{
                    productId: event.target.getAttribute('data-product-id'),
                    codeId: event.target.getAttribute('data-code-id'),
                    code: val
            },(res)=>{
                if(res.success){
                    this.getProductCodes();
                }
            })
    }

    removeProductCode=(event)=>{
        console.log({productId: event.target.getAttribute('data-product-id'),codeId: event.target.getAttribute('data-code-id')})

        Service.get('/import/removeProductCode',{
                    productId: event.target.getAttribute('data-product-id'),
                    codeId: event.target.getAttribute('data-code-id'),
                    //value: val
            },(res)=>{
                if(res.success){
                    this.getProductCodes();
                }
            })
    }

    addProductCode=(event)=>{
        let val = document.getElementById('cnid_'+event.target.getAttribute('data-product-id')).value;
        let codeName = event.target.getAttribute('data-code-name');
        let input = document.getElementById('cnid_'+event.target.getAttribute('data-product-id'));

        console.log({productId: event.target.getAttribute('data-product-id'), value: val})
        Service.get('/import/addProductCode',{
                    productId: event.target.getAttribute('data-product-id'),
                    codeName: codeName,
                    code: val
            },(res)=>{
                if(res.success){
                    this.getProductCodes();
                    input.value = '';
                }
            })

    }

    editProductSelect=(event)=>{
        const subBrandId = event.target.value;
        const brandId = this.state.subBrands.find(u => u.id === subBrandId);

        let sp = this.state.selectedProduct;
        sp.sub_brand = brandId.name;
        sp.sub_brands_id = brandId.id;
        sp.sub_brands_code = brandId.sub_brands_code;
        
        this.setState({selectedProduct: sp})
    }

  render() {
    return (
      <div className="content">
        <h1>Products</h1>
            <div className="selector">
            <span>Filter: </span><input type="text" className="selector-filter" onChange={this.updateFilteredProducts} placeholder="Filter products"/><br />
            </div>
            <button onClick={this.createProductToggle}>Create New Product</button>
            <table className="preview-table">
                <thead>
                <tr className="table-head-fixed">
                    <th>Id</th>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>Strauss Code</th>
                    <th>Aman</th>
                    <th>Aroma</th>
                    <th>Delhaize</th>
                    <th>Dis</th>
                    <th>Gomex</th>
                    <th>Mercator</th>
                    <th>Europrom</th>
                    <th>Univerexport</th>
                    <th>Metro</th>
                    <th>Metalac</th>
                    <th>Weight Kg</th>
                    <th>Price RSD</th>
                    <th>Edit</th>
                    {/* <td>Greske</td>
                    <td>Logovi</td>
                    <td>File Session</td> */}
                </tr>
                </thead>
                <tbody>
                {this.state.products.map((item, index)=>{
                    return(
                            <tr>
                                <td>{item.id}</td>
                                <td>{item.sku}</td>
                                <td>{item.name}<br /><div className="product-row-brand">{item.brand}<span>{item.sub_brand}</span></div></td>
                                <td>{item.strauss_code}</td>
                                <td onClick={this.openCodePopup} data-code-name="aman_code" data-product-id={item.id} >{this.parseCodes(item.aman_code)}</td>
                                <td onClick={this.openCodePopup} data-code-name="aroma_code" data-product-id={item.id} >{this.parseCodes(item.aroma_code)}</td>
                                <td onClick={this.openCodePopup} data-code-name="delhaize_code" data-product-id={item.id} >{this.parseCodes(item.delhaize_code)}</td>
                                <td onClick={this.openCodePopup} data-code-name="dis_code" data-product-id={item.id} >{this.parseCodes(item.dis_code)}</td>
                                <td onClick={this.openCodePopup} data-code-name="gomex_code" data-product-id={item.id} >{this.parseCodes(item.gomex_code)}</td>
                                <td onClick={this.openCodePopup} data-code-name="mercator_code" data-product-id={item.id} >{this.parseCodes(item.mercator_code)}</td>
                                <td onClick={this.openCodePopup} data-code-name="europrom_code" data-product-id={item.id} >{this.parseCodes(item.europrom_code)}</td>
                                <td onClick={this.openCodePopup} data-code-name="univerexport_code" data-product-id={item.id} >{this.parseCodes(item.univerexport_code)}</td>
                                <td onClick={this.openCodePopup} data-code-name="metro_code" data-product-id={item.id} >{this.parseCodes(item.metro_code)}</td>
                                <td onClick={this.openCodePopup} data-code-name="metalac_code" data-product-id={item.id} >{this.parseCodes(item.metalac_code)}</td>
                                
                                {/* <td>{this.parseCodes(item.aroma_code)}</td>
                                <td>{this.parseCodes(item.delhaize_code)}</td>
                                <td>{this.parseCodes(item.dis_code)}</td>
                                <td>{this.parseCodes(item.gomex_code)}</td>
                                <td>{this.parseCodes(item.mercator_code)}</td>
                                <td>{this.parseCodes(item.europrom_code)}</td>
                                <td>{this.parseCodes(item.univerexport_code)}</td> */}
                                <td>{item.weight}</td>
                                <td>{item.price}</td>
                                <td><button className="keyword" id={item.id} onClick={this.editProduct}>Edit</button></td>
                            </tr>
                    )
                }, this)
                }
                </tbody>
            </table>
            <Popup open={this.state.open} modal closeOnDocumentClick={false} position="right center">
                    {close => (
                        <div className="edit-product">
                            <h3>{this.state.selectedProduct.name} id:{this.state.selectedProduct.id}</h3>
                            <span>Sku:</span><input type="text" id="sku" onChange={this.editProductValue} value={this.state.selectedProduct.sku} /><br />
                            <span>Strauss Code</span><input type="text" id="strauss_code" onChange={this.editProductValue} value={this.state.selectedProduct.strauss_code} /><br />
                            {/* <span>Aman</span><input type="text" id="aman_code" onChange={this.editProductValue} value={this.state.selectedProduct.aman_code} /><br />
                            <span>Aroma</span><input type="text" id="aroma_code" onChange={this.editProductValue} value={this.state.selectedProduct.aroma_code} /><br />
                            <span>Delhaize</span><input type="text" id="delhaize_code" onChange={this.editProductValue} value={this.state.selectedProduct.delhaize_code} /><br />
                            <span>Dis</span><input type="text" id="dis_code" onChange={this.editProductValue} value={this.state.selectedProduct.dis_code} /><br />
                            <span>Gomex</span><input type="text" id="gomex_code" onChange={this.editProductValue} value={this.state.selectedProduct.gomex_code} /><br />
                            <span>Mercator</span><input type="text" id="mercator_code" onChange={this.editProductValue} value={this.state.selectedProduct.mercator_code} /><br />
                            <span>Europrom</span><input type="text" id="europrom_code" onChange={this.editProductValue} value={this.state.selectedProduct.europrom_code} /><br />
                            <span>Univerexport</span><input type="text" id="univerexport_code" onChange={this.editProductValue} value={this.state.selectedProduct.univerexport_code} /><br /> */}
                            <span>Weight</span><input type="text" id="weight" onChange={this.editProductValue} value={this.state.selectedProduct.weight} /> Kg<br />
                            <span>Price</span><input type="text" id="price" onChange={this.editProductValue} value={this.state.selectedProduct.price} /> RSD<br />
                            <span>Sub Brand Id</span>
                            <select id="subBrands" onChange={this.editProductSelect} value={this.state.selectedProduct.sub_brands_id}>
                                {this.state.subBrands.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.label}
                                    </option>
                                ))}
                            </select><br />
                            <br />
                            <button className="keyword" onClick={this.updateProduct}>Update</button>
                            <button className="keyword update" style={{background: '#999'}} onClick={this.cancelUpdate}>Cancel</button>
                            
                        </div>
                        
                    )}
                </Popup> 

                <Popup open={this.state.openCreate} modal closeOnDocumentClick={false} position="right center">
                    {close => (
                        <div className="edit-product">
                            {/* <h3>{this.state.createProduct.name} id:{this.state.createProduct.id}</h3> */}
                            <span>Sku:</span><input type="text" id="sku_create" onChange={this.createProductValue} value={this.state.createProduct.sku_create} /><br />
                            <span>Name</span><input type="text" id="name_create" onChange={this.createProductValue} value={this.state.createProduct.name_create} /><br />
                            <span>Strauss Code</span><input type="text" id="strauss_code_create" onChange={this.createProductValue} value={this.state.createProduct.strauss_code_create} /><br />
                            {/* <span>Aman</span><input type="text" id="aman_code_create" onChange={this.createProductValue} value={this.state.createProduct.aman_code_create} /><br />
                            <span>Aroma</span><input type="text" id="aroma_code_create" onChange={this.createProductValue} value={this.state.createProduct.aroma_code_create} /><br />
                            <span>Delhaize</span><input type="text" id="delhaize_code_create" onChange={this.createProductValue} value={this.state.createProduct.delhaize_code_create} /><br />
                            <span>Dis</span><input type="text" id="dis_code_create" onChange={this.createProductValue} value={this.state.createProduct.dis_code_create} /><br />
                            <span>Gomex</span><input type="text" id="gomex_code_create" onChange={this.createProductValue} value={this.state.createProduct.gomex_code_create} /><br />
                            <span>Mercator</span><input type="text" id="mercator_code_create" onChange={this.createProductValue} value={this.state.createProduct.mercator_code_create} /><br />
                            <span>Europrom</span><input type="text" id="europrom_code_create" onChange={this.createProductValue} value={this.state.createProduct.europrom_code_create} /><br />
                            <span>Univerexport</span><input type="text" id="univerexport_code_create" onChange={this.createProductValue} value={this.state.createProduct.univerexport_code_create} /><br /> */}
                            <span>Weight</span><input type="text" id="weight_create" onChange={this.createProductValue} value={this.state.createProduct.weight_create} /> Kg<br />
                            <span>Price</span><input type="text" id="price_create" onChange={this.createProductValue} value={this.state.createProduct.price_create} /> RSD<br />
                            <span>Sub Brand Id</span>
                                <select id="sub_brand_id_create" onChange={this.createProductSelect} value={this.state.sub_brand_id_create}>
                                    {this.state.subBrands.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                                <br />
                            {/* <span>Sub Brand Code</span><input type="text" id="sub_brand_code_create" onChange={this.createProductValue} value={this.state.createProduct.sub_brand_code_create} /> <br /> */}
                            <span>Producer Id</span>
                                <select id="producer_id_create" onChange={this.createProductValue} value={this.state.producer_id_create}>
                                    {this.state.producers.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                                <br />
                            <span>Product Type Id</span><input type="text" id="product_type_id_create" onChange={this.createProductValue} value={this.state.createProduct.product_type_id_create} /> <br />
                            <br />
                            <button className="keyword" onClick={this.createProduct}>Create</button>
                            <button className="keyword update" style={{background: '#999'}} onClick={this.cancelCreate}>Cancel</button>
                            
                        </div>
                        
                    )}
                </Popup> 

                <Popup open={this.state.openEditCodes} modal closeOnDocumentClick={false} position="right center">
                    {close => (
                        <div className="edit-product">
                            <h3>Edit {this.state.selectedEditCode} codes</h3>
                            <p>Product ID: {this.state.selectedProductId}</p>

                            {this.state.currentCodes.map(function(item){
                                console.log(item);
                                if(item.name === this.state.selectedEditCode){
                                    return(
                                        <div>
                                            <input id={'cid_'+item.id} type="text" placeholder={item.code} />
                                            <button data-product-id={item.products_id} data-code-id={item.id} onClick={this.updateProductCode}>Update</button>
                                            <button data-product-id={item.products_id} data-code-id={item.id} onClick={this.removeProductCode}>Remove</button>
                                        </div>
                                    )
                                } 
                            },this)
                            
                            }
                            <input type="text" id={'cnid_'+this.state.selectedProductId}/><button data-product-id={this.state.selectedProductId} data-code-name={this.state.selectedEditCode} onClick={this.addProductCode}>Add new code</button>
                            <br />
                            {/* <button className="keyword" onClick={this.addProductCode}>Update</button> */}
                            <button className="keyword" onClick={this.cancelCodeEdit}>Close</button>
                        </div>
                        
                    )}
                </Popup> 
            
      </div>
      
    )
  }
}

export default Products;