import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import Service from '../components/Service';
import Search from '../components/Search';
import TreeMenu from 'react-simple-tree-menu';
import '../../node_modules/react-simple-tree-menu/dist/main.css';

class Leaflets extends Component {

    constructor(props){
        super(props);
        this.state = {
            sales: [],
            leaflets: []
        }

    }

    test=(res)=>{
        console.log('test run', res);
    }

    componentDidMount=()=>{
        this.getLeaflets();
    }

    getLeaflets=()=>{
        Service.get('/liflets/get',{},(res)=>{
            this.setState({leaflets: res.data.leaflets});
        });
    }

  render() {
    return (
      <div className="content">
          <h1>Lifleti</h1>
          <Search onChange={this.test} route='/analytics/getAllStores' />
          {/* <h2>Associates</h2> */}
          {/* <TreeMenu data={this.state.treeData}/> */}
          <table className="preview-table">
                <tr>
                    <td>Brend</td>
                    <td>Od</td>
                    <td>Do</td>
                    <td>Popust</td>  
                    <td>Pakovanje</td>
                    <td>Cena</td>
                    <td>Normalna cena</td>
                    <td>Retailer</td>
                    <td>SKU</td>  
                </tr>

                

                {/* brand: "DONCAFE MINAS"
currency: "RSD"
date_from: "28-11-2019"
date_to: "04-12-2019"
discount: "22"
ean: "8600101521248"
id: "70"
manufacturer: "STRAUSS GROUP LTD."
packaging: "1 PAKOVANJE 500 GR"
price: "199.0000"
price_normal: "254.9900"
products_id: null
retailer: "S SHOP&GO"
sku_code: "18815"
stores_id: null
subbrand: " " */}
            {this.state.leaflets.map((item, index)=>{
                return(
                    <tr>
                        <td>{item.brand}</td>
                        <td>{item.date_from}</td>
                        <td>{item.date_to}</td>
                        <td>{item.discount}%</td>  
                        <td>{item.packaging}</td>
                        <td>{item.price} {item.currency}</td>
                        <td>{item.price_normal} {item.currency}</td>
                        <td>{item.retailer}</td>
                        <td>{item.sku_code}</td>             
                    </tr>
                )
            }, this)
            }
          </table>
      </div>
      
    )
  }
}

export default Leaflets;