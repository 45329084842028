import React, { Component } from 'react';
import Service from '../components/Service';
import { CSVReader } from 'react-papaparse';


class Import extends Component {
constructor() {
    super();
    this.state = {
      products: []
    };
    this.fileInput = React.createRef();
    // this.prepareProducts = this.prepareProducts.bind(this);
    // this.createProducts = this.createProducts.bind(this);
  }

  handleReadCSV = (data) => {
    console.log(data);
    this.setState({
            products: data.data
          });
    console.log(data.data);
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  }

  handleImportOffer = () => {
    this.fileInput.current.click();
  }

  sendProducts=()=>{

      let p = this.state.products.slice(1);
      Service.get('/import/products',{products: p},(res)=>{
          console.log(res)
      })
  }

  render() {
    return (
      <div>
        <h2>IMPORT PRODUCTS</h2>
        <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{display: 'none'}}
          onError={this.handleOnError}
        />
        <p className='hint' >Klikni dugme 'Odaberi CSV' da uneses proizvode iz CSV fajla</p>
        <button onClick={this.handleImportOffer}>Odaberi CSV</button>
        <button onClick={this.sendProducts}>Submit</button>
        {this.state.products.map(function(item, index){
            return(
                      <p>{item.toString()}</p>
                        
              );
          })}

          
      </div>
    );
  }

}

export default Import;