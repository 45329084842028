import React, { Component } from 'react';
import Service from '../components/Service';

import HighchartsReact from "highcharts-react-official";
import highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more'; highchartsMore(highcharts);


function order(prop) {
    return function(a, b) {
        return a[prop] - b[prop];
    }
}

function compare(a, b) {
  if ( parseFloat(a.surveyAmount) < parseFloat(b.surveyAmount) ){
    return -1;
  }
  return 1
//   if ( a.SA > b.SA ){
//     return 1;
//   }
//   return 0;
}



var selectedProps = [
    {name: 'tip_objekta_code', value: 1},
    {name: 'lokacija_radnje_2_code', value: 0}, 
    {name: 'lokacija_radnje_3_code', value: 0}, 
    {name: 'vrsta_okruženja_code', value: 0}, 
    {name: 'surveyAmount', value: 1}
]

let options = {
                chart: {
                    type: 'line',
                    //type: 'scatter',
                    zoomType: 'xy',
                    panning: true,
                    panKey: 'shift'
                },
                title: {
                    text: 'Doncafe, sales prediction by store similarity'
                },
                subtitle: {
                    text: 'Source: Survey data & Brainit data'
                },
                xAxis: {
                    title: {
                        enabled: true,
                        text: 'Stores'
                    },
                    startOnTick: true,
                    endOnTick: true,
                    showLastLabel: true,
                    categories: []
                },
                yAxis: {
                    title: {
                        text: 'Sales (kg)'
                    }
                },
                // legend: {
                //     layout: 'vertical',
                //     align: 'left',
                //     verticalAlign: 'top',
                //     x: 100,
                //     y: 70,
                //     floating: true,
                //     backgroundColor: highcharts.defaultOptions.chart.backgroundColor,
                //     borderWidth: 1
                // },
                plotOptions: {
                    scatter: {
                        marker: {
                            radius: 5,
                            states: {
                                hover: {
                                    enabled: true,
                                    lineColor: 'rgb(100,100,100)'
                                }
                            }
                        },
                        states: {
                            hover: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x} kg, {point.y} kg'
                        }
                    }
                },
                series: [{
                    name: 'SellAmount',
                    color: 'rgba(223, 83, 83, .5)',
                    data: [1,2,3,4,5]

                },
                {
                    name: 'Prediction',
                    color: 'rgba(84, 83, 233, .5)',
                    data: [5,2,35,3,5]

                }]
}

let optionsArea = {
    chart: {
        type: 'column',
        zoomType: 'xy',
        // scrollablePlotArea: {
        //     minWidth: 600,
        //     scrollPositionX: 1
        // }
    },

    title: {
        text: 'Ratio'
    },

    xAxis: {
        // type: 'datetime',
        // accessibility: {
        //     rangeDescription: 'Range: Jan 1st 2017 to Dec 31 2017.'
        // }
        title: {
            text: 'Stores'
        }
    },

    yAxis: {
        title: {
            text: 'Sales (Kg)'
        }
    },

    tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: 'Kg',
        xDateFormat: '%A, %b %e'
    },

    legend: {
        enabled: false
    },

    series: [{
        name: 'Offset',
        data: []
    }]
}

class Ml extends Component {

    constructor(props){
        super(props);
        this.state = {
            model: [],
            stats: [],
            ready: false,
            selectedProps: [],
            result: 0,
            sales: 0,
            options: options,
            optionsArea: optionsArea,
            averageError: 0,
            predictionSum: 0

        }

    }

    componentDidMount(){
        this.getSellData();
        this.setState({selectedProps})
    }

    getSellData=()=>{
        let model = [];

        Service.get('/analytics/getSellDataForPredictionsByStoreOnlyRelevantPure',{},(res)=>{
            if(res.success){
                res.data.map(function(item){
                    if((parseInt(item.surveyAmount) < 200 && parseInt(item.sellAmount) < 200 && parseInt(item.surveyAmount) !== 0 && item.surveyAmount) && (parseInt(item.sellAmount) !== 0 && item.sellAmount)){
                        model.push(item);
                    }
                })

                this.setState({model},()=>{
                    this.setStats(this.state.model);
                })
            }
        });
    }


    setStats=(model)=>{
        let a = {};
        // let selectedProps = ['tip_objekta_code', 'lokacija_radnje_2_code', 'lokacija_radnje_3_code', 'vrsta_okruženja_code', 'surveyAmount']; 

        for(var p in model[0]){
            a[p] = model[0][p];
            a[p] = {}
        } 
    
        this.setState({stats: a},()=>{
            this.state.selectedProps.map(item =>{
                let obj = this.state.stats;
                if(item.name in obj){
                    this.getStatsDataByProps(item.name);
                }
            })
        })
    }

    getStatsDataByProps=(props)=>{
        let propsValues = [];

        this.state.model.map(p =>{
          if(!propsValues.includes(p[props])){
            propsValues.push(p[props]);
          }
        })

        let statsTemp = this.state.stats;
        propsValues.map(prop =>{
            let modelData = [];
            this.state.model.map(model =>{
                if(prop === model[props]){
                    modelData.push(model);
                }
            })
            
            statsTemp[props][prop] = {value: prop, data: modelData, sellAvg: this.setSellAvg(modelData)};
        })
        this.setState({stats: statsTemp},()=>{
            console.log('stats', this.state.stats)
            // this.setPredict()
            this.setState({ready: true})
        });

    }

    setSellAvg=(data)=>{
        let count = 0;

        data.map(item =>{
            count += parseFloat(item.sellAmount);
        })

        let sellAvg = count / data.length;

        return sellAvg;
    }

    setPredict=()=>{
        // let model = [...this.state.model];
        let result = [];
        let propsValues = 0;
        this.state.selectedProps.map((prop) =>{
            propsValues += parseInt(prop.value);
        })

        this.state.model.map((item, index) =>{
            let count = 0;
            // let propsValues = 0;

            this.state.selectedProps.map((prop) =>{
                // propsValues += prop.value;
                for (var i=0; i < prop.value; i++) {
                    count += this.state.stats[prop.name][item[prop.name]].sellAvg;
                }            
            })

            // item['predict'] = count / propsValues;
            result.push({sellAmount: item.sellAmount, predict: count/propsValues, surveyAmount: item.surveyAmount, storeCode: item.store_doncafe_code});
        })

        console.log("result", result)
        result.sort( order('surveyAmount') );
        this.testPrediction(result);
        this.updateChart(result);

        // this.setState({model},()=>{
        //     console.log("model", this.state.model)
        // })
    }

    testPrediction=(model)=>{
        let finalScore = 0;
        let sellAmounts = 0;
        let predictionSum = 0;
        model.map(item =>{
            let abs = Math.abs(parseFloat(item.sellAmount)-item.predict);
            sellAmounts += parseFloat(item.sellAmount);
            finalScore += abs;
            predictionSum += item.predict;
        })


        console.log("abs", finalScore, sellAmounts, predictionSum)
        this.setState({result: finalScore, sales: sellAmounts, predictionSum}, ()=>{
            
        })
    }

    handlePropValue=(event)=>{
        let sp = this.state.selectedProps;

        sp.map(item=>{
            if(item.name === event.target.getAttribute('data-name')){
                item.value = event.target.value;
            }
        })

        this.setState({selectedProps: sp})
    }

    updateChart = (results) => {
        
        let opt = this.state.options;
        let optArea = this.state.optionsArea;
        console.log('opt', opt);
        let a = [];
        let b = [];
        let c = [];
        let aa = [];
        let errorSum = 0;
        let storeNames = [];
        results.map(item=>{
            a.push(parseFloat(item.sellAmount));
            b.push(item.predict);
            c.push(parseFloat(item.surveyAmount));
            aa.push(Math.abs([parseFloat(item.sellAmount) - item.predict ]));
            errorSum += Math.abs([parseFloat(item.sellAmount) - item.predict ]);
            storeNames.push(item.storeName);
        })
        console.log('area', aa);
        // set cart
        opt.series[0].data = a;
        opt.series[1].data = b;
        opt.series[2] = {};
        opt.series[2].data = c;
        opt.series[2].name = 'Survey Amount';

        
        opt.xAxis.categories = storeNames;
        // set area chart
        optArea.series[0].data = aa;

        let ae = errorSum / aa.length;
        // calculate average error
        

        this.setState({options: opt, optionsArea, optArea, averageError: ae});
        
    }

    render() {
        return (
            <div className="content">
                <h1>Machine learning</h1>

                {this.state.selectedProps.map((item)=>{
                    return(
                        <div>{item.name} <input value={item.value} onChange={this.handlePropValue} data-name={item.name}/></div>
                    )
                })}

                {this.state.ready &&
                    <button onClick={this.setPredict}>Predict</button>
                }

                <p>Result: {this.state.result}</p>
                <p>Sales: {this.state.sales}</p>
                <p>Prediction sum: {this.state.predictionSum}</p>
                

                <h3>Ratio: {this.state.result / this.state.sales}</h3>
                <h4>Average Error: {this.state.averageError}</h4>

                <div className='chart-container-sales'>
                    <HighchartsReact highcharts={highcharts} options={this.state.options} allowChartUpdate={true}
                    updateArgs={[true, true, true]}/>
                </div>
                <div className='chart-container-sales'>
                    <HighchartsReact highcharts={highcharts} options={this.state.optionsArea} allowChartUpdate={true}
                    updateArgs={[true, true, true]}/>
                </div>

            </div>
        
        )
    }
}

export default Ml;