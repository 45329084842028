import React, { Component } from 'react';
import Service from '../components/Service';

import { CSVReader } from 'react-papaparse';


// const items = {
//     sellOut: []
// };
class ImportGeoLocations extends Component {
    constructor() {
        super();
        this.state = {
            geoLocations: [],
            page: 0,
            perPage: 20
        };
        this.fileInput = React.createRef();
    }

    handlePage=(event)=>{
        this.setState({page: event.target.value});
    }

    handlePerPage=(event)=>{
        this.setState({perPage: event.target.value});
    }

    handleReadCSV = (data) => {
        //console.log(data);
        this.setState({
                geoLocations: data.data.slice(3)
            });
        //console.log(data.data);
    }

    handleImportOffer = () => {
        this.fileInput.current.click();
    }

    sendData=()=>{
            // console.log(this.state.sellOut);
            // return;
        // let p = this.state.geoLocations.slice(3);

        // Service.get('/import/geoLocations',{geoLocations: p},(res)=>{
        //     console.log(res)
        // })

        let items = this.state.geoLocations;
        let d = [];
        let page = parseInt(this.state.page);
        let perPage = parseInt(this.state.perPage);
        console.log((page * perPage), (page * perPage) + perPage - 1);
        for(var i = (page * perPage); i <= (page * perPage) + perPage - 1; i++){
            d.push(items[i]);
        }
        console.log('items to send:', d);
        Service.get('/import/geoLocations',{geoLocations: d});
    }


  render() {
    return (
      <div>
          <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{display: 'none'}}
          onError={this.handleOnError}
        />
        <p className='hint' >Klikni dugme 'Odaberi CSV' da uneses proizvode iz CSV fajla</p>
        <button onClick={this.handleImportOffer}>Odaberi CSV</button>
        PAGE: <input type="text" value={this.state.page} onChange={this.handlePage} />
        PER PAGE: <input type="text" value={this.state.perPage} onChange={this.handlePerPage} />
        <button onClick={this.sendData}>Submit</button>
        {this.state.geoLocations.map(function(item, index){
            return(
                      <p>{item.toString()}</p>
                        
              );
          })}
        
        
      </div>
    );
  }

}

export default ImportGeoLocations;