import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import Service from '../components/Service';
import DateSelector from '../components/DateSelector';
import AsyncSelect from 'react-select/async';
import Search from '../components/Search';
import Select from '../components/Select';
import Selector from '../components/Selector';
import { CSVLink, CSVDownload } from "react-csv";
import Popup from "reactjs-popup";
import ReactExport from "react-export-excel";


import HighchartsReact from "highcharts-react-official";
import highcharts from 'highcharts'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];


const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

function numberWithCommas(x) {
    if(x){
        let y = Math.round(x);
        return y.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
    return '-';
}

class Sales extends Component {

    constructor(props){
        super(props);
        this.state = {
            regions: [],
            from: '', 
            to: '', 
            storeId: 'all',
            productId: 'all',
            straussCode: 'all',
            businessAssociateId: 'all',
            type: 'sell-out',
            customerId: 'all',
            name: 'sales',
            groupBy: 'month',
            canceled: '0',
            products: [],
            prediction: false,
            salesChannels: 'all',
            city: '',
            cities: [],
            sales: [],
            citiesArray: [],
            allStores: [],
            store: '',
            inputValue: '',
            selectedCustomers: [],
            selectedBusinessAssociates: [],
            selectedStores: [],
            selectedSubBrands: [],
            selectedProducts: [],
            selectedCities: [],
            selectedBrands: [],
            selectedTerrirories: [],
            rowType: 'subBrands',
            columns: [],
            status: 'No data.',
            competition: false,
            csvData: [],
            excelData: [],
            stop: false,
            next: false,
            topAmount: 0,
            average: 0,
            salesCompare: [],
            nextCompare: false,
            compareAmounts: {},
            fromCompare: '',
            toCompare: '',
            typeCompare: 'sell-in',
            open: false,
            options: {
                chart: {
                    type: 'column',
                    plotBorderWidth: 1,
                    zoomType: 'xy'
                },
                xAxis: {
                    categories: [1,2,3],
                    title: {
                      text: 'Year'
                   }
                },
                title: {
                    text: 'chart'
                },
                series: [{name: 'test', data: [1,3,5]},{name: 'test2', data: [3,2,6]}]
            },
            optionsPopup: {
                chart: {
                    type: 'column',
                    plotBorderWidth: 1,
                    zoomType: 'xy'
                },
                xAxis: {
                    categories: [1,2,3],
                },
                title: {
                    text: 'chart'
                },
                series: [{name: 'test', data: [1,3,5]},{name: 'test2', data: [3,2,6]}]
            },
            presets: [],
            presetId: '',
            selectedBusinessAssociatesPreset: [],
            selectedCustomersPreset: [],
            selectedTerritoriesPreset: [],
            selectedStoresPreset: [],
            selectedSubBrandsPreset: [],
            selectedBrandsPreset: [],
            selectedProductsPreset: [],
            selectedCitiesPreset: [],
            groupByPreset: [],
            rowTypePreset: [],
            currentPresetId:'',
            currentPresetName:''
        }
        this.mainDataFrom = React.createRef();
        this.mainDataTo = React.createRef();
        this.salesRepresentatives = React.createRef();
        this.customers = React.createRef();
        this.territories = React.createRef();
        this.stores = React.createRef();
        this.brands = React.createRef();
        this.subBrands = React.createRef();
        this.products = React.createRef();
        this.cities = React.createRef();
        this.columns = React.createRef();
        this.rows = React.createRef();
        this.compareDataDateFrom = React.createRef();
        this.compareDataDateTo = React.createRef();
    }

    updateChart = () => {
        
        let opt = this.state.options;

        //opt.series[index] = {name: filters.name, data: res.data.amounts}
        opt.series = [];
        console.log('sales', this.state.sales);
        this.state.sales.map((row)=>{
            // row.data.amounts.map((item, index)=>{
                
            // })
            console.log('rl', row.data.rowLabel);
            opt.series.push({name: row.rowLabel, data: row.data.amounts});
        })

                            
        // <td id={row.rowLabel + '-' + index}><div id={row.rowLabel + '-' + index + 'color'} className="table-color-box" ></div>{numberWithCommas(item)}<div className='compare' id={row.rowLabel + '-' + index + 'compare'}></div></td>

        //opt.series[index] = {name: filters.name, data: [55,66,33,44,66,22,55,33,55,44]}
        this.setState({options: opt});
        
        this.setXValues();

    }

    updateChartPopup = (index) => {
        
        let opt = this.state.optionsPopup;

        //opt.series[index] = {name: filters.name, data: res.data.amounts}
        opt.series = [];
        console.log('sales', this.state.sales);
        // this.state.sales.map((row)=>{
            // row.data.amounts.map((item, index)=>{
                
            // })
            //console.log('rl', row.data.rowLabel);
            const a = this.state.sales.filter(s => s.rowLabel == index);
            console.log(a);
            //opt.series.push({name: this.state.sales[index].rowLabel, data: this.state.sales[index].data.amounts});
            opt.series.push({name: a[0].rowLabel + ' data', data: a[0].data.amounts});
        // })
            const b = this.state.salesCompare.filter(s => s.rowLabel == index);
            if(b[0]){
                opt.series.push({name: b[0].rowLabel + ' compare', data: b[0].data.amounts});
            }
            
                            
        // <td id={row.rowLabel + '-' + index}><div id={row.rowLabel + '-' + index + 'color'} className="table-color-box" ></div>{numberWithCommas(item)}<div className='compare' id={row.rowLabel + '-' + index + 'compare'}></div></td>

        //opt.series[index] = {name: filters.name, data: [55,66,33,44,66,22,55,33,55,44]}

        let c = []
        a[0].data.dates.map((item, index)=>{
            c.push(item)
        })
        opt.xAxis.categories = c;

        this.setState({optionsPopup: opt});
        
        // this.setXValues();

    }

    setXValues=()=>{
        let opt = this.state.options;
        let c = []
        this.state.columns.map((item, index)=>{
            c.push(item)
        })
        opt.xAxis.categories = c;

        this.setState({options: opt});
    }



    handleChange = city => {
        this.setState({city});
        console.log(`Option selected:`, city);
    };



    

    componentDidMount=()=>{
        this.getPresets();

    }



    generateFilters=()=>{
        let f = {
            from: this.state.from, 
                to: this.state.to, 
                stores: this.state.selectedStores,
                businessAssociates: this.state.selectedBusinessAssociates,
                customers: this.state.selectedCustomers,
                products: this.state.selectedProducts,
                cities: this.state.selectedCities,
                canceled: this.state.canceled,
                prediction: this.state.prediction,
                salesChannels: this.salesChannelsValues[this.state.salesChannels],
                subBrands: this.state.selectedSubBrands,
                brands: this.state.selectedBrands,
                territories: this.state.selectedTerrirories,
                type: this.state.type,
                groupBy: this.state.groupBy,
                rowType: this.state.rowType,
                straussCode: this.state.straussCode,
                competition: JSON.parse(this.state.competition),
                next: this.state.next
        }
        return f;
    }

    generateFiltersPreset=()=>{
        let f = {
            from: this.state.from, 
                to: this.state.to, 
                stores: this.state.selectedStoresPreset,
                businessAssociates: this.state.selectedBusinessAssociatesPreset,
                customers: this.state.selectedCustomersPreset,
                products: this.state.selectedProductsPreset,
                cities: this.state.selectedCitiesPreset,
                canceled: this.state.canceled,
                prediction: this.state.prediction,
                salesChannels: this.state.salesChannels,
                subBrands: this.state.selectedSubBrandsPreset,
                brands: this.state.selectedBrandsPreset,
                territories: this.state.selectedTerritoriesPreset,
                type: this.state.type,
                groupBy: this.state.groupByPreset,
                rowType: this.state.rowTypePreset,
                straussCode: this.state.straussCode,
                competition: JSON.parse(this.state.competition),
                next: this.state.next,
                toCompare: this.state.toCompare,
                fromCompare: this.state.fromCompare,
                typeCompare: this.state.typeCompare
        }
        return f;
    }

    getSalesData=()=>{
        if(!this.state.next){
            this.setState({sales: []});
            this.setState({stop: false});
            window.scrollTo(0, 0);
        }
        this.setState({status: 'Loading sales data. Please wait, this can take a while...'})
        Service.get('/analytics/getSales',{
            filters: this.generateFilters()
            // {
            //     from: this.state.from, 
            //     to: this.state.to, 
            //     stores: this.state.selectedStores,
            //     businessAssociates: this.state.selectedBusinessAssociates,
            //     customers: this.state.selectedCustomers,
            //     products: this.state.selectedProducts,
            //     cities: this.state.selectedCities,
            //     canceled: this.state.canceled,
            //     prediction: this.state.prediction,
            //     salesChannels: this.state.salesChannels,
            //     subBrands: this.state.selectedSubBrands,
            //     brands: this.state.selectedBrands,
            //     type: this.state.type,
            //     groupBy: this.state.groupBy,
            //     rowType: this.state.rowType,
            //     straussCode: this.state.straussCode,
            //     competition: false,
            //     next: this.state.next
            //     }
            },(res)=>{
                if(res.success){
                    this.setState({
                        sales: [ ...this.state.sales, ...res.data ]
                    },()=>{
                        // if(!this.state.next){
                            if(res.data.length > 0){
                                this.setState({columns: res.data[0].data.dates});
                            }
                        // }
                        if(res.next){
                            if(!this.state.stop){
                                this.setState({next: res.next},()=>{
                                    this.getSalesData(res.next);
                                })
                                
                            }
                        }else{
                            this.setState({next: false},()=>{
                                this.getCompareSalesData();
                            });
                            this.generateCSV();
                            this.setState({status: ''});
                            
                            this.updateChart();
                        }
                    })
                    
                    // this.setState({sales: res.data},()=>{
                    //     this.setState({columns: res.data[0].data.dates},()=>{
                    //         this.generateCSV();
                    //     });
                    // });
                    
                    
                }else{
                    this.setState({status: 'Error. Please try later.'})
                }
            
        })
    }

    getCompareSalesData=()=>{
        if(!this.state.nextCompare){
            this.setState({salesCompare: []});
            this.setState({stop: false});
        }
        let filters = this.generateFilters();
            filters.from = this.state.fromCompare;
            filters.to = this.state.toCompare;
            filters.type = this.state.typeCompare;
            filters.next = this.state.nextCompare;
        Service.get('/analytics/getSales',{
            filters: filters
        },(res)=>{
                if(res.success){
                    this.setState({
                        salesCompare: [ ...this.state.salesCompare, ...res.data ]
                    },()=>{
                        // if(!this.state.next){
                            if(res.data.length > 0){
                                this.setState({columnsCompare: res.data[0].data.dates});
                            }
                        // }
                        if(res.next){
                            if(!this.state.stop){
                                this.setState({nextCompare: res.next},()=>{
                                    this.getCompareSalesData(res.next);
                                })
                                
                            }
                        }else{
                            this.setState({nextCompare: false});
                            //this.generateCSV();
                            this.calculateAmounts();
                        }
                    })
                    
                    // this.setState({sales: res.data},()=>{
                    //     this.setState({columns: res.data[0].data.dates},()=>{
                    //         this.generateCSV();
                    //     });
                    // });
                    
                    
                }else{
                    this.setState({status: 'Error. Please try later.'})
                }
            
        })
    }

    generateCSV=()=>{
        console.log('gcsv');
        var c = []
        // let a = []
        // a.push(this.state.rowType+" / "+this.state.groupBy);
        // this.state.columns.map((item, index)=>{
        //    a.push(item);
        // }, this)
        // c.push(a);

        this.state.sales.map((row, index)=>{
            let b = [];
            b.push(row.rowLabel);
            row.data.amounts.map((item, index)=>{
                    b.push(item);
                }, this)
            c.push(b);
        }, this)
        this.setState({csvData: c},()=>{
            this.generateExcel();
        });
    }

    generateExcel=()=>{
        let excel = [];

        this.state.csvData.map((item, index)=>{
            var rv = {};
            for (var i = 0; i < item.length; ++i)
                rv[i] = item[i];
            excel.push(rv);
        }, this);

        this.setState({excelData: excel})
    }


    updateSearch=(res, value, valuePreset)=>{
        console.log('test product', res);
        if(valuePreset.includes("Preset")){
            let l = []
            res.map((item)=>{
                l.push(item)
            })
            this.setState({[valuePreset]: l});
        }
        if(res){
            let p = []
            res.map((item)=>{
                p.push(item.value)
            })
            this.setState({[value]: p});
        }else{
            this.setState({[value]: []});
        }
    }

    
    updateCustomers=(res)=>{
        this.updateSearch(res, 'selectedCustomers', 'selectedCustomersPreset')
    }
    updateBusinessAssociates=(res)=>{
        this.updateSearch(res, 'selectedBusinessAssociates', 'selectedBusinessAssociatesPreset')
    }
    updateTerritories=(res)=>{
        this.updateSearch(res, 'selectedTerritories', 'selectedTerritoriesPreset')
    }
    updateStores=(res)=>{
        this.updateSearch(res, 'selectedStores', 'selectedStoresPreset')
    }
    updateSubBrands=(res)=>{
        this.updateSearch(res, 'selectedSubBrands', 'selectedSubBrandsPreset')
    }
    updateBrands=(res)=>{
        this.updateSearch(res, 'selectedBrands', 'selectedBrandsPreset')
    }
    updateProducts=(res)=>{
        this.updateSearch(res, 'selectedProducts', 'selectedProductsPreset')
    }
    updateCities=(res)=>{
        this.updateSearch(res, 'selectedCities', 'selectedCitiesPreset')
    }

    updateColumns=(res)=>{
        if(res && res[0]){
            this.setState({groupBy: res[0].value});
            this.setState({groupByPreset: res});
        }
    }
    updateRows=(res)=>{
        if(res && res[0]){
            this.setState({rowType: res[0].value});
            this.setState({rowTypePreset: res});
        }
        console.log(res);
    }

    options = [
        { id: 'month', label: 'month' },
        { id: 'day', label: 'day' },
        { id: 'year', label: 'year' },
        { id: 'products', label: 'products' },
        { id: 'stores', label: 'stores' },
        { id: 'cities', label: 'cities' },
        { id: 'businessAssociates', label: 'sales representatives' },
        { id: 'customers', label: 'customers' },
        { id: 'brands', label: 'brands' },
        { id: 'subBrands', label: 'subBrands' },
        { id: 'territories', label: 'territories' }
    ]

    optionsRows = [
        { id: 'products', label: 'products' },
        { id: 'stores', label: 'stores' },
        { id: 'cities', label: 'cities' },
        { id: 'businessAssociates', label: 'sales representatives' },
        { id: 'customers', label: 'customers' },
        { id: 'brands', label: 'brands' },
        { id: 'subBrands', label: 'subBrands' },
        { id: 'territories', label: 'territories' }
    ]

    updateTest=(res)=>{
        console.log('test', res);
    }  

    changeFrom=(date)=>{
        this.setState({from: date});
    }

    changeTo=(date)=>{
        this.setState({to: date});
    }
    changeFromCompare=(date)=>{
        this.setState({fromCompare: date});
    }

    changeToCompare=(date)=>{
        this.setState({toCompare: date});
    } 

    sellOptions = ['sell-out', 'sell-in', 'leaflets']
    salesChannelsOptions = ['all', 'mp', 'vp']
    salesChannelsValues = {all: 'all', mp: 1, vp: 2};

    canceledOptions = [['all','svi'], ['0','nestornirano'], ['1','stornirano']]
    competitionOptions = [[false, 'without competition'], [true, 'with competition']]

    changeCanceled=(event)=>{
        this.setState({canceled: event.target.value});
    }
    changeSalesChannels=(event)=>{
        this.setState({salesChannels: event.target.value});
    }

    changeType=(event)=>{
        this.setState({type: event.target.value});
    }
    changeTypeCompare=(event)=>{
        this.setState({typeCompare: event.target.value});
    }

    changeCompetition=(event)=>{
        this.setState({competition: event.target.value});
    }

    stopRequest=()=>{
        this.setState({stop: true});
    }

    calculateAmounts=()=>{
        let topAmount = 0;
        let sum = 0;
        let count = 0;
        let average = 0;
        this.state.sales.map((row)=>{
            row.data.amounts.map((item, index)=>{
                if(parseFloat(item) > topAmount){
                    sum += parseFloat(item);
                    count ++;
                    average = sum / count;
                    topAmount = parseFloat(item);
                }
            }, this)

        })
        let ca = this.state.compareAmounts;
        this.state.salesCompare.map((row)=>{
            row.data.amounts.map((item, index)=>{
                // if(parseFloat(item) > topAmount){
                    let id = row.rowLabel + '-' + index + 'compare';
                    ca[id] = parseFloat(item);
                    console.log(item);
                    // sum += parseFloat(item);
                    // count ++;
                    // average = sum / count;
                    // topAmount = parseFloat(item);
                // }
            }, this)

        })

        console.log(average, topAmount, ca);
        this.setState({topAmount: topAmount, average: average, compareAmounts: ca},()=>{
            this.colorize();
        })
    }

    colorize=()=>{
        this.state.sales.map((row)=>{
            row.data.amounts.map((item, index)=>{
                let id = row.rowLabel + '-' + index + 'color';
                let idNumber = row.rowLabel + '-' + index;
                let idCompare = row.rowLabel + '-' + index + 'compare';
                
                document.getElementById(idCompare).innerHTML = numberWithCommas(this.state.compareAmounts[idCompare]);
                // let percent = item / this.state.topAmount;
                let percent = parseFloat(item) / parseFloat(this.state.compareAmounts[idCompare]);
                if(parseFloat(item) && parseFloat(this.state.compareAmounts[idCompare])){
                    document.getElementById(id).style.backgroundColor = 'green';
                    if(item > this.state.compareAmounts[idCompare]){ //this.state.average){
                        document.getElementById(id).style.opacity = (percent * .8)+ .2;  //'.8';
                        if(percent > .6){
                            document.getElementById(idNumber).style.color = 'white';
                        }
                    }else{
                        // low amounts
                        document.getElementById(id).style.backgroundColor = 'red';
                        document.getElementById(id).style.opacity = -(percent * .8) + .8;  //'.8';
                        // document.getElementById(id).style.opacity = (( -percent * .8)+ .2) + 1;
                    }
                }

            }, this)

        })
    }

    showChart =(event)=>{
        console.log('sales id',event.target.getAttribute('data-sales-id'));
        let id = event.target.getAttribute('data-sales-id');
        //console.log('sales data', this.state.sales[0])
        this.setState({open: true});
        this.updateChartPopup(id);
    } 

    closePopup=()=>{
        this.setState({open: false});
    }

    createPreset=()=>{
        let presetName = prompt("Please enter preset name:", "");
        if (presetName !== null || presetName !== "") {
            Service.get('/analytics/savePreset',{
                name: presetName,
                action: 'getSales',
                filters: this.generateFiltersPreset()
            },(res)=>{
                if(res.success){
                    this.getPresets();
                }
            })
        } 
    }

    savePreset=()=>{
        var update = window.confirm("Update: Are you sure you want to overwrite preset "+this.state.currentPresetName+"?");
        if (update == true) {
            Service.get('/analytics/updatePreset',{
                id: this.state.currentPresetId,
                filters: this.generateFiltersPreset()
            },(res)=>{
                if(res.success){
                    this.getPresetById();
                }
            })
        }            
    }

    getPresets=()=>{
        Service.get('/analytics/getPresets',{
            action: 'getSales'
        },(res)=>{
            if(res.success){
                this.setState({presets: res.data.presets}, ()=>{
                })
            }
        }) 
    }

    changePreset=(event)=>{
        this.setState({presetId: event.target.value},()=>{
            this.getPresetById();
        })
    }

    getPresetById=()=>{
        this.salesRepresentatives.current.clearData();
        this.customers.current.clearData();
        this.territories.current.clearData();
        this.stores.current.clearData();
        this.brands.current.clearData();
        this.subBrands.current.clearData();
        this.products.current.clearData();
        this.cities.current.clearData();
        this.columns.current.clearData();
        this.rows.current.clearData();
        Service.get('/analytics/getPresetById',{
            id: this.state.presetId
        },(res)=>{
            if(res.success){
                let typeCompareTemp = "sell-in";
                if(res.data.preset.typeCompare){
                    typeCompareTemp = res.data.preset.typeCompare;
                }
                this.setState({
                    canceled: res.data.preset.canceled,
                    competition: res.data.preset.competition,
                    next: res.data.preset.next,
                    prediction: res.data.preset.prediction,
                    salesChannels: res.data.preset.salesChannels,
                    straussCode: res.data.preset.straussCode,
                    type: res.data.preset.type,
                    currentPresetId: res.data.id,
                    currentPresetName: res.data.label,
                    typeCompare: typeCompareTemp
                },()=>{
                     // Main data date from
                    let mainDataDateFrom = res.data.preset.from.split('-');
                    let year = mainDataDateFrom[0];
                    let month = +mainDataDateFrom[1];
                    let day = +mainDataDateFrom[2];
                    this.mainDataFrom.current.setYear(year);
                    this.mainDataFrom.current.setMonth(month);
                    this.mainDataFrom.current.setDay(day);
                    // Main data date to
                    let mainDataDateTo = res.data.preset.to.split('-');
                    let yearTo = mainDataDateTo[0];
                    let monthTo = +mainDataDateTo[1];
                    let dayTo = +mainDataDateTo[2];
                    this.mainDataTo.current.setYear(yearTo);
                    this.mainDataTo.current.setMonth(monthTo);
                    this.mainDataTo.current.setDay(dayTo);
                    
                    if(res.data.preset.fromCompare){
                        // compare data date from
                        let compareDataDateFrom = res.data.preset.fromCompare.split('-');
                        let yearC = compareDataDateFrom[0];
                        let monthC = +compareDataDateFrom[1];
                        let dayC = +compareDataDateFrom[2];
                        this.compareDataDateFrom.current.setYear(yearC);
                        this.compareDataDateFrom.current.setMonth(monthC);
                        this.compareDataDateFrom.current.setDay(dayC);
                    }

                    if(res.data.preset.toCompare){
                        // compare data date to
                        let compareDataDateTo = res.data.preset.toCompare.split('-');
                        let yearToC = compareDataDateTo[0];
                        let monthToC = +compareDataDateTo[1];
                        let dayToC = +compareDataDateTo[2];
                        this.compareDataDateTo.current.setYear(yearToC);
                        this.compareDataDateTo.current.setMonth(monthToC);
                        this.compareDataDateTo.current.setDay(dayToC);
                    }
                    
                //     toCompare: this.state.toCompare,
                // toFrom: this.state.toFrom,
                // typeCompare: this.state.typeCompare
                    //salesRepresentatives
                    res.data.preset.businessAssociates.map((item, index)=>{
                        this.salesRepresentatives.current.addItem(item.value, item.label)
                    }, this);
                    // customers
                    res.data.preset.customers.map((item, index)=>{
                        this.customers.current.addItem(item.value, item.label)
                    }, this);
                    // territories
                    res.data.preset.territories.map((item, index)=>{
                        this.territories.current.addItem(item.value, item.label)
                    }, this);
                    // stores
                    res.data.preset.stores.map((item, index)=>{
                        this.stores.current.addItem(item.value, item.label)
                    }, this);
                    // brands
                    res.data.preset.brands.map((item, index)=>{
                        this.brands.current.addItem(item.value, item.label)
                    }, this);
                    // subBrands
                    res.data.preset.subBrands.map((item, index)=>{
                        this.subBrands.current.addItem(item.value, item.label)
                    }, this);
                     // products
                     res.data.preset.products.map((item, index)=>{
                        this.products.current.addItem(item.value, item.label)
                    }, this);
                    // cities
                    res.data.preset.cities.map((item, index)=>{
                        this.cities.current.addItem(item.value, item.label)
                    }, this);
                     // columns
                     res.data.preset.groupBy.map((item, index)=>{
                        this.columns.current.addItem(item.value, item.label)
                    }, this);
                    // rows
                    res.data.preset.rowType.map((item, index)=>{
                        this.rows.current.addItem(item.value, item.label)
                    }, this);
                })
            }
        })
    }


render() {
    return (
        <div className="content">
            <h1>Sales</h1>
            <hr />
                <div className="sales-controls">
                    <h2>Options</h2>
                    Main Data:<br />
                    <DateSelector ref={this.mainDataFrom} label="From" onChange={this.changeFrom} defaultYear={2019} defaultMonth={1} />
                    <DateSelector ref={this.mainDataTo} label="To" onChange={this.changeTo} defaultYear={2020} defaultMonth={1} />
                    Source<select value={this.state.type} onChange={this.changeType}>
                    {/* <option >Type:</option> */}
                        {this.sellOptions.map(function(item, index){
                        return(
                            <option value={item} key={index}>{item}</option>
                        )},this
                        )};
                    </select>

                    Status<select value={this.state.canceled} onChange={this.changeCanceled}>
                        {/* <option >Type:</option> */}
                            {this.canceledOptions.map(function(item, index){
                            return(
                                <option value={item[0]} key={index}>{item[1]}</option>
                            )},this
                            )};
                    </select>
                    Sales Type<select value={this.state.salesChannels} onChange={this.changeSalesChannels}>
                        {/* <option >Kanal Prodaje:</option> */}
                            {this.salesChannelsOptions.map(function(item, index){
                            return(
                                <option value={item} key={index}>{item}</option>
                            )},this
                            )};
                    </select>
                    Results<select value={this.state.competition} onChange={this.changeCompetition}>
                            {this.competitionOptions.map(function(item, index){
                            return(
                                <option value={item[0]} key={index}>{item}</option>
                            )},this
                            )};
                    </select>
                </div>
                <div> Compare Data:<br />
                    <DateSelector ref={this.compareDataDateFrom} label="From" onChange={this.changeFromCompare} defaultYear={2019} defaultMonth={1} />
                    <DateSelector ref={this.compareDataDateTo} label="To" onChange={this.changeToCompare} defaultYear={2020} defaultMonth={1} />
                    Source<select value={this.state.typeCompare} onChange={this.changeTypeCompare}>
                        {this.sellOptions.map(function(item, index){
                        return(
                            <option value={item} key={index}>{item}</option>
                        )},this
                        )};
                    </select>
                    </div>
            <hr />
            <div className="sales-filters">
                <h2>Filters</h2>
            {/* <Search onChange={this.updateCustomers} route='/analytics/getAllCustomers' placeholder="Kupci..."/> */}
                    {/* <Selector onChange={this.updateTest} route='/analytics/getAllProducts' single='true' placeholder="Prodavci..."/> */}
                    
                    Sales Representatives:<br />
                    <Selector ref={this.salesRepresentatives} onChange={this.updateBusinessAssociates} route='/analytics/getAllBusinessAssociates' placeholder="Sales Representatives..."/>
                    Customers:<br />
                    <Selector ref={this.customers} onChange={this.updateCustomers} route='/analytics/getAllCustomers' filters={{businessAssociates: this.state.selectedBusinessAssociates}} filterOptional={true} filterLabel="by s.r" placeholder="Customers..."/>
                    Territories:<br />
                    <Selector ref={this.territories} onChange={this.updateTerritories} route='/analytics/getAllTerritories' filters={{territories: this.state.selectedTerrirories}} placeholder="Territories..."/>
                    Stores:<br />
                    <Selector ref={this.stores} onChange={this.updateStores} route='/analytics/getAllStores' filters={{businessAssociates: this.state.selectedBusinessAssociates, customers: this.state.selectedCustomers}} filterOptional={true} filterLabel="by s.r and customers" placeholder="Stores..."/>
                    Brands:<br />
                    <Selector ref={this.brands} onChange={this.updateBrands} route='/analytics/getAllBrands' filters={{competition: this.state.competition}} placeholder="Brands..."/>
                    Sub Brands:<br />
                    <Selector ref={this.subBrands} onChange={this.updateSubBrands} route='/analytics/getAllSubBrands' filters={{competition: this.state.competition, brands: this.state.selectedBrands}} placeholder="Subbrands..."/>
                    Products:<br />
                    <Selector ref={this.products} onChange={this.updateProducts} route='/analytics/getAllProducts' filters={{competition: this.state.competition, brands: this.state.selectedBrands, subBrands: this.state.selectedSubBrands}} placeholder="Products..."/>
                    Cities:<br />
                    <Selector ref={this.cities} onChange={this.updateCities} route='/analytics/getAllCities' placeholder="Cities..."/>
                    <hr />
                    <h2>Display by</h2>
                    Columns:<br />
                    <Selector ref={this.columns} onChange={this.updateColumns} options={this.options} type='single' placeholder="Columns"/>
                    Rows:<br />
                    <Selector ref={this.rows} onChange={this.updateRows} options={this.optionsRows} type='single' placeholder="Rows"/>
                    

                    {/* Presets */}
                    Presets<select value={this.state.preset} onChange={this.changePreset}>
                    <option >Select Preset</option>
                        {this.state.presets.map(function(item, index){
                        return(
                            <option value={item.id} key={index}>{item.name}</option>
                        )},this
                        )};
                    </select>
                    <button className='keyword' onClick={this.createPreset}>
                        Create Preset
                    </button>
                    <button className='keyword' onClick={this.savePreset}>
                        Save Preset
                    </button>
                    <br></br>

                    <button className='keyword' onClick={this.getSalesData}>
                        Submit
                    </button>
                    <button className='keyword' onClick={this.stopRequest}>
                        Stop
                    </button>
                
                </div>
                <div className="sales-results">
                <h2>Sales Data</h2>
                {/* <CSVLink 
                    data={this.state.csvData}
                    filename={"doncafe-sales-"+this.state.from+"/"+this.state.from+".csv"}
                    className="keyword"
                >Export CSV</CSVLink> */}

                <ExcelFile element={<button className="keyword">Export excel</button>} filename={"doncafe-sales-"+this.state.from+"/"+this.state.from}>
                  <ExcelSheet data={this.state.excelData} name={"doncafe-sales-"+this.state.from+"/"+this.state.from}>
                    <ExcelColumn label="subBrand/mont" value="0" />
                    {this.state.columns.map((item, index)=>{
                        return(
                            <ExcelColumn label={item} value={index+1} />
                        )
                        }, this)
                    }
                  </ExcelSheet>
                </ExcelFile>

                <div className='table-cont'>
                <table className="preview-table" draggable>
                    <tr>
                        <td>{this.state.rowType} / {this.state.groupBy}</td>
                        {this.state.columns.map((item, index)=>{
                                return(
                                    <td>{item}</td>
                                )
                            }, this)
                        }
                    </tr>
                    {this.state.sales.map((row, ind)=>{
                        return(
                            <tr>
                                <td data-sales-id={row.rowLabel} onClick={this.showChart}>{row.rowLabel}</td>
                                {row.data.amounts.map((item, index)=>{
                                        return(
                                            <td id={row.rowLabel + '-' + index} ><div id={row.rowLabel + '-' + index + 'color'} className="table-color-box" ></div>{numberWithCommas(item)}<div className='compare' id={row.rowLabel + '-' + index + 'compare'}></div></td>
                                        )
                                    }, this)
                                }
                            </tr>
                        )
                    }, this)
                    }
            </table>
            <div className="sales-status">{this.state.status}</div>
            </div>
            </div>
            <Popup open={this.state.open} modal closeOnDocumentClick={false} position="right center">
                    {close => (
                        <div>
                            <h3>Compare</h3>
                            <HighchartsReact highcharts={highcharts} options={this.state.optionsPopup} allowChartUpdate={true}
                updateArgs={[true, true, true]}/>
                            {/* <button className="keyword" onClick={this.updateProduct}>Update</button> */}
                            <button className="keyword update" style={{background: '#999'}} onClick={this.closePopup}>Close</button>
                            
                        </div>
                        
                    )}
                </Popup> 
            <div className='chart-container-sales'>
                <HighchartsReact highcharts={highcharts} options={this.state.options} allowChartUpdate={true}
                updateArgs={[true, true, true]}/>
            </div>
        </div>
      
    )
  }
}

export default Sales;