import React, { Component } from 'react';
import Service from '../components/Service';
import { CSVReader } from 'react-papaparse';

const items = {
    data: []
};
class ImportRoutes extends Component {
constructor() {
    super();
    this.state = {
      name: 'React',
      partners: [],
      page: 0,
      perPage: 1000,
      auto: false,
      autoStopPage: 18,
      preview: [],
      from: '',
      to: ''
    };
    this.fileInput = React.createRef();
  }

    sendData=()=>{
        let d = [];
        let page = parseInt(this.state.page);
        let perPage = parseInt(this.state.perPage);

        for(var i = (page * perPage); i <= (page * perPage) - 1 + perPage; i++){
            d.push(items.data[i]);
        }

        console.log('items to send:', d);
        Service.get('/routes/import',{routes: d, solution: 1},(res)=>{
          if(res.success){
            this.setState({page: parseInt(this.state.page) + 1},()=>{
                if(this.state.page <= this.state.autoStopPage){
                    if(this.state.auto === true){
                        this.sendData();
                    }
                }
              
            }
            )
          }
        });
    }

  handlePage=(event)=>{
      this.setState({page: event.target.value});
  }
  handleRoute=(event)=>{
      this.setState({route: event.target.value});
  }

  handlePerPage=(event)=>{
      this.setState({perPage: event.target.value});
  }

  showFile = () => {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
         var preview = document.getElementById('show-text');
         var file = document.querySelector('input[type=file]').files[0];
         var reader = new FileReader()

         var textFile = /text.*/;

        //  var state = this.state;

         if (file.type.match(textFile)) {
             let data = [];
            reader.onload = function (event) {

                items.data = event.target.result.split(/\r?\n/);
                console.log(items.data);
               //preview.innerHTML = items.data.slice(1,10);//event.target.result.slice(0,50000);
               //data = partners;
            //    state.setState({partners: items},()=>{
            //        console.log(state.partners);
            //    });
               
               //console.log(event.target.result.slice(0,500000).split(/\r?\n/));
               //Service.get('/test/parseSellin',{sellin: event.target.result.slice(0,50000)});
            }
         } else {
            preview.innerHTML = "<span class='error'>It doesn't seem to be a text file!</span>";
         }
         reader.readAsText(file);

   } else {
      alert("Your browser is too old to support HTML5 File API");
   }
  }

    switchAuto=()=>{
        this.setState({auto: !this.state.auto});
    }
    
    handleAutoStop=(event)=>{
        this.setState({autoStopPage: event.target.value});
    }

    handleFrom=(event)=>{
        this.setState({from: event.target.value});
    }
    handleTo=(event)=>{
        this.setState({to: event.target.value});
    }


    handleReadCSV = (data) => {
        console.log("data", data);
        // this.setState({
        //         sellOut: data.data
        //     });
        items.data = data.data;
        this.showPreview(items.data);
        console.log( data.data);
    }

    handleImportOffer = () => {
        this.fileInput.current.click();
    }

    // sendData=()=>{

    //     let p = this.state.sellOut.slice(3, 1000);
    //     Service.get('/import/sellOut',{sellOut: p},(res)=>{
    //         console.log(res)
    //     })
    // }

    showPreview=(data)=>{
        this.setState({preview: data.slice(0, 5)});
    }

  render() {
    return (
      <div>
        <h2>IMPORT ROUTES</h2>
        {/* <input type="file" onChange={this.showFile} /> */}
        <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{display: 'none'}}
          onError={this.handleOnError}
        />
        {/* <p className='hint' >Klikni dugme 'Odaberi CSV' da uneses proizvode iz CSV fajla</p> */}
        <div className='instructions'>
          <p>Instructions:</p>
          <ol>
            <li>Choose CSV file</li>
            <li>Set: Page = 0</li>
            <li>Set: Per Page = 1000</li>
            <li>Set: Page Stop = Number of CSV file rows / Per Page. If file has 17340 rows (stores), then 17340/1000 = 18</li>
            <li>Check:  AUTO to checked</li>
            <li>Click SEND</li>
          </ol> 

          <p>CSV example:</p>
          <table className="import-preview-table">
            <thead>
              <tr className="table-head-fixed">
                <th>Шифра објекта</th>
                <th>Купац</th>
                <th>Адреса</th>
                <th>Град</th>
                <th>Корисничко име</th>
                <th>Комерцијалиста</th>
                <th>Фреквенција посете</th>
                <th>ПОН 19.07.2021</th>
                <th>УТО 20.07.2021</th>
                <th>СРЕ 21.07.2021</th>
                <th>ЧЕТ 22.07.2021</th>
                <th>ПЕТ 23.07.2021</th>
                <th>СУБ 24.07.2021</th>
                <th>НЕД 25.07.2021</th>
                <th>ПОН 26.07.2021</th>
                <th>УТО 27.07.2021</th>
                <th>СРЕ 28.07.2021</th>
                <th>ЧЕТ 29.07.2021</th>
                <th>ПЕТ 30.07.2021</th>
                <th>СУБ 31.07.2021</th>
                <th>НЕД 01.08.2021</th>
                <th>ПОН 02.08.2021</th>
                <th>УТО 03.08.2021</th>
                <th>СРЕ 04.08.2021</th>
                <th>ЧЕТ 05.08.2021</th>
                <th>ПЕТ 06.08.2021</th>
                <th>СУБ 07.08.2021</th>
                <th>НЕД 08.08.2021</th>
                <th>ПОН 09.08.2021</th>
                <th>УТО 10.08.2021</th>
                <th>СРЕ 11.08.2021</th>
                <th>ЧЕТ 12.08.2021</th>
                <th>ПЕТ 13.08.2021</th>
                <th>СУБ 14.08.2021</th>
                <th>НЕД 15.08.2021</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td>S0466914</td>
                <td>MAXI - 220</td>
                <td>Promenada 9 b</td>
                <td>SREMSKA MITROVICA</td>
                <td>M1A5</td>
                <td>Јелена Грабовац</td>
                <td>7</td>
                <td></td>
                <td>+</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>+</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>+</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>+</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          
          </table>
        </div>
        <hr></hr>
        <button onClick={this.handleImportOffer}>Odaberi CSV</button>
        
        <br />
        PAGE: <input type="text" value={this.state.page} onChange={this.handlePage} />
        PER PAGE: <input type="text" value={this.state.perPage} onChange={this.handlePerPage} />
        <br />
        {/* DATE FROM: <input type="text" value={this.state.from} onChange={this.handleFrom} />
        <br />
        DATE TO: <input type="text" value={this.state.to} onChange={this.handleTo} />
        <br /> */}
        {/* ROUTE: <input type="text" value={this.state.route} onChange={this.handleRoute} />
        <br /> */}
        PAGE STOP: <input type="text" value={this.state.autoStopPage} onChange={this.handleAutoStop} /><br />
        {this.state.auto == false &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'gray'}}>AUTO</button>
        }
        {this.state.auto == true &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'red'}}>AUTO</button>
        }
        <button onClick={this.sendData}>Posalji</button>
        <div id="show-text">Choose text File</div>
        <div>
            {this.state.preview.map(function(item, index){
                return(
                    <p>{item}</p>
                )
                })
            }
        </div>
        
        {/* <button onClick={this.state.showFile}>Pregledaj</button> */}
        
        
        
      </div>
    );
  }

}

export default ImportRoutes;