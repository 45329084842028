import React, { Component } from 'react';
import Service from '../components/Service';
import { CSVReader } from 'react-papaparse';
import DateTime from '../components/DateTime';

const items = {
    data: []
};
class ImportCustomCsv extends Component {
constructor() {
    super();
    this.state = {
      name: 'React',
      partners: [],
      page: 0,
      perPage: 20,
      route: '',
      auto: false,
      autoStopPage: 100,
      preview: [],
      from: '',
      to: '',
      selectedStore: '',
      selectedSalesType: '',
      selectedUnit: '',
      fileName: '',
      importInfo: '',
      dateTest: 0
    };
    this.fileInput = React.createRef();
  }

    sendData=()=>{
        let d = [];
        let page = parseInt(this.state.page);
        let perPage = parseInt(this.state.perPage);
        console.log((page * perPage), (page * perPage) - 1 + perPage);
        if(page > 0){
            d.push(items.data[0]);
        }
        for(var i = (page * perPage); i <= (page * perPage) - 1 + perPage; i++){
            if(items.data[i]){
                if(items.data[i].length > 1){
                    d.push(items.data[i]);
                }
            }
        }
        this.setState({importInfo: 'sending page '+page});
        console.log('items to send:', d);
        console.log('/import/sellOut'+this.state.selectedStore, {import: d, sellOut: d, dateFrom: this.state.from, dateTo: this.state.to} );
        //return;
        Service.get('/import/sellOut'+this.state.selectedStore,{
            import: d, 
            sellOut: d, 
            dateFrom: this.state.from, 
            dateTo: this.state.to,
            salesType: this.state.selectedSalesType,
            unit: this.state.selectedUnit,
            fileName: this.state.fileName
            
            },(res)=>{
          if(res.success){
              this.setState({importInfo: 'import done.'});
            this.setState({page: parseInt(this.state.page) + 1},()=>{
              if(this.state.page <= this.state.autoStopPage){
                  if(this.state.auto === true){
                    this.sendData();
                  }
              }
              
            }
            )
          }
        });
    }

  handlePage=(event)=>{
      this.setState({page: event.target.value});
  }
  handleRoute=(event)=>{
      this.setState({route: event.target.value});
  }

  handlePerPage=(event)=>{
      this.setState({perPage: event.target.value},()=>{
        this.setState({autoStopPage: Math.floor(items.data.length / this.state.perPage)});
      });
  }

  stores = ['Dis', 'Gomex', 'Metalac'];

  changeStore=(event)=>{
    console.log(event.target.value);
    this.setState({selectedStore: event.target.value})
  }

salesTypes = ['maloprodaja', 'veleprodaja'];
  changeSalesType=(event)=>{
    console.log(event.target.value);
    this.setState({selectedSalesType: event.target.value})
  }

  units = ['kg', 'kom'];

  changeUnit=(event)=>{
    console.log(event.target.value);
    this.setState({selectedUnit: event.target.value})
  }

  showFile = () => {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
         var preview = document.getElementById('show-text');
         var file = document.querySelector('input[type=file]').files[0];
         var reader = new FileReader()

         var textFile = /text.*/;

        //  var state = this.state;

         if (file.type.match(textFile)) {
             let data = [];
            reader.onload = function (event) {

                items.data = event.target.result.split(/\r?\n/);
                console.log(items.data);
               //preview.innerHTML = items.data.slice(1,10);//event.target.result.slice(0,50000);
               //data = partners;
            //    state.setState({partners: items},()=>{
            //        console.log(state.partners);
            //    });
               
               //console.log(event.target.result.slice(0,500000).split(/\r?\n/));
               //Service.get('/test/parseSellin',{sellin: event.target.result.slice(0,50000)});
            }
         } else {
            preview.innerHTML = "<span class='error'>It doesn't seem to be a text file!</span>";
         }
         reader.readAsText(file);

   } else {
      alert("Your browser is too old to support HTML5 File API");
   }
  }

    switchAuto=()=>{
        this.setState({auto: !this.state.auto});
    }
    
    handleAutoStop=(event)=>{
        this.setState({autoStopPage: event.target.value});
    }

    handleFrom=(event)=>{
        this.setState({from: event.target.value});
    }
    handleTo=(event)=>{
        this.setState({to: event.target.value});
    }
    handleFileName=(event)=>{
        this.setState({fileName: event.target.value});
    }

    handleReadCSV = (data) => {
        console.log(data);
        // this.setState({
        //         sellOut: data.data
        //     });
        items.data = data.data;
        this.showPreview(items.data);
        console.log(items.data.length);
        this.setState({autoStopPage: Math.floor(items.data.length / this.state.perPage)});
    }

    handleImportOffer = () => {
        this.fileInput.current.click();
    }

    // sendData=()=>{

    //     let p = this.state.sellOut.slice(3, 1000);
    //     Service.get('/import/sellOut',{sellOut: p},(res)=>{
    //         console.log(res)
    //     })
    // }

    showPreview=(data)=>{
        this.setState({preview: data.slice(0, 5)});
    }

  render() {
    return (
      <div>
        <h2>IMPORT SELL OUT MULTI (dis, gomex)</h2>
        {/* <input type="file" onChange={this.showFile} /> */}
        <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{display: 'none'}}
          onError={this.handleOnError}
        />
        <p className='hint' >Browse file containing sales data in CSV (comma separated) format. Then select the customer and choose value types</p>
        <button onClick={this.handleImportOffer}>Odaberi CSV</button>
        <select value={this.state.selectedStore} onChange={this.changeStore}>
          <option value='' >Customer:</option>
            {this.stores.map(function(item, index){
              return(
                <option value={item} key={index}>{item}</option>
              )},this
            )};
          </select>
          <select value={this.state.selectedSalesType} onChange={this.changeSalesType}>
          <option value='' >Select sales:</option>
            {this.salesTypes.map(function(item, index){
              return(
                <option value={item} key={index}>{item}</option>
              )},this
            )};
          </select>
          <select value={this.state.selectedUnit} onChange={this.changeUnit}>
          <option value='' >Select unit:</option>
            {this.units.map(function(item, index){
              return(
                <option value={item} key={index}>{item}</option>
              )},this
            )};
          </select>
        <section>
        PAGE: <input type="text" value={this.state.page} onChange={this.handlePage} />
        PER PAGE: <input type="text" value={this.state.perPage} onChange={this.handlePerPage} />
        </section>
        {/* DATE FROM: <input type="text" value={this.state.from} onChange={this.handleFrom} />
        <br /> */}
        <section>
        <label>Date From:</label>
            <DateTime placeholder="date from" text="" selected={this.state.date} showTimeSelect={false} onChange={(date)=>{ this.setState({from: date})}}/>
        <label>Date To:</label>
            <DateTime placeholder="date to" text="" selected={this.state.date} showTimeSelect={false} onChange={(date)=>{ this.setState({to: date})}}/>
            <p className="note" >* if sheet data time period is one month, there is no need to set end date.</p>
        </section>
        {/* DATE TO: <input type="text" value={this.state.to} onChange={this.handleTo} />
        <br /> */}
        <br />
        FILE NAME: <input type="text" value={this.state.fileName} onChange={this.handleFileName} />
        <p className="note" >* enter the file name for future reference.</p>
        {/* ROUTE: <input type="text" value={this.state.route} onChange={this.handleRoute} />
        <br /> */}
        PAGE STOP: <input type="text" value={this.state.autoStopPage} onChange={this.handleAutoStop} /><br />
        {this.state.auto == false &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'gray'}}>AUTO</button>
        }
        {this.state.auto == true &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'red'}}>AUTO</button>
        }
        <button onClick={this.sendData}>Posalji</button>
        <p className="import-info">{this.state.importInfo}</p>
        <div id="show-text">Choose text File</div>
        <div>
            {this.state.preview.map(function(item, index){
                return(
                    <p>{item}</p>
                )
                })
            }
        </div>
        
        {/* <button onClick={this.state.showFile}>Pregledaj</button> */}
        
        
        
      </div>
    );
  }

}

export default ImportCustomCsv;