import React from "react";
import { BrowserRouter as Router, Route, Link, Redirect} from "react-router-dom";
import HomePage from './views/home';
import Header from './views/Header';
import Analisys from './views/Analisys';
import Login from './views/Login';
import MyAccount from './views/MyAccount';
import {Auth} from './components/Auth';
import Register from './views/Register';
import Import from './views/Import';
import Projects from './views/Projects';
import Project from './views/Project';
import Logs from './views/Logs';
import ProductsImport from './components/ProductsImport';
import Associates from './views/Associates';
import Leaflets from './views/Leaflets';
import Actions from './views/Actions';
import Action from './views/Action';
import Sales from './views/Sales';
import Products from './views/Products';
import Map from './views/Map';
import Nielsen from './views/Nielsen';
import Prediction from './views/Prediction';
import Brands from './views/Brands';
import Survey from './views/Survey';
import SurveyDev from './views/SurveyDev';
import Ml from './views/Ml';
import Routes from './views/Routes';


const test = () => {

  if (localStorage.getItem('token') && localStorage.getItem('userId')) {
    Auth.isAuthenticated = true;
    Auth.token = localStorage.getItem('token');
    Auth.userId = localStorage.getItem('userId');
  }
  else Auth.isAuthenticated = false;
}
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
  )} />
)

const App = () => (

  <Router>
    <div>
      <Header />
      <div>
      <Route exact path="/" component={HomePage} />
      <Route path="/import" component={Import} />
      <Route path="/:lang/about" component={About} />
      <Route path="/topics" component={Topics} />
      <Route path="/analisys" component={Analisys} />
      <Route path="/:lang/analisys" component={Analisys} />
      <Route path="/associates" component={Associates} />
      <Route path="/logs" component={Logs} />
      <Route path="/leaflets" component={Leaflets} />
      <Route exact path="/actions" component={Actions} />
      <Route exact path="/action/:id?" component={Action} />
      <Route path="/sales" component={Sales} />
      <Route path="/products" component={Products} />
      <Route path="/brands" component={Brands} />
      <Route path="/map" component={Map} />
      <Route path="/nielsen" component={Nielsen} />
      <Route path="/prediction" component={Prediction} />
      <Route path="/importtxt" component={Import} />
      <Route path="/login" component={Login} />
      <PrivateRoute path="/projects" component={Projects} />
      <Route path="/project/:id" component={Project} />
      <Route path="/register" component={Register} />
      <Route path="/survey" component={Survey} />
      <Route path="/survey-dev" component={SurveyDev} />
      <Route path="/survey-dev" component={SurveyDev} />
      <Route path="/ml" component={Ml} />
      <Route path="/routes" component={Routes} />

      <PrivateRoute path="/my-account" component={MyAccount} />
      
      </div>
      <Footer />
    </div>
  </Router>
);

{test()}
//const Home = () => <h2>Home test</h2>;
const About = ({ match }) => <h2>About {match.params.lang}</h2>;
const Topic = ({ match }) => <h3>Requested Param: {match.params.id}</h3>;
const Topics = ({ match }) => (
  <div>
    <h2>Topics</h2>

    <ul>
      <li>
        <Link to={`${match.url}/components`}>Components {match.path}</Link>
      </li>
      <li>
        <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
      </li>
    </ul>

    <Route path={`${match.path}/:id`} component={Topic} />
    <Route
      exact
      path={match.path}
      render={() => <h3>Please select a topic.</h3>}
    />

  </div>
);


const Footer = () => (
  <div className="footer">Powered by <b>BrainIt</b></div>
);

export default App;
