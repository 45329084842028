import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import Service from '../components/Service';
import TreeMenu from 'react-simple-tree-menu';
import '../../node_modules/react-simple-tree-menu/dist/main.css';

class Associates extends Component {

    constructor(props){
        super(props);
        this.state = {
            associates: [],
            parents: [],
            regions: [],
            ba: [
                {name: 'test1', id: 1, parentId: 0},
                {name: 'test2', id: 2, parentId: 1},
                {name: 'test3', id: 3, parentId: 1},
                {name: 'test4', id: 4, parentId: 4},
                {name: 'test5', id: 5, parentId: 3},
                {name: 'test6', id: 6, parentId: 3},
            ],
            treeData: [
                {
                    key: 'first-level-node-1',
                    label: 'Node 1 at the first level',
                    nodes: [
                    {
                        key: 'second-level-node-1',
                        label: 'Node 1 at the second level',
                        nodes: [
                        {
                            key: 'third-level-node-1',
                            label: 'Last node of the branch',
                            nodes: [] // you can remove the nodes property or leave it as an empty array
                        },
                        ],
                    },
                    ],
                },
                {
                    key: 'first-level-node-2',
                    label: 'Node 2 at the first level',
                },
            ]
        }

    }

    createParents=()=>{
        let a = {}
        this.state.associates.map(function(item, index){
            a[item.id] = item.name;
        })
        this.setState({parents: a});
    }

    findLevel1Items(o, parentId){
        let r = []
        for(var p in o){
            if(parseInt(o[p].parent_business_associates_id) == parentId){
                r.push({
                    key: o[p].id,
                    label: o[p].name,
                    nodes: this.findLevel2Items(o, o[p].id)
                })
            }
        }
        return r;
    }

    findLevel2Items(o, parentId){
        let r = []
        for(var p in o){
            if(parseInt(o[p].parent_business_associates_id) == parentId){
                r.push({
                    key: o[p].id,
                    label: o[p].name,
                    nodes: this.findLevel3Items(o, o[p].id)
                })
            }
        }
        return r;
    }

    findLevel3Items(o, parentId){
        let r = []
        for(var p in o){
            if(parseInt(o[p].parent_business_associates_id) == parentId){
                r.push({
                    key: o[p].id,
                    label: o[p].name,
                    nodes: this.findLevel4Items(o, o[p].id)
                })
            }
        }
        return r;
    }

    findLevel4Items(o, parentId){
        let r = []
        for(var p in o){
            if(parseInt(o[p].parent_business_associates_id) == parentId){
                r.push({
                    key: o[p].id,
                    label: o[p].name
                })
            }
        }
        return r;
    }

    createTreeData=(o)=>{
        let l1 = [];
        let l2 = [];
        let l3 = [];
        for(var p in o){
            if(parseInt(o[p].management_level_id) == 0){
                l1.push({
                    key: o[p].id,
                    label: o[p].name,
                    nodes: this.findLevel1Items(o, o[p].id)
                })
            }
            if(parseInt(o[p].management_level_id) == 2){
                l2.push({
                    key: o[p].id,
                    label: o[p].name
                })
            }
            if(parseInt(o[p].management_level_id) == 3){
                l3.push({
                    key: o[p].id,
                    label: o[p].name
                })
            }
        }

        console.log(l1);
        return l1;
    }

    componentDidMount=()=>{
        Service.get('/analytics/getBusinessAssociates',{},(res)=>{
            this.setState({
                treeData: this.createTreeData(res.data.business_associates),
                associates: res.data.business_associates
                },()=>{
                    this.createParents();
                });
            //this.createTreeData(res.data.business_associates);
        });
    }

  render() {
    return (
      <div className="content">
          <h1>Business Associates</h1>
          {/* <h2>Associates</h2> */}
          {/* <TreeMenu data={this.state.treeData}/> */}
          <table className="preview-table">
                <tr>
                    <td>Id</td>
                    <td>Name</td>
                    <td>Management level</td> 
                    <td>Superior</td>  
                </tr>
          {this.state.associates.map((item, index)=>{
              return(
                  <tr>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.management_level_id}</td>
                        <td>{this.state.parents[item.parent_business_associates_id]}</td>
                                        
                    </tr>
              )
          }, this)
          }
          </table>
      </div>
      
    )
  }
}

export default Associates;