import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import Service from '../components/Service';
import Selector from '../components/Selector';
import DateSelector from '../components/DateSelector';

const types = [{id:'global', label: 'global'},
{id:'product', label: 'product'}, 
{id:'subbrand', label: 'subbrand'}, 
{id:'brand', label: 'brand'}]

class Actions extends Component {

    constructor(props){
        super(props);
        this.state = {
            type: 'global',
            competition: true,
            magnitude: 0,
            value: null,
            from: null,
            to: null,
            name: null,
            description: null,
            actions: []
        }

    }

    componentDidMount=()=>{
        // Service.get('/test/getRegions',{},(res)=>{
        //     this.setState({regions: res.data.regions});
        // });
        this.updateActions();
    }

    updateActions=()=>{
        Service.get('/import/getActions',{},(res)=>{
            //this.setState({regions: res.data.regions});
            if(res.success){
                this.setState({actions: res.data});
            }
        });
    }

    updateType=(data)=>{
        console.log('data', data);
        if(data[0]){
            this.setState({type: data[0].value, value: null},()=>{
                console.log('state type', this.state.type)
            })
        }else{
            this.setState({type: 'global', value: null})
        }
    }

    updateBrands=(data)=>{
        console.log('data', data);
        if(data[0]){
            this.setState({value: data[0].value},()=>{
                console.log('state type', this.state.type)
            })
        }else{
            this.setState({type: 'global', value: null})
        }
    }

    updateSubBrands=(data)=>{
        console.log('data', data);
        if(data[0]){
            this.setState({value: data[0].value},()=>{
                console.log('state type', this.state.type)
            })
        }else{
            this.setState({type: 'global', value: null})
        }
    }

    updateProducts=(data)=>{
        console.log('data', data);
        if(data[0]){
            this.setState({value: data[0].value},()=>{
                console.log('state type', this.state.type)
            })
        }else{
            this.setState({type: 'global', value: null})
        }
    }

    changeFrom=(date)=>{
        this.setState({from: date});
    }

    changeTo=(date)=>{
        this.setState({to: date});
    }

    addCampagin=()=>{

        
        Service.get('/import/createAction',{
            dateOpened: this.state.from,
            dateClosed: this.state.to,
            actionsName: this.state.name,
            description: this.state.description,
            magnitude: this.state.magnitude,
            type: this.state.type,
            typeValue: this.state.value,
        },(res)=>{
            //this.setState({regions: res.data.regions});
            if(res.success){
                alert('Action/campagin successfuly added.');
                this.resetState();
            }else{
                alert('ERROR - ' + res.error);
            }
        });
    }

    resetState=()=>{
        this.setState({
            type: 'global',
            competition: true,
            magnitude: 0,
            value: null,
            from: null,
            to: null,
            name: null,
            description: null
        })
    }

    handleInput = (event) => {
        let name = event.target.getAttribute('data-name');
        console.log('name', name, event.target.value)
        this.setState({[name]: event.target.value}, ()=>{console.log(this.state)});
    };

  render() {
    return (
      <div>
            <h1>Actions</h1>
            <h2>Marketing campagins input</h2>
            <h3>Time period</h3>
            <DateSelector label="From" onChange={this.changeFrom} defaultYear={2019} defaultMonth={1} />
            <DateSelector label="To" onChange={this.changeTo} defaultYear={2020} defaultMonth={1} />
            <br />Name:
            <br /><input data-name='name' onChange={this.handleInput} value={this.state.name}/>
            <br />Description:
            <br /><textarea data-name='description' onChange={this.handleInput} value={this.state.description} size="50" ></textarea>
            <br />Magnitude:
            <br /><input type="number" data-name='magnitude' onChange={this.handleInput} value={this.state.magnitude}/>
            <br />
            <p className="import-info">*use magnitude to describe the campagin influence relative to your previous campagins. Consider media type (tv, radio, internet), budget, type (commercial, game, quiz, gift).</p>
            <br />
            <p className="import-info">*use negative magnitude value to describe the competition campagins if any.</p>
            <br />
            <h2>Type</h2>
            <Selector onChange={this.updateType} options={types} type='single' filters={{competition: this.state.competition}} placeholder="Campagin type"/>
            <p className="import-info">*select to choose on which type of product clasification the campain is pointing. Global, brand, subbrand or a single product.</p>
            {this.state.type == 'brand' &&
            <fragment>
                <h2>Brands</h2>
                <Selector onChange={this.updateBrands} type='single' route='/analytics/getAllBrands' filters={{competition: this.state.competition}} placeholder="Brands..."/>
            </fragment>
            }
            {this.state.type == 'subbrand' &&
            <fragment>
             <h2>Sub Brands</h2>
                <Selector onChange={this.updateSubBrands} type='single' route='/analytics/getAllSubBrands' filters={{competition: this.state.competition, brands: this.state.selectedBrands}} placeholder="Subbrands..."/>
            </fragment>
            }
            {this.state.type == 'product' &&
            <fragment>
                <h2>Products</h2>
                <Selector onChange={this.updateProducts} type='single' route='/analytics/getAllProducts' filters={{competition: this.state.competition, brands: this.state.selectedBrands, subBrands: this.state.selectedSubBrands}} placeholder="Products..."/>
            </fragment>
            }
            <div>
                <button className="keyword" onClick={this.addCampagin}>Save Campagin</button>
            </div>
            <hr />
            <h2>Actions list</h2>
            <div>
                {this.state.actions.map(function(item){
                    return(
                        // <p>{JSON.stringify(item)}</p>
                         <p><b>{item.actions_name}</b>, {item.description} {item.date_opened} - {item.date_closed}, magnitude: {item.magnitude}, type: {item.type}, value: {item.type_value} </p>
                    )
                })
                }
            </div>
      </div>
      
    )
  }
}

export default Actions;