import React, { Component } from 'react';
import Service from '../components/Service';

import { CSVReader } from 'react-papaparse';

function twoDigits(d) {
    if(0 <= d && d < 10) return "0" + d.toString();
    if(-10 < d && d < 0) return "-0" + (-1*d).toString();
    return d.toString();
}

Date.prototype.toMysqlFormat = function() {
    return this.getUTCFullYear() + "-" + twoDigits(1 + this.getUTCMonth()) + "-" + twoDigits(this.getUTCDate()) + " " + twoDigits(this.getUTCHours()) + ":" + twoDigits(this.getUTCMinutes()) + ":" + twoDigits(this.getUTCSeconds());
};
// const items = {
//     sellOut: []
// };
class ImportSellOut extends Component {
constructor() {
    super();
    this.state = {
      sellOut: [],
      selectedStore: '',
      fileInput:'',
      page: 0,
      perPage: 500,
      auto: false,
      autoStopPage: 100,
      preview: [],
      from: '',
      to: '',
      logs: {},
      logsDate: new Date().toISOString().slice(0, 19).replace('T', ' '),
      sessionFront: new Date().getTime(),
      previewData: [],
      columns: ['-','-','-','-','-','-','-','-','-','-','-','-'],
      presets: [],
      presetName: '',
      selectedPresetName: '',
      importInfo: ''
    };
    
    this.fileInput = React.createRef();
  }

  updateSessionFront=()=>{
      this.setState({sessionFront: new Date().getTime()});
  }

  stores = ['Metro',   
            'Delhaize',
            'Lidl',
            'Mercator',
            'Univerexport',
            'Veropulous',
            'Aman',
            'Europrom',
            'Aroma',
            'Sap',
            'Metalac',
            'Dis',
            'Gomex',
            'Medius'
            ]

    componentDidMount(){
        console.log('session_front', this.state.sessionFront);
        this.getPresets();
        // this.setState({columns: this.options});
        // this.getLogs();
    }

    savePreset=()=>{

     Service.get(`/import/saveEdiPreset`,{
             name: this.state.presetName, 
             customer: this.state.selectedStore, 
             fields: this.state.columns
             },(res)=>{
            if(res.success){
                console.log('preset saved');
                this.getPresets();
            }
         })
    }

    saveCurrentPreset=()=>{

     Service.get(`/import/saveEdiPreset`,{
             name: this.state.selectedPresetName, 
             customer: this.state.selectedStore, 
             fields: this.state.columns
             },(res)=>{
            if(res.success){
                console.log('preset saved');
                alert('Preset je snimljen.');
            }else{
                alert('Greška! Preset nije snimljen. Verovatno niste odabrali kupca.');
            }
         })
    }

    getPresets=()=>{

        Service.get(`/import/getEdiPresets`,{},(res)=>{
            if(res.success){
                this.setState({presets: res.data.presets});
            }
         })
    }

    loadPreset=(event)=>{
        console.log(event.target.value);
        console.log('presets',this.state.presets[event.target.value].fields);
        let f = this.state.presets[event.target.value].fields;
        let n = this.state.presets[event.target.value].name;
        this.setState({columns: f, selectedPresetName: n})
        // Service.get(`/import/getEdiPreset`,{
        //      name: this.state.presetName, 
        //      customer: this.state.selectedStore, 
        //      fields: this.state.columns
        //      },(res)=>{
        //     if(res.success){
        //         console.log('preset saved');
        //     }
        //  })
    }


  handleReadCSV = (data) => {
    console.log(data);
    this.setState({
            sellOut: data.data,
            fileName: this.fileInput.current.files[0].name
          },()=>{
              this.updateSessionFront();
              this.getPreview();
              });
    console.log(data.data);
  }

  handleFrom=(event)=>{
        this.setState({from: event.target.value});
    }
    handleTo=(event)=>{
        this.setState({to: event.target.value});
    }

  handleImportOffer = () => {
    this.fileInput.current.click();
    // console.log("Ime fajla:",this.fileInput.current)
  }

    options = [ '-',
        'productsCode',
        'amount',
        'netAmount',
        'dateFrom',
        'dateTo',
        'sapCode',
        'unit',
        'barcode',
        'salesType'
    ]

        

    getPreview=()=>{
        this.setState({page: 0}, ()=>{
            let d = [];
            let page = parseInt(this.state.page);
            let perPage = parseInt(this.state.perPage);
            for(var i = (page * perPage); i <= (page * perPage) - 1 + perPage; i++){
                d.push(this.state.sellOut[i]);
            }
            let p = d;
            
            this.setState({previewData: p},()=>{
                console.log('data', this.state.previewData);
            });
            //console.log(p);
        })

        // let d = [];
        // let page = parseInt(this.state.page);
        // let perPage = parseInt(this.state.perPage);
        // for(var i = (page * perPage); i <= (page * perPage) - 1 + perPage; i++){
        //     let r = {}
        //     for(var j in this.state.sellOut[i]){
        //         r[this.columns[j]] = this.state.sellOut[i][j];
        //     }
        //     d.push(r);
        // }
        // let p = d;

        // this.setState({previewData: p});
        // console.log(p);

      if(this.state.selectedStore || this.state.selectedStore != 0){
            
        //  Service.get(`/import/sellOut${this.state.selectedStore}`,{
        //      sellOut: p, 
        //      fileName:this.fileInput.current.files[0].name, 
        //      preview: true,dateFrom: this.state.from,
        //      dateTo: this.state.to,
        //      page: this.state.page
        //      },(res)=>{
        //     if(res.success){
        //         this.setState({preview: res.preview_data});
        //     }
        //  })
      }
    }

    sendData=()=>{

        // let d = [];
        // let page = parseInt(this.state.page);
        // let perPage = parseInt(this.state.perPage);
        // console.log((page * perPage), (page * perPage) - 1 + perPage);
        // for(var i = (page * perPage); i <= (page * perPage) - 1 + perPage; i++){
        //     d.push(this.state.sellOut[i]);
        // }
        // console.log('items to send:', d);

        // let p = d;
        // console.log(p);

        let d = [];
        let page = parseInt(this.state.page);
        let perPage = parseInt(this.state.perPage);
        let pageStop = Math.ceil(parseInt(this.state.sellOut.length) / perPage);
        // console.log('page stop', pageStop);
        // return;
        for(var i = (page * perPage); i <= (page * perPage) - 1 + perPage; i++){
            let r = {}
            for(var j in this.state.sellOut[i]){
                if(this.state.columns[j] != '-'){
                    r[this.state.columns[j]] = this.state.sellOut[i][j];
                }
            }
            d.push(r);
        }
        let p = d;
        // console.log(p);
        // return;
        this.setState({importInfo: 'sending page '+page});
        
        if(this.state.selectedStore || this.state.selectedStore != 0){
            //  Service.get(`/import/sellOut${this.state.selectedStore}`,{
            Service.get(`/import/sellOutUniversal`,{
                sellOut: p,
                fileName:this.fileInput.current.files[0].name,
                sessionFront: this.state.sessionFront,
                dateFrom: this.state.from,
                dateTo: this.state.to,
                page: this.state.page,
                perPage: this.state.perPage,
                customer: this.state.selectedStore
                },(res)=>{
                if(res.success){
                    this.setState({importInfo: 'import done.'});
                    this.getLogs();
                this.setState({page: parseInt(this.state.page) + 1},()=>{
                    
                // if(this.state.page <= this.state.autoStopPage){
                if(this.state.page <= pageStop){
                    if(this.state.auto === true){
                        this.sendData();
                    }
                }
                
                
                }
                )
            }
            })
        }else{
            alert('Morate odabrati kupca!');
        }
  }

  changeStore=(event)=>{
    console.log(event.target.value);
    this.setState({selectedStore: event.target.value})
  }

  switchAuto=()=>{
      this.setState({auto: !this.state.auto});
  }

    handlePage=(event)=>{
        this.setState({page: event.target.value});
    }

    handlePerPage=(event)=>{
        this.setState({perPage: event.target.value});
    }
    handleAutoStop=(event)=>{
        this.setState({autoStopPage: event.target.value});
    }

    getLogs=()=>{
        console.log(this.state.logsDate);
        Service.get('/log/getLogs',{logType: 'sell_out', page: 0, perPage: 100, date: this.state.logsDate},(res)=>{
            if(res.success){
                this.setState({logs: res.data.logs});
                console.log(res);
            }
         })
    }

    changeColumn=(event)=>{
        console.log(event.target.id, event.target.value);
        let c = this.state.columns;
        for(var p in c){
            if(c[p] === event.target.value && event.target.value != this.options[0]){
                console.log('to',this.options);

                c[p] = this.options[0].toString();
            }
        }
        c[event.target.id] = event.target.value;
        this.setState({columns: c});
    }

    handlePresetName=(event)=>{
        this.setState({presetName: event.target.value});
    }


  render() {
    return (
      <div>
          <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{display: 'none'}}
          onError={this.handleOnError}
          
        />
        <p className='hint' >Klikni dugme 'Odaberi CSV' da uneses proizvode iz CSV fajla</p>
        <p>Odabrani fajl: <b>{this.state.fileName}</b></p>
        <button onClick={this.handleImportOffer} >Odaberi CSV</button>
        <select value={this.state.selectedStore} onChange={this.changeStore}>
          <option value='' >Odaberi kupca:</option>
            {this.stores.map(function(item, index){
              return(
                <option value={item} key={index}>{item}</option>
              )},this
            )};
          </select>
          <br />
          PAGE: <input type="text" value={this.state.page} onChange={this.handlePage} /><br />
        PER PAGE: <input type="text" value={this.state.perPage} onChange={this.handlePerPage} /><br />
        {/* PAGE STOP: <input type="text" value={this.state.autoStopPage} onChange={this.handleAutoStop} /><br /> */}
        {this.state.auto == false &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'gray'}}>AUTO</button>
        }
        {this.state.auto == true &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'red'}}>AUTO</button>
        }
        {/* AMAN */}
        {this.state.selectedStore === 'Aman' &&
            <span>
                <br />
                DATE FROM: <input type="text" value={this.state.from} onChange={this.handleFrom} />
                <br />
                DATE TO: <input type="text" value={this.state.to} onChange={this.handleTo} />
                <br />
            </span>
        }
        <button onClick={this.sendData} className='keyword' >Submit</button>
        <p className="import-info">{this.state.importInfo}</p>
        {/* <button onClick={this.getPreview} className='keyword' >Preview</button> */}
        {this.state.sellOut.length > 0 &&
            <h5>File Loaded.</h5>

        }
        {/* {this.state.sellOut.map(function(item, index){
            return(
                      <p>{item.toString()}</p>
                        
              );
          })} */}
          <hr/>
        <div>
            <h3>Preseti</h3>
            <h4>Kreiraj novi</h4>
            <p>Kreiraj novi preset na osnovu selektovanih polja</p>
            <input type="text" value={this.presetName} onChange={this.handlePresetName} />
            <button onClick={this.savePreset}>Dodaj</button>
            <h4>Odaberi preset</h4>
            <select onChange={this.loadPreset} >
                <option>-</option>
                {this.state.presets.map(function(item,index){
                    return (
                        <option value={index}>{item.name}</option>
                    );
                },this)} 
            </select> <button onClick={this.saveCurrentPreset}>Sacuvaj promene</button>
        </div>
        <hr/>
        <h3>Pregled</h3>
        {this.state.previewData.length > 0 &&
        <div className='table-cont table-short'>
            <table className="preview-table">
                <tr>
                    {this.state.previewData[1].map(function(item, index){

                        return (
                            <td>
                                <select id={index} onChange={this.changeColumn} value={this.state.columns[index]} >
                                    {this.options.map(function(itemOption,index){
                                        return (
                                            <option>{itemOption}</option>
                                        );
                                    },this)} 
                                </select>    
                            </td>
                        );
                    },this)} 
                </tr>
                {this.state.previewData.map(function(itemBig, index){
                    if(itemBig){
                    return(
                        <tr>
                            {itemBig.map(function(item, index){
                                return(
                                    // <fragment>
                                        <td>{item}</td> 
                                    // </fragment> 
                                );
                            })}         
                        </tr>
                                
                    );
                    }
                })}
            </table>
        </div>
        }
{/* 
        {this.state.preview.length > 0 &&
        <div>
            <table className="preview-table">
                <tr>
                    <td>br</td>
                    <td>sifra proizvoda</td>
                    <td>sifra prodavnice</td> 
                    <td>sifra kupca</td>  
                    <td>kolicina</td> 
                    <td>net-amount</td> 
                    <td>od</td> 
                    <td>do</td> 
                    <td>SAP</td> 
                </tr>
                {this.state.preview.map(function(item, index){
                    return(
                        <tr>
                            <td>{index}</td>
                            <td>{item.productsCode}</td>
                            <td>{item.storesDoncafeCode}</td>
                            <td>{item.customerDoncafeCode}</td>  
                            <td>{item.amount}</td> 
                            <td>{item.netAmount}</td> 
                            <td>{item.dateFrom.slice(0,10)}</td> 
                            <td>{item.dateTo.slice(0,10)}</td> 
                            <td>{item.sapCode}</td>                  
                        </tr>
                                
                    );
                })}
            </table>
        </div>
        } */}
      </div>
    );
  }

}

export default ImportSellOut;