import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import {Chart} from '../components/Chart';
import Service from '../components/Service';

class Actions extends Component {

    constructor(props){
        super(props);
        this.state = {
            actions: []
        }

    }

    componentDidMount=()=>{
        Service.get('/import/getActions',{},(res)=>{
            this.setState({actions: res.data});
        });
    }

render() {
    return (
        <div className="content">
            <h1>Actions</h1>
            <h2>Marketing campagins input</h2>
            <table className="preview-table actions-table" >
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>From</th>
                    <th>To</th>
                </tr>
                <tr>
                    <td colspan="6">actions list</td>
                </tr>
                {this.state.actions.map((item, index)=>{
                    return(
                        <tr>
                            <td>{item.id}</td>
                            <td><Link to={`/action/${item.id}`}>{item.actions_name}</Link></td>
                            <td>{item.type}</td>
                            <td className="description">{item.description}</td>
                            <td>{item.date_opened}</td>
                            <td>{item.date_closed}</td>
                        </tr>
                    )
                })}
            </table>
          
        </div>
      
    )}
}

export default Actions;