import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import Service from '../components/Service';

class HomePage extends Component {

    constructor(props){
        super(props);
        this.state = {
            regions: []
        }

    }

    componentDidMount=()=>{
        // Service.get('/test/getRegions',{},(res)=>{
        //     this.setState({regions: res.data.regions});
        // });
    }

  render() {
    return (
      <div className="content">
          <h1>Doncafe</h1>
          <h2>Sales Prediction App</h2>
          {/* <h3>Ulogujte se:</h3>
          Korisnik: <input type="text" /><br/>
          Lozinka: <input type="text" /> */}
          {/* {this.state.regions.map((item, index)=>{
              return(
                  <p>id: {item.id}; name: {item.name}</p>
              )
          }, this)
          } */}
      </div>
      
    )
  }
}

export default HomePage;