import React, { Component } from 'react';
import Service from '../components/Service';

import { CSVReader } from 'react-papaparse';


// const items = {
//     sellOut: []
// };
class ImportSellOutDev extends Component {
constructor() {
    super();
    this.state = {
      sellOut: [],
      selectedStore: '',
      fileInput:'',
      page: 0,
      perPage: 20,
      auto: false,
      autoStopPage: 100
      
    };
    
    this.fileInput = React.createRef();
  }

  stores = ['Metro',   
            'Delhaize',
            'Dis',
            'Lidl',
            'Mercator',
            'Univerexport',
            'Veropulous',
            'Aman',
            'Sap']

  handleReadCSV = (data) => {
    console.log(data);
    this.setState({
            sellOut: data.data
          });
    console.log(data.data);
  }

  handleImportOffer = () => {
    this.fileInput.current.click();
    // console.log("Ime fajla:",this.fileInput.current)
  }

    sendData=()=>{

        let d = [];
        let page = parseInt(this.state.page);
        let perPage = parseInt(this.state.perPage);
        console.log((page * perPage), (page * perPage) - 1 + perPage);
        for(var i = (page * perPage); i <= (page * perPage) - 1 + perPage; i++){
            d.push(this.state.sellOut[i]);
        }
        console.log('items to send:', d);

        let p = d;//.slice(3, 1000);
        console.log(p);
      if(this.state.selectedStore || this.state.selectedStore != 0){
        console.log('/Tijana/sellOut'+this.state.selectedStore);
        console.log("Ime fajla:",this.fileInput.current.files[0].name)
            console.log(p);
            //return;
         Service.get(`/Tijana/sellOut${this.state.selectedStore}`,{sellOut: p , fileName:this.fileInput.current.files[0].name},(res)=>{
            if(res.success){
            this.setState({page: parseInt(this.state.page) + 1},()=>{
              if(this.state.page <= this.state.autoStopPage){
                  if(this.state.auto === true){
                    this.sendData();
                  }
              }
              
            }
            )
          }
         })
      }
  }

  changeStore=(event)=>{
    console.log(event.target.value);
    this.setState({selectedStore: event.target.value})
  }

  switchAuto=()=>{
      this.setState({auto: !this.state.auto});
  }

    handlePage=(event)=>{
        this.setState({page: event.target.value});
    }

    handlePerPage=(event)=>{
        this.setState({perPage: event.target.value});
    }
    handleAutoStop=(event)=>{
        this.setState({autoStopPage: event.target.value});
    }


  render() {
    return (
      <div>
          <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{display: 'none'}}
          onError={this.handleOnError}
          
        />
        <p className='hint' >Klikni dugme 'Odaberi CSV' da uneses proizvode iz CSV fajla</p>
        <button onClick={this.handleImportOffer} >Odaberi CSV</button>
        <select value={this.state.selectedStore} onChange={this.changeStore}>
          <option value='' >odaberi prodavnicu:</option>
            {this.stores.map(function(item, index){
              return(
                <option value={item} key={index}>{item}</option>
              )},this
            )};
          </select>
          <br />
          PAGE: <input type="text" value={this.state.page} onChange={this.handlePage} /><br />
        PER PAGE: <input type="text" value={this.state.perPage} onChange={this.handlePerPage} /><br />
        PAGE STOP: <input type="text" value={this.state.autoStopPage} onChange={this.handleAutoStop} /><br />
        {this.state.auto == false &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'gray'}}>AUTO</button>
        }
        {this.state.auto == true &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'red'}}>AUTO</button>
        }
        <button onClick={this.sendData} className='keyword' >Submit</button>
        {this.state.sellOut.map(function(item, index){
            return(
                      <p>{item.toString()}</p>
                        
              );
          })}
        
        
      </div>
    );
  }

}

export default ImportSellOutDev;