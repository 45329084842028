import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import Service from '../components/Service';

/*

/import/getStoresNielsen'
id
doncafe_id
nislen id
doncafe ime
nislen ime
dc adresa
ns adresa, street_number, vilage_city,  stavi tekst ispod teksta svuda
distanca

uzimamo samo povezane

--------

/import/removeStoreNielsen

saljem id

*/

class Nielsen extends Component {

    constructor(props){
        super(props);
        this.state = {
            stores: [],
            deletedStores: [],
            loading: 'Loading, please wait...'
        }

    }

    componentDidMount=()=>{
        // Service.get('/test/getRegions',{},(res)=>{
        //     this.setState({regions: res.data.regions});
        // });
        this.updateStores();
    }

    updateStores=()=>{
        Service.get('/import/getStoresNielsen',{},(res)=>{
            if(res.success){
                this.setState({stores: res.data.stores, loading: ''});
            }
        });
    }

    removeStore=(event)=>{
        let id = event.target.getAttribute('data-store-id');
        Service.get('/import/removeStoreNielsen',{id: parseInt(id)},(res)=>{
            if(res.success){
                let ds = this.state.deletedStores;
                ds.push(id);
                this.setState({deletedStores: ds});
            }
        });
    }

    checkDeleted=(id)=>{
        
        if(this.state.deletedStores.includes(id)){
            return 'gray';
        }
        return 'black';
    }

  render() {
    return (
      <div className="content">
          <h1>Nielsen Data</h1>
          <h2>Stores removing</h2>
          <p>Click the remove button to remove the doncafe store id from Nielsen data table</p>
          <p>{this.state.loading}</p>

          <table className="preview-table">
                <thead>
                    <tr className="table-head-fixed">
                        <th>id</th>
                        <th>Address</th>
                        <th>Store Name</th>
                        <th>Distance km</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {this.state.stores.map((item, index)=>{
//                     address: "29. Novembra 105/2, Aleksandrova"
// city_vllage: "Aleksandrovac (rasinski / Aleksandrovac)"
// doncafe_code: "S04163003"
// doncafe_distance: "0.01"
// id: "4"
// outlet_address: "29. NOVEMBRA"
// outlet_name: "POPOVIC 3"
// store_name: "Popovic 3 STR-Iv. Po."
// stores_id: "3313"
// street_number: "44"
                    return(
                            <tr style={{color: this.checkDeleted(item.id)}}>
                                <td style={{textAlign: 'left'}}>{item.id} / {item.stores_id}<br />{item.doncafe_code}</td>
                                <td style={{textAlign: 'left'}}>{item.address.toUpperCase()}<br />{item.outlet_address} {item.street_number} {item.city_vllage}</td>
                                <td style={{textAlign: 'left'}}>{item.store_name}<br />{item.outlet_name}</td>
                                <td>{item.doncafe_distance}</td>
                                <td><button onClick={this.removeStore} data-store-id={item.id} >Remove</button></td>
                            </tr>
                        )
                    }, this)
                    }
                </tbody>
            </table>

          {/* <h3>Ulogujte se:</h3>
          Korisnik: <input type="text" /><br/>
          Lozinka: <input type="text" /> */}
          {/* {this.state.regions.map((item, index)=>{
              return(
                  <p>id: {item.id}; name: {item.name}</p>
              )
          }, this)
          } */}
      </div>
      
    )
  }
}

export default Nielsen;