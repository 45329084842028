import React, { Component, Fragment } from 'react';
import renderHTML from 'react-render-html';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class TableSurvey extends Component {

  constructor(props) {
    super(props);
      this.state = { 
        excelData: []
      };
  }

    componentDidMount(){
        this.generateExcelData();
    }

    generateExcelData=()=>{
      let excel = [];

      this.props.data.data.map((item)=>{
        let data = Object.assign({}, item);
        excel.push(data)
      }, this);

      this.setState({excelData: excel})
    }

    parseData=(data)=>{
      if(typeof(data) === 'object'){
        return '<div style={{color: '+data.color+'}}>' +data.value+'<span>'+data.altValue+'</span></div>';
      }
    }

  render() {
    return (
      <div>
        <ExcelFile element={<button className="keyword">Export excel</button>} filename={"doncafe-sales-"+this.state.from+"/"+this.state.from}>
          <ExcelSheet data={this.state.excelData} name={"doncafe-sales-"+this.state.from+"/"+this.state.from}>
            {this.props.data.columnNames.map((item, index)=>{
                return(
                    <ExcelColumn label={item} value={index} />
                )
                }, this)
            }
          </ExcelSheet>
        </ExcelFile>

       <table className="preview-table">
        <thead>
          <tr className="table-head-fixed">
            {this.props.data.columnNames.map(function(item, index){
              return(
                  <th>{item}</th>
              )},this
            )}
          </tr>
        </thead>
        <tbody>
          {this.props.data.data.map(function(item, index){
            return(
              <tr>
                {item.map(function(item2){
                  return(
                      <td>{typeof(data) === 'object' ? renderHTML(item2) : item2}</td>
                  )},this
                )}
              </tr>
            )},this
          )}
        </tbody>
      
      </table>
      </div>
    )
  }

}

export default TableSurvey;
