import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import Service from '../components/Service';
import Search from '../components/Search';
import TreeMenu from 'react-simple-tree-menu';
import '../../node_modules/react-simple-tree-menu/dist/main.css';
import HighchartsReact from "highcharts-react-official";
import highcharts from 'highcharts';


const fields = [
    //"classification_code", 
    //"ownership_type", 
    // "chain_name", 
    //"outlet_type", 
    "selling_area", 
    //"service_type", 
    // "Information_Collected_Status", 
    // "Information_Collected_From", 
    //"region", 
    "urban_rural", 
    "store_location_A", 
    "store_location_B", 
    "store_location_C", 
    // "Product_Handling_Fresh_Food", 
    // "Product_Handling_Packed_Food", 
    // "Product_Handling_Frozen_Food", 
    // "Product_Handling_Sold_By_Weight", 
    // "Product_Handling_Alcohol_Beverages", 
    // "Product_Handling_Soft_Drinks", 
    // "Product_Handling_Juices", 
    // "Product_Handling_Water", 
    // "Product_Handling_Coffee", 
    // "Product_Handling_Tea", 
    // "Product_Handling_Energy_Drinks", 
    // "Product_Handling_Beer", 
    // "Product_Handling_Confectionery", 
    // "Product_Handling_Prepared_Meals", 
    // "Product_Handling_Personal_Care_Products", 
    // "Product_Handling_Household_Care_Products", 
    // "Product_Handling_Baby_Diapers", 
    // "Product_Handling_Laundry_Detergents", 
    // "Product_Handling_Cigarettes", 
    // "Product_Handling_IQOS", 
    // "Product_Handling_Salted_Snack", 
    // "Product_Handling_Ice_Cream", 
    // "Product_Handling_Whiskey", 
    // "Product_Handling_Other", 
    // "Product_Handling_Pet_Food", 
    // "Brand_Handling_Gilette", 
    // "Brand_Handling_Redbull", 
    // "Brand_Handling_Coke_Zero", 
    // "Brand_Handling_Pepsi_Max", 
    // "Brand_Handling_Black_Easy_Instant_Coffee", 
    // "Brand_Handling_Doncaffe_321_Sec_Instant_Coffee", 
    // "Brand_Handling_IQOS_Device", 
    // "Brand_Handling_Heets_Sticks", 
    // "Brand_Handling_Glo_Device", 
    // "Brand_Handling_Neo_Sticks", 
    // "Brand_Handling_Pringles", 
    // "Brand_Handling_Doritos", 
    // "Brand_Handling_Smoki", 
    // "Brand_Handling_Cockta", 
    // "Brand_Handling_Argeta", 
    // "Brand_Handling_Cedevita_On_The_Go", 
    // "Brand_Handling_Other", 
    // "sell2019", 
    // "sell2019_prediction", 
    "sellIn2019", 
    // "sellIn2019_prediction", 
    "nielsen_counties_id", 
    "nielsen_municipalities_id", 
    "nielsen_city_villages_id", 
    // "avg_sale", 
    "mun_avg_sale", 
    "cou_avg_sale", 
    "city_avg_sale"
]

 const magnitudes = {
    "classification_code": 1, 
    "ownership_type": 1, 
    "chain_name": 1, 
    "outlet_type": 0, 
    "selling_area": 1, 
    "service_type": 0, 
    "Information_Collected_Status": 1, 
    "Information_Collected_From": 1, 
    "region": 1, 
    "urban_rural": 0, 
    "store_location_A": 0, 
    "store_location_B": 0, 
    "store_location_C": 0, 
    "Product_Handling_Fresh_Food": 1, 
    "Product_Handling_Packed_Food": 1, 
    "Product_Handling_Frozen_Food": 1, 
    "Product_Handling_Sold_By_Weight": 1, 
    "Product_Handling_Alcohol_Beverages": 1, 
    "Product_Handling_Soft_Drinks": 1, 
    "Product_Handling_Juices": 1, 
    "Product_Handling_Water": 1, 
    "Product_Handling_Coffee": 1, 
    "Product_Handling_Tea": 1, 
    "Product_Handling_Energy_Drinks": 1, 
    "Product_Handling_Beer": 1, 
    "Product_Handling_Confectionery": 1, 
    "Product_Handling_Prepared_Meals": 1, 
    "Product_Handling_Personal_Care_Products": 1, 
    "Product_Handling_Household_Care_Products": 1, 
    "Product_Handling_Baby_Diapers": 1, 
    "Product_Handling_Laundry_Detergents": 1, 
    "Product_Handling_Cigarettes": 1, 
    "Product_Handling_IQOS": 1, 
    "Product_Handling_Salted_Snack": 1, 
    "Product_Handling_Ice_Cream": 1, 
    "Product_Handling_Whiskey": 1, 
    "Product_Handling_Other": 1, 
    "Product_Handling_Pet_Food": 1, 
    "Brand_Handling_Gilette": 1, 
    "Brand_Handling_Redbull": 1, 
    "Brand_Handling_Coke_Zero": 1, 
    "Brand_Handling_Pepsi_Max": 1, 
    "Brand_Handling_Black_Easy_Instant_Coffee": 1, 
    "Brand_Handling_Doncaffe_321_Sec_Instant_Coffee": 1, 
    "Brand_Handling_IQOS_Device": 1, 
    "Brand_Handling_Heets_Sticks": 1, 
    "Brand_Handling_Glo_Device": 1, 
    "Brand_Handling_Neo_Sticks": 1, 
    "Brand_Handling_Pringles": 1, 
    "Brand_Handling_Doritos": 1, 
    "Brand_Handling_Smoki": 1, 
    "Brand_Handling_Cockta": 1, 
    "Brand_Handling_Argeta": 1, 
    "Brand_Handling_Cedevita_On_The_Go": 1, 
    "Brand_Handling_Other": 1, 
    "sell2019": 1, 
    "sell2019_prediction": 1, 
    "sellIn2019": 0, 
    "sellIn2019_prediction": 1, 
    "nielsen_counties_id": 1, 
    "nielsen_municipalities_id": 1, 
    "nielsen_city_villages_id": 1, 
    "avg_sale": 0, 
    "mun_avg_sale": 0, 
    "cou_avg_sale": 0, 
    "city_avg_sale": 0
 }

 const results = {
     compare: [],
     count: 0
 }


    /*
    Brand_Handling-Argeta: "0",
    Brand_Handling-Black_Easy_Instant_Coffee: "0",
    Brand_Handling-Cedevita_On_The_Go: "1",
    Brand_Handling-Cockta: "1",
    Brand_Handling-Coke_Zero: "0",
    Brand_Handling-Doncaffe_321_Sec_Instant_Coffee: "1",
    Brand_Handling-Doritos: "0",
    Brand_Handling-Gilette: "1",
    Brand_Handling-Glo_Device: "0",
    Brand_Handling-Heets_Sticks: "0",
    Brand_Handling-IQOS_Device: "0",
    Brand_Handling-Neo_Sticks: "0",
    Brand_Handling-Other: "1",
    Brand_Handling-Pepsi_Max: "1",
    Brand_Handling-Pringles: "0",
    Brand_Handling-Redbull: "0",
    Brand_Handling-Smoki: "1",

    Product_Handling-Alcohol_Beverages: "1",
    Product_Handling-Baby_Diapers: "0",
    Product_Handling-Beer: "1",
    Product_Handling-Cigarettes: "1",
    Product_Handling-Coffee: "1",
    Product_Handling-Confectionery: "1",
    Product_Handling-Energy_Drinks: "1",
    Product_Handling-Fresh_Food: "1",
    Product_Handling-Frozen_Food: "1",
    Product_Handling-Household_Care_Products: "1",
    Product_Handling-IQOS: "0",
    Product_Handling-Ice_Cream: "0",
    Product_Handling-Juices: "1",
    Product_Handling-Laundry_Detergents: "1",
    Product_Handling-Other: "0",
    Product_Handling-Packed_Food: "1",
    Product_Handling-Personal_Care_Products: "1",
    Product_Handling-Pet_Food: "0",
    Product_Handling-Prepared_Meals: "0",
    Product_Handling-Salted_Snack: "1",
    Product_Handling-Soft_Drinks: "1",
    Product_Handling-Sold_By_Weight: "1",
    Product_Handling-Tea: "1",
    Product_Handling-Water: "1",
    Product_Handling-Whiskey: "1",

    city_vllage: "Aleksinac (nisavski / Aleksinac)",

    county: "Nisavski",


    doncafe_distance: "0.01",

    municipality: "Aleksinac",



    outlet_type: "3",


    selling_area_(M_): "90",
    service_type: "2",
    store_location_A: "1",
    store_location_B: "6",
    store_location_C: "9",

    urban_rural: "2",

    */


/*
    // sell2019: null
    // sell2019_prediction: "736.76"
    // sellIn2019: "35.43"
    // sellIn2019_prediction: "35.43"

  ?  Information_Collected_From: "1"
  ?  Information_Collected_Status: "4"
    ?  classification_code: "011"
  ?? deleted: "0"
?   ownership_type: "1"
?  region: "1"
?    store_response_id: "G5000172"
?    street_number: "0"
    */


/*
    algorythm

    get stores
    get test stores

    normalize values


*/

class Prediction extends Component {

    constructor(props){
        super(props);
        this.state = {
            stores: [],
            storesTest: [],
            bounds: {},
            allStores: [],
            prediction: {prediction: 'no results'},
            selectedStore: 0,
            loaded: false,
            currentStore: 0,
            predictionResult: [],
            predictionCheck: false,
            options: {
                chart: {
                    type: 'scatter',
                    zoomType: 'xy'
                },
                title: {
                    text: 'Doncafe, sales prediction by store similarity'
                },
                subtitle: {
                    text: 'Source: Nielsen data & Brainit data'
                },
                xAxis: {
                    title: {
                        enabled: true,
                        text: 'Sales (kg)'
                    },
                    startOnTick: true,
                    endOnTick: true,
                    showLastLabel: true
                },
                yAxis: {
                    title: {
                        text: 'Sales (kg)'
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    verticalAlign: 'top',
                    x: 100,
                    y: 70,
                    floating: true,
                    backgroundColor: highcharts.defaultOptions.chart.backgroundColor,
                    borderWidth: 1
                },
                plotOptions: {
                    scatter: {
                        marker: {
                            radius: 5,
                            states: {
                                hover: {
                                    enabled: true,
                                    lineColor: 'rgb(100,100,100)'
                                }
                            }
                        },
                        states: {
                            hover: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x} kg, {point.y} kg'
                        }
                    }
                },
                series: [{
                    name: 'Prediction',
                    color: 'rgba(223, 83, 83, .5)',
                    data: [[161.2, 51.6], [167.5, 59.0]]

                }]
            }
        }

    }

    updateChart = () => {
        
        let opt = this.state.options;
        console.log('opt', opt);
        //opt.series[0].data.push(this.state.predictionResults);
        opt.series[0].data = results.compare;
        // let c = []
        // console.log('pr', this.state.predictionResults)
        // this.state.predictionResults.map((item)=>{
        //     if(item){
        //         c.push(item)
        //     }
        // })

        this.setState({options: opt});
        
    }

    checkPrediction=()=>{
        
        if(this.state.predictionCheck == true){
            console.log('c p');
            if(results.count < 1000){
                results.compare.push(this.predict(results.count));
                results.count ++;
                this.checkPrediction();
            }else{
                this.updateChart();
                this.state.predictionCheck = false;
                results.count = 0;
            }
        }
        
    }

    togglePrediction=()=>{
        this.setState({predictionCheck: !this.state.predictionCheck});
    }



    componentDidMount=()=>{
        this.getNielsenTest();
    }

    normalize=(value, max, min, magnitude = 1)=>{
        return value/(max-min) * magnitude;
    }

    getBounds=()=>{
        let min = {};
        let max = {};
        //let magnitudes = '';

        // initialize fields in min and max objects
        fields.map((field)=>{
            min[field] = Infinity;
            max[field] = 0;
        })

        // let fields = [];
        // for(var p in this.state.storesTest[0]){
        //     fields.push(p);
        // }
        // console.log('fields', fields.toString());
        this.state.storesTest.map((store)=>{
            fields.map((field)=>{
                if(parseFloat(store[field]) < min[field]){
                    min[field] = parseFloat(store[field]);
                }
                if(parseFloat(store[field]) > max[field]){
                    max[field] = parseFloat(store[field]);
                }
            })
        })

        //console.log('bounds', min, max, magnitudes);
        this.setState({bounds: {min: min, max: max, magnitudes: magnitudes}, loaded: true},()=>{
            console.log('bounds',this.state.bounds);
            //this.predict();
        });
    }

    predict=(ss)=>{
        //let store = this.state.storesTest[0];
        //store.prediction = 0;

        let selectedStore = this.state.storesTest[ss];
        let lastResult = Infinity;
        let targetStores = [];
        let closestStore = {sellIn2019: 0};
        let a, b;
        this.state.storesTest.map((store, index)=>{
            if(index != ss ){ // avoid self store
                let result = 0;
                fields.map((field)=>{
                    //console.log(store[field], selectedStore[field])
                    a = parseFloat(store[field]);
                    b = parseFloat(selectedStore[field]);
                    //console.log('comp',this.normalize(a, this.state.bounds.max[field], this.state.bounds.min[field]), this.normalize(b, this.state.bounds.max[field], this.state.bounds.min[field]))
                    result += Math.pow( this.normalize(a, this.state.bounds.max[field], this.state.bounds.min[field], this.state.bounds.magnitudes[field]) - this.normalize(b, this.state.bounds.max[field], this.state.bounds.min[field], this.state.bounds.magnitudes[field]),2);
                    //result += Math.pow(parseFloat(store[field]) - parseFloat(selectedStore[field]),2);
                });
                //console.log('res', result);
                if(result < lastResult){
                    console.log('rslt', result);
                    lastResult = result;
                    targetStores.push(store);
                    closestStore = store;
                }
            }
            

        })

        let c = {}
        let pd = 0;
        let count = 0;
        fields.map((field)=>{
            let ts = '';
            
            for(var i = targetStores.length; i > targetStores.length - 10; i --){
                if(targetStores[i]){
                    if(field == 'sellIn2019'){
                        pd += parseFloat(targetStores[i][field]);
                        count ++;
                    }
                    ts += targetStores[i][field] + ' ';

                }
            }
            console.log('sss',selectedStore);

            c[field] = selectedStore[field] + ' ' + ts;
            
        });

        //c.prediction = selectedStore['sellIn2019'] + ' - ' + (pd/count);
        c.prediction = selectedStore['sellIn2019'] + ' - ' + closestStore['sellIn2019'] + ' - ' + parseInt((pd/count)) + ' - ' + parseInt(((pd/count) + parseFloat(closestStore['sellIn2019']))/2);
        

        console.log('predicted store', c);
        console.log('target store', targetStores);
        return [parseInt(selectedStore['sellIn2019']), parseInt(closestStore['sellIn2019'])];
        // this.setState({prediction: c, predictionResults: [parseInt(selectedStore['sellIn2019']), parseInt(closestStore['sellIn2019'])]},()=>{
        //     this.checkPrediction();
        // });


    }

    getNielsenTest=()=>{
        Service.get('/analytics/getAllNielsenData',{filters: {sales: 1}},(res)=>{
            console.log(res.data[0] );
            let tst = []
            let dt = []
            res.data.map((item)=>{
                if(parseFloat(item.sellIn2019) > 10){
                    tst.push(item);
                }else{
                    dt.push(item);
                }
            })
            this.setState({storesTest: tst, stores: dt},()=>{
                //getProperties(res)
                console.log('st', this.state.storesTest);
                this.getBounds();
            });
        });
    }

    getNielsenEmpty=()=>{
        Service.get('/analytics/getAllNielsenData',{filters: {sales: 0}},(res)=>{
            console.log(res.data[0]);
            this.setState({data: res.data},()=>{
                //getProperties(res)
                //this.getBounds();
            });
        });
    }

    changeStore=(event)=>{
        this.setState({selectedStore: event.target.value});
    }

    changeMagnitude=(event)=>{
        let bounds = this.state.bounds;
        console.log('magnitudes change', event.target.getAttribute('data-field'), event.target.value)
        bounds.magnitudes[event.target.getAttribute('data-field')] = event.target.value;
        this.setState({bounds: bounds});
    }
    

  render() {
    return (
      <div className="content">
            <h1>prediction</h1>
            Store Index: <input type="number" value={this.state.selectedStore} onChange={this.changeStore}/>
            <button onClick={this.checkPrediction} >Predict Store</button>
            <button onClick={this.togglePrediction} >Check {this.state.predictionCheck.toString()}</button>
            
            <div>result: {this.state.prediction.prediction}</div>
            {this.state.loaded === true &&
                fields.map((item)=>{
                    return(
                        <div>
                            <input onChange={this.changeMagnitude} data-field={item} value={this.state.bounds.magnitudes[item]}/> {item}
                        </div>
                    )
                })
            }
            <div className='chart-container-sales'>
                <HighchartsReact highcharts={highcharts} options={this.state.options} allowChartUpdate={true}
                updateArgs={[true, true, true]}/>
            </div>
      </div>
      
    )
  }
}

export default Prediction;