import React, { Component } from 'react';
import Service from '../components/Service';
import AsyncSelect from 'react-select/async';

class Sales extends Component {

    constructor(props){
        super(props);
        this.state = {
            results: [],
            inputValue: '',
            multi: true,
            autoClose: false
        }

    }

    componentDidMount(){
        if( this.props.type || this.props.type === 'single'){
            this.setState({multi: false, autoClose: true});
        }
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue;//.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    filterItems = (inputValue) => {
        return this.state.results.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };
    //'/analytics/getAllStores'
    loadOptions = (inputValue, callback) => {
        Service.get(this.props.route, {filter: this.state.inputValue}, (res)=>{
            console.log(res);
            if(res.success){
                let c = [];
                res.data.map((item)=>{
                    c.push({value: item.id, label: item.label});
                });
                this.setState({results: c},()=>{
                    
                    callback(this.filterItems(inputValue));
                });
            }
        })
    };

  render() {
    return (
      <span>
        {/* <p>{this.state.inputValue}</p> */}
            <AsyncSelect
                className='react-select-container'
                closeMenuOnSelect={this.state.autoClose}
                cacheOptions
                loadOptions={this.loadOptions}
                placeholder={this.props.placeholder}
                isClearable
                defaultOptions
                isMulti={this.state.multi}
                onInputChange={this.handleInputChange}
                onChange={this.props.onChange}
            />         
      </span>      
    )
  }
}

export default Sales;