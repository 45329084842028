import React, { Component } from 'react';
import HighchartsReact from "highcharts-react-official";
import highcharts from 'highcharts'
import { DatePicker } from '@y0c/react-datepicker';
import moment from 'moment';
import Service from '../components/Service';
import DataSet from '../components/DataSet';
import DateSelector from '../components/DateSelector';
import duix from 'duix';


import '@y0c/react-datepicker/assets/styles/calendar.scss';
//import { ItemContent } from 'semantic-ui-react';

//const Chart1 = ({ options, highcharts }) => (
class ChartSurvey extends Component {

  constructor(props) {
    super(props);
      this.state = { 
        options: {
            chart: {
                type: this.props.data.chartType,
                plotBorderWidth: 1,
                zoomType: 'xy'
            },
            xAxis: {
                categories: [1,2,3],
                title: {
                    text: 'Year'
                }
            },
            title: {
                text: ''
            },
            series: []
          }
      };
  }

    componentDidMount(){
        if(this.props.data){
            this.setChatOptions();
        }
    }

    setChatOptions=()=>{
        let o = this.state.options
        let series = [];

        this.props.data.data.map((item, index)=>{
          if(item && Array.isArray(item) === true){
            series.push({name: this.props.data.rowNames[index], data: item})
          }else if(item && Array.isArray(item) === false){
            series.push({name: this.props.data.rowNames[index], data: [item]})
          }            
        })

        o.series = series;
        this.setState({options: o},()=>{
        })
    
    }

  render() {
    return (
      <div>
        <div className="chart">
            <div className='chart-container-in-survey'>
                <HighchartsReact highcharts={highcharts} options={this.state.options} allowChartUpdate={true}
                updateArgs={[true, true, true]}/>
            </div>
        </div>
      </div>
    )
  }

}

export default ChartSurvey;
