import React, { Component } from 'react';
import renderHTML from 'react-render-html';

import HighchartsReact from "highcharts-react-official";
import highcharts from 'highcharts'
import { DatePicker } from '@y0c/react-datepicker';
import moment from 'moment';
import Service from '../components/Service';
import DataSet from '../components/DataSet';
import DateSelector from '../components/DateSelector';
import duix from 'duix';

class TextSurvey extends Component {

  constructor(props) {
    super(props);
      this.state = { 
        text: ''
      };
  }

    componentDidMount(){
        if(this.props.data){
            this.setText();
        }
    }

    setText=()=>{
        this.setState({text: this.props.data.data})
    }

  render() {
    return (
      <div>
          <p>{renderHTML(this.state.text)}</p>
      </div>
    )
  }

}

export default TextSurvey;
