import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import {Chart} from '../components/Chart';
import Service from '../components/Service';

class Actions extends Component {

    constructor(props){
        super(props);
        this.state = {
            action: {}
        }

    }

    componentDidMount=()=>{
        Service.get('/import/getAction',{actionsId: this.props.match.params.id},(res)=>{
            this.setState({action: res.data[0]});
        });
    }

render() {
    return (
        <div className="content">
            <h1>{this.state.action.actions_name}</h1>
            <p>{this.state.action.date_opened} - {this.state.action.date_closed}</p>
            <p>Type: {this.state.action.type}</p>
            <p>Magnitude: {this.state.action.magnitude}</p>
            <p>{this.state.action.description}</p>
            
                        {/* <tr>
                            <td>{item.id}</td>
                            <td><Link to={`/action/${item.id}`}>{item.actions_name}</Link></td>
                            <td>{item.type}</td>
                            <td className="description">{item.description}</td>
                            <td>{item.date_opened}</td>
                            <td>{item.date_closed}</td>
                        </tr> */}

          
        </div>
      
    )}
}

export default Actions;