import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import Service from '../components/Service';
import Search from '../components/Search';
import JSONPretty from 'react-json-pretty';

function allnumeric(value){
    return /^\d+$/.test(value);
} 


class Logs extends Component {

    constructor(props){
        super(props);
        this.state = {
            logs: [],
            currentImport: '',
            page: 1,
            info: [],
            rows: [],
            status: [],
            loading: 0,
            issues: []

        }

    }

    componentDidMount=()=>{
        //this.getLogs();
        this.getStatus();
    }


    getLogs=()=>{
        this.setState({loading: 1});
        console.log('filter', this.state.currentImport);
        // Service.get('/log/getPending',{logType: 'sell_out', page: this.state.page - 1, perPage: 1000, filter: this.state.currentImport},(res)=>{
        //     this.setState({logs: res.data.logs});
        // });
        if(this.state.currentImport){
            Service.get('/log/getMissingFields',{filter: this.state.currentImport},(res)=>{
                //this.setState({info: res.data});
                
                this.setState({rows: res.data.rows},()=>{
                    this.renderIssues();
                });
                
                console.log(res);
            });
        }

        
    }

    renderIssues=()=>{
        console.log('rows', this.state.rows)
        let r = this.state.rows;
        let is = {products: [], products_codes: [], stores: [], stores_doncafe_code: []};

        let products = [];
        let products_codes = [];
        console.log('items issues', r);
        r.map((item,index)=>{
            if(parseFloat(item.amount) > 0 || parseFloat(item.net_amount) > 0){
                if(!item.products_id){
                    if(!is.products.includes(item.products_code)){
                        //products.push(item.products_code);
                        is.products.push(item.products_code);
                    }
                }

                if(!item.products_code){
                    if(!is.products_codes.includes(item.row_number)){
                        //products.push(item.products_code);
                        is.products_codes.push(item.row_number);
                    }
                }
                
                if(!item.stores_id){
                    
                    if(!is.stores.includes(item.stores_id)){
                        //products.push(item.products_code);
                        if(!is.stores.includes(item.file_sap_code)){
                            is.stores.push(item.file_sap_code);
                            console.log('item no store: ', item)
                        }
                    }
                }
                if(!item.stores_doncafe_code){
                    if(!is.stores_doncafe_code.includes(item.row)){
                        //products.push(item.products_code);
                        is.stores_doncafe_code.push(item.row);
                    }
                }
            }
            
        })

        console.log('is', is);
        this.setState({issues: is},()=>{
            this.setState({loading: 2});
        });

    }

    getCurrentImports=(res)=>{
        if(res){
            this.setState({currentImport: res.value},()=>{
                this.getLogs();
            });
        }else{
            this.setState({currentImport: ''},()=>{
                this.getLogs();
            });
        }
    }

    changeImport = (event)=>{
        let ci = event.target.getAttribute("data-id");
        this.setState({currentImport: ci},()=>{
            this.getLogs();
        }); 
    }

    deleteCurrentImport=(confirm)=>{
        if(this.state.currentImport == ''){
            alert('Import session not selected!');
            console.log(this.state.currentImport);
            return;
        }

        if (window.confirm("DELETE selected import!")) {
            Service.get('/log/deleteCurrentImport',{currentImport: this.state.currentImport},(res)=>{
                //this.getLogs();
                //this.getCurrentImports();
                this.setState({currentImport: null});
                alert('unos je obrisan.');
            });
            //alert('yes');
        } else {
            //txt = "You pressed Cancel!";
        }
        
    }

    changePage=(x)=>{
        let page = this.state.page;
        let p = page + x;
        if(p < 1){p = 1}
        this.setState({page: p},()=>{
            this.getLogs();
        });
    }

    parseRowNumbers(rn){

        // return value if it is just number
        if(allnumeric(rn)){
            return rn;
        }

        // find number in  string
        let a = rn.split(',');
        let r;
        for(var i in a){
            if(a[i].includes('rb:')){
              return r = a[i].replace(/\D+/g, '');
            }
        }

        // no value, return not-available
        return 'n/a';
    }

    getStatus(){
        Service.get('/log/status',{},(res)=>{
            this.setState({status: res.data.status});
        }); 
    }

    // getCurrentImports=()=>{
    //     Service.get('/log/getCurrentImports',{logType: 'sell_out', page: 0, perPage: 1000},(res)=>{
    //         this.setState({logs: res.data.logs});
    //     });
    // }

  render() {
    return (
      <div className="content">
          <h1>LOGS</h1>
          <Search onChange={this.getCurrentImports} route='/log/getAllCurrentImports' type='single' placeholder="Get Import Sessions..."/>
            {/* <button className="keyword" onClick={()=>{this.changePage(-1)}}>-</button> PAGE: {this.state.page} <button className="keyword" onClick={()=>{this.changePage(1)}}>-</button>  */}
            <button className="keyword logs-delete-button" onClick={this.deleteCurrentImport}>Delete Selected Import</button>
            <hr />
            {/* <table className="preview-table">
                <tr>
                    <td>Id</td>
                    <td>File Name</td>
                    <td>File Row</td>
                    <td>Greske</td>
                    <td>Logovi</td>
                    <td>File Session</td>
                </tr>
                {this.state.logs.map((item, index)=>{
                    return(
                            <tr>
                                <td>{item.id}</td>
                                <td>{item.file_name}</td>
                                <td>{item.row_number}</td>
                                <td>{JSON.stringify(item.errors)}</td>
                                <td>{JSON.stringify(item.errors_info)}</td>
                                <td>{item.current_import}</td>
                            </tr>
                    )
                }, this)
                }
            </table> */}
            <div className="logs-left">
                <h3>Sell Out data with issues</h3>
                <p className="import-info">*sets with just no-amount and no-net_amount data rows will not be displayed.</p>
                <ul>
                {this.state.status.map((item, index)=>{
                    return (
                        <li onClick={this.changeImport} data-id={item}>{item}</li>
                    )
                })}
                </ul>
            </div>
            <div className="logs-right">
                {/* <p><pre>{JSON.stringify(this.state.info)}</pre></p> */}
                <h3>{this.state.currentImport}</h3>
                {this.state.loading === 0 &&
                    <p>Please select Sell Out data</p>
                }

                {this.state.loading === 1 &&
                    <p>Loading, please wait...</p>
                }

                {this.state.loading === 2 &&
                <fragment>

                <div style={{textAlign: 'left'}}>
                    <p>Missing products in DB: {this.state.issues.products.length} - product codes: [{this.state.issues.products.join(', ')}]</p>
                    <p>Missing products in File: {this.state.issues.products_codes.length} - rows: [{this.state.issues.products_codes.join(', ')}]</p>
                    <p>Missing stores in DB: {this.state.issues.stores.length} - store codes: [{this.state.issues.stores.join(', ')}]</p>
                    {/* <p>Missing products in File: {this.state.issues.stores_doncafe_code.length} - rows: [{this.state.issues.stores_doncafe_code.join(', ')}]</p> */}
                </div>
                <table className="preview-table">
                <thead>
                <tr className="table-head-fixed">
                    <th>id</th>
                    <th>products_id</th>
                    <th>products_code</th>
                    <th>stores_id</th>
                    <th>stores_doncafe_code</th>
                    <th>amount</th>
                    <th>net_amount</th>
                    <th>unit</th>
                    <th>price</th>
                    <th>date_from</th>
                    <th>date_to</th>
                    <th>sales_type</th>
                    {/* <th>actions_id</th> */}
                    {/* <th>barcode</th> */}
                    <th>strauss_codes_id</th>
                    <th>producers_id</th>
                    {/* <th>brands_id</th> */}
                    {/* <th>sub_brands_id</th> */}
                    <th>doncafe_code</th>
                    {/* <th>territories_id</th> */}
                    {/* <th>territory_types_id</th> */}
                    {/* <th>regions_id</th> */}
                    {/* <th>attractiveness_id</th> */}
                    {/* <th>store_types_id</th> */}
                    {/* <th>current_import</th> */}
                    <th>customers_doncafe_code</th>
                    <th>customers_id</th>
                    <th>sales_representatives_id</th>
                    <th>current_sales_representatives_id</th>
                    <th>created_at</th>
                    <th>updated_at</th>
                    <th>deleted</th>
                    <th>file_amount</th>
                    <th>file_net_amount</th>
                    <th>file_date_from</th>
                    <th>file_sap_code</th>
                    <th>file_customers_name</th>
                    <th>file_product_code</th>
                    <th>file_name</th>
                    <th>row_number</th>
                    <th>update_count</th>
                    
                    {/* <td>Greske</td>
                    <td>Logovi</td>
                    <td>File Session</td> */}
                </tr>
                </thead>
                <tbody>
                {this.state.rows.map((item, index)=>{
                    if(parseFloat(item.amount) > 0 || parseFloat(item.net_amount) > 0){
                    return(
                            <tr>
                                <td>{item.id}</td>
                                <td>{item.products_id}</td>
                                <td>{item.products_code}</td>
                                <td>{item.stores_id}</td>
                                <td>{item.stores_doncafe_code}</td>
                                <td>{item.amount}</td>
                                <td>{item.net_amount}</td>
                                <td>{item.unit}</td>
                                <td>{item.price}</td>
                                <td>{item.date_from}</td>
                                <td>{item.date_to}</td>
                                <td>{item.sales_type}</td>
                                {/* <td>{item.actions_id}</td> */}
                                {/* <td>{item.barcode}</td> */}
                                <td>{item.strauss_codes_id}</td>
                                <td>{item.producers_id}</td>
                                {/* <td>{item.brands_id}</td> */}
                                {/* <td>{item.sub_brands_id}</td> */}
                                <td>{item.doncafe_code}</td>
                                {/* <td>{item.territories_id}</td> */}
                                {/* <td>{item.territory_types_id}</td> */}
                                {/* <td>{item.regions_id}</td> */}
                                {/* <td>{item.attractiveness_id}</td> */}
                                {/* <td>{item.store_types_id}</td> */}
                                {/* <td>{item.current_import}</td> */}
                                <td>{item.customers_doncafe_code}</td>
                                <td>{item.customers_id}</td>
                                <td>{item.sales_representatives_id}</td>
                                <td>{item.current_sales_representatives_id}</td>
                                <td>{item.created_at}</td>
                                <td>{item.updated_at}</td>
                                <td>{item.deleted}</td>
                                <td>{item.file_amount}</td>
                                <td>{item.file_net_amount}</td>
                                <td>{item.file_date_from}</td>
                                <td>{item.file_sap_code}</td>
                                <td>{item.file_customers_name}</td>
                                <td>{item.file_product_code}</td>
                                <td>{item.file_name}</td>
                                <td>{this.parseRowNumbers(item.row_number)}</td>
                                <td>{item.update_count}</td>
                                
                            </tr>
                        )
                        }
                    }, this)
                    }
                    </tbody>
                </table>
                </fragment>
                }

                {Object.keys(this.state.info).map((keyName, i) => (
                        <div className="info-section">
                            {/* key: {i} Name: {keyName} */}
                            {/* {this.state.info[keyName].title} */}
                            <h2>{keyName}</h2>
                            {this.state.info[keyName].map((item, index)=>{
                                if(item.values.length > 0){
                                    let content = '';
                                return(
                                    <span className="info-section-row">
                                    
                                    <div className="info-section-data">
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                        <span className="info-section-values">
                                        {item.values.map((value, idx)=>{
                                            if(content){
                                                content += ', '+value;
                                            }else{
                                               content += value; 
                                            }
                                           return(
                                                <div className="info-section-value" >{value}</div>
                                            )
                                        }, this)
                                        }
                                        {/* {content} */}
                                        </span>
                                    </div>
                                    </span>
                                )
                                }
                            }, this)
                            }
                        </div>
                ))}
                
                {/* {this.state.info.map((item, index)=>{
                    return(
                            <tr>
                                <td>{item}</td>
                            </tr>
                    )
                }, this)
                } */}
                {/* <JSONPretty id="json-pretty" data={this.state.info}></JSONPretty> */}
            </div>
      </div>
      
    )
  }
}

export default Logs;