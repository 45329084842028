import React, { Component } from 'react';
import Service from '../components/Service';
import { CSVReader } from 'react-papaparse';

const items = {
    data: []
};
class ImportSurvey extends Component {
constructor() {
    super();
    this.state = {
      name: 'React',
      partners: [],
      page: 0,
      perPage: 1000,
      route: 'insertStoreSurvey',
      auto: false,
      autoStopPage: 18,
      preview: [],
      from: '',
      to: ''
    };
    this.fileInput = React.createRef();
  }

    sendData=()=>{
        let d = [];
        let page = parseInt(this.state.page);
        let perPage = parseInt(this.state.perPage);
        console.log((page * perPage), (page * perPage) - 1 + perPage);
        for(var i = (page * perPage); i <= (page * perPage) - 1 + perPage; i++){
            d.push(items.data[i]);
        }
        console.log('items to send:', d);
        Service.get('/import/'+this.state.route,{import: d, sellOut: d, page: this.state.page},(res)=>{
          if(res.success){
            this.setState({page: parseInt(this.state.page) + 1},()=>{
              if(this.state.page <= this.state.autoStopPage){
                  if(this.state.auto === true){
                    this.sendData();
                  }
              }
              
            }
            )
          }
        });
    }

  handlePage=(event)=>{
      this.setState({page: event.target.value});
  }
  handleRoute=(event)=>{
      this.setState({route: event.target.value});
  }

  handlePerPage=(event)=>{
      this.setState({perPage: event.target.value});
  }

  showFile = () => {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
         var preview = document.getElementById('show-text');
         var file = document.querySelector('input[type=file]').files[0];
         var reader = new FileReader()

         var textFile = /text.*/;

        //  var state = this.state;

         if (file.type.match(textFile)) {
             let data = [];
            reader.onload = function (event) {

                items.data = event.target.result.split(/\r?\n/);
                console.log(items.data);
               //preview.innerHTML = items.data.slice(1,10);//event.target.result.slice(0,50000);
               //data = partners;
            //    state.setState({partners: items},()=>{
            //        console.log(state.partners);
            //    });
               
               //console.log(event.target.result.slice(0,500000).split(/\r?\n/));
               //Service.get('/test/parseSellin',{sellin: event.target.result.slice(0,50000)});
            }
         } else {
            preview.innerHTML = "<span class='error'>It doesn't seem to be a text file!</span>";
         }
         reader.readAsText(file);

   } else {
      alert("Your browser is too old to support HTML5 File API");
   }
  }

    switchAuto=()=>{
        this.setState({auto: !this.state.auto});
    }
    
    handleAutoStop=(event)=>{
        this.setState({autoStopPage: event.target.value});
    }

    handleFrom=(event)=>{
        this.setState({from: event.target.value});
    }
    handleTo=(event)=>{
        this.setState({to: event.target.value});
    }


    handleReadCSV = (data) => {
        console.log(data);
        // this.setState({
        //         sellOut: data.data
        //     });
        items.data = data.data;
        this.showPreview(items.data);
        console.log(data.data);
    }

    handleImportOffer = () => {
        this.fileInput.current.click();
    }

    // sendData=()=>{

    //     let p = this.state.sellOut.slice(3, 1000);
    //     Service.get('/import/sellOut',{sellOut: p},(res)=>{
    //         console.log(res)
    //     })
    // }

    showPreview=(data)=>{
        this.setState({preview: data.slice(0, 5)});
    }

  render() {
    return (
      <div>
        <h2>IMPORT SURVEY</h2>
        {/* <input type="file" onChange={this.showFile} /> */}
        <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{display: 'none'}}
          onError={this.handleOnError}
        />
        {/* <p className='hint' >Klikni dugme 'Odaberi CSV' da uneses proizvode iz CSV fajla</p> */}
        <div className='instructions'>
          <p>Instructions:</p>
          <ol>
            <li>Choose CSV file</li>
            <li>Set: Page = 0</li>
            <li>Set: Per Page = 1000</li>
            <li>Set: Page Stop = Number of CSV file rows / Per Page. If file has 17340 rows (stores), then 17340/1000 = 18</li>
            <li>Check:  AUTO to checked</li>
            <li>Click SEND</li>
          </ol> 

          <p>CSV example:</p>
          <table className="import-preview-table">
            <thead>
              <tr className="table-head-fixed">
                <th>br</th>
                <th>Шифра купца</th>
                <th>Шифра објекта</th>
                <th>Објекат</th>
                <th>Адреса</th>
                <th>Град</th>
                <th>Шифра комерцијалисте</th>
                <th>Комерцијалиста</th>
                <th>Датум</th>
                <th>Време</th>
                <th>Фотографије</th>
                <th>Zatvorena radnja</th>
                <th>Provera imena</th>
                <th>Provera adrese</th>
                <th>Provera vlasnika</th>
                <th>Kontakt telefon</th>
                <th>Da li ima kafu?</th>
                <th>Da li želi saradnju?</th>
                <th>Ukoliko ne želi saradnju obeležite razlog:</th>
                <th>Ima od Strauss proizvoda:</th>
                <th>Ima od konkurentskih proizvoda:</th>
                <th>Izlaznost kafe u kg mesečno</th>
                <th>Dodatni komentar</th>
                <th>Tip objekta</th>
                <th>Vrsta okruženja</th>
                <th>Lokacija radnje 1</th>
                <th>Lokacija radnje 2</th>
                <th>Lokacija radnje 3</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>S00001</td>
                <td>S00001001</td>
                <td>Zenit T.R</td>
                <td>“ul.Tihomira Đordevica br.10, Aleksinac”</td>
                <td>ALEKSINAC</td>
                <td>S5A2</td>
                <td>Лидија Стојановић</td>
                <td>07.12.2020</td>
                <td>13:12</td>
                <td>Кликни</td>
                <td>Ne</td>
                <td>Zenit</td>
                <td>Tihomira Djordjevica 10</td>
                <td>Vesna Dokic</td>
                <td>069772612</td>
                <td>Da</td>
                <td>Da</td>
                <td></td>
                <td>“Moment,Minas,Strong,C kafa,Zlatna C kafa”</td>
                <td>“Grand Gold,Grand Aroma,Bonito”</td>
                <td>24</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          
          </table>
        </div>
        <hr></hr>
        <button onClick={this.handleImportOffer}>Odaberi CSV</button>
        
        <br />
        PAGE: <input type="text" value={this.state.page} onChange={this.handlePage} />
        PER PAGE: <input type="text" value={this.state.perPage} onChange={this.handlePerPage} />
        <br />
        {/* DATE FROM: <input type="text" value={this.state.from} onChange={this.handleFrom} />
        <br />
        DATE TO: <input type="text" value={this.state.to} onChange={this.handleTo} />
        <br /> */}
        {/* ROUTE: <input type="text" value={this.state.route} onChange={this.handleRoute} />
        <br /> */}
        PAGE STOP: <input type="text" value={this.state.autoStopPage} onChange={this.handleAutoStop} /><br />
        {this.state.auto == false &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'gray'}}>AUTO</button>
        }
        {this.state.auto == true &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'red'}}>AUTO</button>
        }
        <button onClick={this.sendData}>Posalji</button>
        <div id="show-text">Choose text File</div>
        <div>
            {this.state.preview.map(function(item, index){
                return(
                    <p>{item}</p>
                )
                })
            }
        </div>
        
        {/* <button onClick={this.state.showFile}>Pregledaj</button> */}
        
        
        
      </div>
    );
  }

}

export default ImportSurvey;