import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import Service from '../components/Service';

class Brands extends Component {

    constructor(props){
        super(props);
        this.state = {
            brands: [],
            producers: [],
            subBrands: [],
            selectedBrand: '',
            selectedProducer: '',
            newProducer: '',
            newBrand: '',
            newSubBrand: '',
            newSubBrandCode: ''
        }

    }

    componentDidMount=()=>{
        this.getBrands();
        this.getProducers();
        this.getSubBrands();
    }

    getBrands=()=>{
        Service.get('/analytics/getAllBrands',{},(res)=>{
            this.setState({brands: res.data});
        });
    }

    getSubBrands=()=>{
        Service.get('/analytics/getAllSubBrands',{},(res)=>{
            this.setState({subBrands: res.data});
        });
    }

    getProducers=()=>{
        Service.get('/analytics/getAllProducers',{},(res)=>{
            this.setState({producers: res.data});
        });
    }

    selectBrand=(event)=>{
        if(event.target.value){
            this.setState({selectedBrand: event.target.value},()=>{
                console.log('test', this.state.selectedBrand)
            });
        }
    }

    selectProducer=(event)=>{
        if(event.target.value){
            this.setState({selectedProducer: event.target.value});
        }
    }

    handleInput=(event)=>{
    this.setState({[event.target.id]: event.target.value},
            ()=>{}
        );   
    }

    createProducer=()=>{
        if(!this.state.newProducer){
            alert('Please enter the producer name');
            return;
        }
        Service.get('/import/createProducer',{name: this.state.newProducer},(res)=>{
            if(res.success){
                alert('Producer "' + this.state.newProducer + '" created successfuly');
            }else{
                alert('Error!');
            }
            this.updateData();
        });
    }

    createBrand=()=>{
        if(!this.state.newBrand){
            alert('Please enter the brand name');
            return;
        }
        if(!this.state.selectedProducer){
            alert('Please select producer');
            return;
        }
        Service.get('/import/createBrand',{name: this.state.newBrand, producerId: this.state.selectedProducer},(res)=>{
            if(res.success){
                alert('Brand "' + this.state.newBrand + '" created successfuly');
            }else{
                alert('Error!');
            }
            this.updateData();
        });
    }

    createSubBrand=()=>{
        if(!this.state.newSubBrand){
            alert('Please enter the sub-brand name');
            return;
        }
        if(!this.state.selectedBrand){
            alert('Please select brand');
            return;
        }
        Service.get('/import/createSubBrand',{name: this.state.newSubBrand, subBrandCode: this.state.newSubBrandCode,  brandId: this.state.selectedBrand},(res)=>{
            if(res.success){
                alert('Sub-brand "' + this.state.newSubBrand + '" created successfuly');
            }else{
                alert('Error!');
            }
            this.updateData();
        });
    }

    updateData=()=>{
        this.getBrands();
        this.getProducers();
        this.setState({
            brands: [],
            producers: [],
            selectedBrand: '',
            selectedProducer: '',
            newProducer: '',
            newBrand: '',
            newSubBrand: '',
            newSubBrandCode: ''
        });
    }

  render() {
    return (
        <div className="content brands">
            <h1>Brands</h1>
            <h2>Brands, sub-brands and producers</h2>
            <div className="inputs">
                <h3>Create</h3>
                <div className="input">
                    Create new producer:<br />
                    <label>name:</label><input type="text" id="newProducer" value={this.state.newProducer} onChange={this.handleInput} />
                    <button class="keyword" onClick={this.createProducer}>Create</button>
                </div>
                <div className="input">
                    Create new brand:<br />
                    <select onChange={this.selectProducer} value={this.state.selectedProducer}>
                        <option selected value=''>
                                Select Producer
                            </option>
                        {this.state.producers.map((item, index) => (
                            <option key={'producer-'+index} value={item.id}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                    <label>name:</label><input type="text" id="newBrand" value={this.state.newBrand} onChange={this.handleInput} />
                    <button class="keyword" onClick={this.createBrand}>Create</button>
                </div>
                <div className="input">
                    Create new sub-brand:<br />
                    <select onChange={this.selectBrand} value={this.state.selectedBrand}>
                        <option selected value=''>
                                Select Brand
                            </option>
                        {this.state.brands.map((item, index) => (
                            <option key={'brand-'+index} value={item.id}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                
                    
                    <label>name:</label><input type="text" id="newSubBrand" value={this.state.newSubBrand} onChange={this.handleInput} /><br />
                    <label>code:</label><input type="text" id="newSubBrandCode" value={this.state.newSubBrandCode} onChange={this.handleInput} />
                    <button class="keyword" onClick={this.createSubBrand}>Create</button>
                </div>
            </div>
            <div className="list">
                <h3>Producers</h3>
                <ul>
                {this.state.producers.map((item, index) => (
                        <li key={'producers-'+index}>
                            {item.label}<sup>{item.id}</sup>
                            <button className="keyword" data-producer-id={item.id}>x</button>
                            <button className="keyword brands-btn-edit" data-producer-id={item.id}>Edit</button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="list">
                <h3>Brands</h3>
                <ul>
                {this.state.brands.map((item, index) => (
                        <li key={'producers-'+index}>
                            {item.label}<sup className="brands-list">{item.producer}</sup>
                            <button className="keyword" data-brand-id={item.id}>x</button>
                            <button className="keyword brands-btn-edit" data-brand-id={item.id}>Edit</button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="list">
                <h3>Sub-Brands</h3>
                <ul>
                {this.state.subBrands.map((item, index) => (
                        <li key={'producers-'+index}>
                            {item.label}<sup className="sub-brands-list">{item.brand}</sup>
                            <button className="keyword" data-sub-brand-id={item.id}>x</button>
                            <button className="keyword brands-btn-edit" data-sub-brand-id={item.id}>Edit</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
      
    )
  }
}

export default Brands;