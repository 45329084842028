import React, { Component } from 'react';
import Service from '../components/Service';

const items = {
    data: []
};
class Import extends Component {
constructor() {
    super();
    this.state = {
      name: 'React',
      partners: [],
      page: 0,
      perPage: 20,
      message: 'start'
    };
  }
    sendData=()=>{ 
        let d = [];
        let page = parseInt(this.state.page);
        let perPage = parseInt(this.state.perPage);
        console.log((page * perPage) + 1, (page * perPage) + perPage);
        for(var i = (page * perPage) + 1; i <= (page * perPage) + perPage; i++){
            if(items.data[i]){
                d.push(items.data[i].split('|'));
            }else{
                break;
            }
        }
        console.log('items to send:', d);
        this.setState({message: 'sending...'});
        Service.get('/import/partners',{partners: d},(res)=>{
            if(res.success){
                this.addPage();
                this.setState({message: 'done!'});
            }
        });
    }

    addPage(){
        let p = this.state.page;
        this.setState({page: p+1});
    }

  handlePage=(event)=>{
      this.setState({page: event.target.value});
  }

  handlePerPage=(event)=>{
      this.setState({perPage: event.target.value});
  }

  showFile = () => {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
         var preview = document.getElementById('show-text');
         var file = document.querySelector('input[type=file]').files[0];
         var reader = new FileReader()

         var textFile = /text.*/;

        //  var state = this.state;

         if (file.type.match(textFile)) {
             let data = [];
            reader.onload = function (event) {

                items.data = event.target.result.split(/\r?\n/);
                console.log(items.data);
               //preview.innerHTML = items.data.slice(1,10);//event.target.result.slice(0,50000);
               //data = partners;
            //    state.setState({partners: items},()=>{
            //        console.log(state.partners);
            //    });
               
               //console.log(event.target.result.slice(0,500000).split(/\r?\n/));
               //Service.get('/test/parseSellin',{sellin: event.target.result.slice(0,50000)});
            }
         } else {
            preview.innerHTML = "<span class='error'>It doesn't seem to be a text file!</span>";
         }
         reader.readAsText(file);

   } else {
      alert("Your browser is too old to support HTML5 File API");
   }
  }

  render() {
    return (
      <div>
        <h2>IMPORT PARTNERS (partneri)</h2>
        <input type="file" onChange={this.showFile} />
        
        <br />
        PAGE: <input type="text" value={this.state.page} onChange={this.handlePage} />
        PER PAGE: <input type="text" value={this.state.perPage} onChange={this.handlePerPage} />
        <button onClick={this.sendData}>Posalji</button><span> {this.state.message}</span>
        <div id="show-text">Choose text File</div>
        {/* <button onClick={this.state.showFile}>Pregledaj</button> */}
        
        
        
      </div>
    );
  }

}

export default Import;