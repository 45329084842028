import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import Service from '../components/Service';
import OpenMap from '../components/OpenMap';
import Territories from '../components/Territories';
import TerritoriesClass from '../components/TerritoriesClass';
import { CSVLink, CSVDownload } from "react-csv";
import ReactExport from "react-export-excel";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import mapDataIE from "@highcharts/map-collection/countries/rs/rs-all.geo.json";

const test = [
  {
      name: "Johson",
      amount: 30000,
      sex: 'M',
      is_married: true
  },
  {
      name: "Monika",
      amount: 355000,
      sex: 'F',
      is_married: false
  },
  {
      name: "John",
      amount: 250000,
      sex: 'M',
      is_married: false
  },
  {
      name: "Josef",
      amount: 450500,
      sex: 'M',
      is_married: true
  }
];

var teams = [];
for(var i = 1; i <= 16; i++){
  teams.push(i);
}

highchartsMap(Highcharts);

if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const days = [
    {name: 'week', value: 'all'},
    {name: 'monday', value: 0},
    {name: 'tuesday', value: 1},
    {name: 'wednesday', value: 2},
    {name: 'thursday', value: 3},
    {name: 'friday', value: 4}
]

const weeks = ['all',1,2,3,4,5,6,7,8];
const displayGroups = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
const groupCount = 520;

const mapOptions = {
    chart: {
      map: 'countries/ie/ie-all',
      height: '800px'
    },
    xAxis: {
            minRange: 1
    },
    yAxis: {
        minRange: 1
    },
    title: {
      text: 'Doncafe Stores'
    },
    credits: {
        enabled: false
    },
    mapNavigation: {
      enabled: true
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '<b>{point.freq}</b><br><b>{point.keyword}</b>                      <br>lat: {point.lat}, lon: {point.lon}'
    },
    series: [{
      // Use the gb-all map with no data as a basemap
      name: 'DoncafeMap',
      mapData: mapDataIE,
      borderColor: '#A0A0A0',
      nullColor: 'rgba(200, 200, 200, 0.3)',
      showInLegend: false
    }, {
      // Specify points using lat/lon
      type: 'mapbubble',
      name: 'Stores',
      color: '#b90606',
      minSize: '8px',
    maxSize: '10px',
      data: [{ z: 10, keyword: "Beograd", lat: 44.7866, lon: 20.4489 }, 
             { z: 4, keyword: "Niš", lat: 43.3209, lon: 21.8958 }],
      cursor: 'pointer',
      point: {
        events: {
          click: function() {
            ////console.log(this.keyword);
          }
        }
      }
    }]
  }

  class Ex extends Component {
    constructor(props){
      super(props);
    }

    componentDidMount(){
      console.log('so', this.props.storesOutside);
    }
    render() {
      return (
        <div className="ex">

            <ExcelFile element={<button className="keyword">Export excel a</button>} filename={"doncafe-sales-routes"}>
              <ExcelSheet data={this.props.storesOutside} name={"doncafe-sales-routes"}>
                <ExcelColumn label="Tim" value="tim" />
                <ExcelColumn label="Grupa" value="grupa" />
              </ExcelSheet>
            </ExcelFile>

            
        </div>
        
      )
    }
  }

class Routes extends Component {

    constructor(props){
        super(props);
        this.state = {
            stores: [],
            mapOptions: null,
            selectedTerritory: 'all',
            territories: [],
            coordinates: [],
            storesOutside: '',
            routes: [],
            defMap: false,
            selectedDay: 'all',
            selectedWeek: 1,
            csvData: [],
            loadingCsv: false,
            excelData:[],
            excelRegionData:[],
            selectedStore: null,
            selectedRegion:'',
            regions:[],
            regionStores: [],
            selectedVersion: 3,
            lastFunction: null,
            polygon: null,
            section:null,
            territoryStoreCount: [0,0,0,0,0],
            selectedGroup: 1,
            selectedTeam: null,
            excelData: [],
            allStores: []
        }

    }

    componentDidMount=()=>{
        this.getStoresTerritoryOutside();

    }

    getStoresTerritoryOutside=()=>{
      Service.get('/rearrangeTerritories/getAllStoresTest',{},(res)=>{

        let storeCenters = [];
        let usedStores = [];
        res.data.stores.map(item => {
          if(!usedStores.includes(item.grupa)){
            storeCenters.push(item);
            usedStores.push(item.grupa);
          }
        });
        this.setState({storesOutside: storeCenters, allStores: res.data.stores},()=>{
          ////console.log("so", this.state.storesOutside)
          
          this.updateMapOutside();
        });
      });
    }

    generateExcelData=()=>{
      // let dt = [];
      // //this.state.storesOutside.map(item =>{
      // this.state.allStores.map(item =>{
      //   if(item.tim == this.state.selectedTeam){
      //     dt.push(item);
      //   }
      // });
      // console.log('dt',this.state.selectedTeam, dt);
      // this.setState({excelData: dt});

      Service.get('/rearrangeTerritories/getAllStoresTest',{},(res)=>{

        let dt = [];
        //this.state.storesOutside.map(item =>{
        res.data.stores.map(item =>{
          if(item.tim == this.state.selectedTeam){
            dt.push(item);
          }
        });
        console.log('dt',this.state.selectedTeam, dt);
        this.setState({excelData: dt});
      });
      
    }

    saveTeamGroup=(group, team)=>{
      Service.get('/rearrangeTerritories/updateStoresGrupaTim', {grupa: group, tim: team}, (res)=>{
        if(res.success){
          console.log('group team saved');
          this.generateExcelData();
          //this.getStoresTerritoryOutside();
        }
      })
    }

    getMarkerData=(id)=>{
      console.log("get marker data", id)
      let so = this.state.storesOutside;
      so.map(item => {
        if(item.grupa == id){
          item.tim = this.state.selectedTeam
        }
      })
      this.setState({storesOutside: so}, ()=>{
        this.saveTeamGroup(id, this.state.selectedTeam);
        this.generateExcelData();
        this.updateMapOutside();
      })
    }

    updateMapOutside=()=>{
      let data = [];
      //Object.entries(this.state.storesOutside).map(([key, value]) => {
        this.state.storesOutside.map(function(item, index){
          let cls = 'lmr-default';
          if(item.tim){
            cls = 'lmr-'+item.tim;
          }
            if(index < (this.state.selectedGroup * groupCount) && index >= (this.state.selectedGroup * groupCount) - groupCount){
                data.push({id: item.grupa, lat: item.latitude, lng: item.longitude, territory: item.grupa, class: cls, label: item.grupa})
            }
        }, this)
      //})
    this.setState({routes: data})
      this.setState({coordinates: data},()=>{
            ////console.log('coord', this.state.coordinates)
        })
    }

    updateMap=()=>{
        let mo = mapOptions;
        let data = []
        let i = 0;
        this.state.stores.map(function(item, index){
          if(i < 1000){
            console.log(item);
              if(parseFloat(item.latitude) && parseFloat(item.longitude)){
                let cls = 'lmr-default';
                if(item.tim){
                  cls = 'lmr-'+item.tim;
                }
                  // data.push({ z: Math.random(), keyword: item.doncafe_code + ' ' + item.business_associates_doncafe_code + ' ' + item.name, lat: parseFloat(item.latitude), lon: parseFloat(item.longitude) })
                  data.push({z: Math.random(), keyword: item.doncafe_code + ' ' + item.business_associates_doncafe_code + ' ' + item.name, lat: parseFloat(item.latitude), lng: parseFloat(item.longitude), territory: item.territory, class: cls})
                  ////console.log('pushed');
                  i ++;
                }
              }

        },this)

        ////console.log('new data', data);
        // mo.series[1].data = data;
        // //console.log(mo);
        // this.setState({mapOptions: mo},()=>{
        //     //Highcharts.redraw();
        //     // update map!
        // });
        let stores = data;
        stores.map((store, index)=>{
            store.y = store.lat;
            store.x = store.lng;
        })
        

        
        this.setState({coordinates: data},()=>{
            console.log('coord', this.state.coordinates)
        })

    }

    getRegionOld=()=>{
        this.setState({regionStores: []});
      let territories = ['A1', 'A2', 'A3', 'A4', 'A5', 'A6'];
      territories.map((item,index)=>{
        let aRoute = this.state.selectedRegion+item;
          Service.get('/analytics/getStoresByTerritory',{territoryId: aRoute},(res)=>{
              let s = res.data.stores;
              
              s.map((item,index)=>{
                  item.x = parseFloat(item.longitude);
                  item.y = parseFloat(item.latitude);
                  item.sales = parseFloat(item.sellIn2019_prediction);
                  item.assigned = false;
              })

                let rsd = [];
                s.map((item,index)=>{
                    let obj = {}
                    for(var p in item){
                        obj[p] = item[p];
                    }
                    rsd.push(obj);
                })

                //let rs = Territories.createRoute(s, this.state.selectedDay, this.state.selectedWeek);
                let c = new TerritoriesClass(rsd, this.state.sections[this.state.selectedTerritory]);
                let rs = c.createRoute(this.state.selectedDay, this.state.selectedWeek);
                ////console.log('crr');
                this.setState({routes: rs});

                let rsr = this.state.regionStores;
                s.map((item)=>{
                    rsr.push(item);
                })
                this.setState({regionStores: rsr},()=>{
                    //console.log('region stores', this.state.regionStores)
                });
                

            })
        }
      )
    }

    setPolygon=(polygon)=>{
        this.setState({polygon: polygon});
        let polygonData = JSON.stringify(this.state.polygon);
       
        if(this.state.section && this.state.selectedTerritory){
          //console.log(polygonData, this.state.section, this.state.selectedTerritory)
          Service.get('/analytics/setTerritorySection', {territoryId: this.state.selectedTerritory, section: this.state.section, polygon: polygonData},(res)=>{
              this.getTerritories(true);
          })
        }
    }

    setSection=(event)=>{
      let section = event.target.getAttribute("data-section");
      this.setState({section: section})
    }

    getRegionRoutes=()=>{
      this.setState({loadingCsv: true},()=>{
          setTimeout(()=>{
              let d = [0,1,2,3,4];
              let dn = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
              let w = [1,2,3,4,5,6,7,8];
              //let w = [1];
              let r = [];
              let excel = [];
              r.push(['id','nielsen_id', 'territory', 'latitude', 'longitude', 'sales', 'type', 'day', 'week', 'visits', 'store name', 'address', 'municipality', 'city', 'active', 'MIS code']);
              w.map((week)=>{
                  d.map((day)=>{
                      let rsdi = [];
                      this.state.regionStores.map((item,index)=>{
                          let obj = {}
                          for(var p in item){
                              obj[p] = item[p];
                          }
                          rsdi.push(obj);
                      })

                      let cr = new TerritoriesClass(rsdi, this.state.sections[this.state.selectedTerritory]);
                      let rslt = cr.createRoute(day, week);

                      //console.log('ae REGIION', rslt);
                      rslt.map((item)=>{
                            let visits= '';
                            if(item.weeks.length == 8){
                              visits = 1;
                            }else if(item.weeks.length == 4){
                              visits = 2;
                            }else if(item.weeks.length == 2){
                              visits = 4;
                            }else if(item.weeks.length == 1){
                              visits = 8;
                            }
                          r.push([item.id, item.response_code_id, item.territory, item.latitude, item.longitude, item.sales, item.type, dn[day], week, visits, item.outlet_name, item.store_address, item.municipality, item.city_vllage, item.active, item.doncafe_code]);
                          excel.push({id: item.id, nielsen_id: item.response_code_id, territory: item.territory, label: item.label, latitude: item.latitude, longitude: item.longitude, sales: item.sales, type: item.type, day: dn[day], week: week, visits: visits, 
                                      outlet_name: item.outlet_name, address: item.store_address, municipality: item.municipality, city: item.city_vllage, active: item.active, doncafe_code: item.doncafe_code});
                      })
                      r.push(rslt);
                  })
              })

              this.setState({csvData: r, loadingCsv: false});
              this.setState({excelRegionData: excel},()=>{
                //console.log("EXCEL DATA", this.state.excelRegionData)
              });
              //console.log('csv data', r);
              
          }, 1000);
          
          ////console.log('csv data', r);
      });
      
  }

  /*
    for each territory
    get territory
    create csv container



  */

 getRegion=()=>{
        this.setState({regionStores: []});
      let territories = ['A1', 'A2', 'A3', 'A4', 'A5', 'A6'];
      territories.map((item,index)=>{
        let aRoute = this.state.selectedRegion+item;
          Service.get('/analytics/getStoresByTerritory',{territoryId: aRoute, version: this.state.selectedVersion},(res)=>{
              let s = res.data.stores;


              
              s.map((item,index)=>{
                  item.x = parseFloat(item.longitude);
                  item.y = parseFloat(item.latitude);
                  item.sales = parseFloat(item.sellIn2019_prediction);
                  item.assigned = false;
              })

                let rsd = [];
                s.map((item,index)=>{
                    let obj = {}
                    for(var p in item){
                        obj[p] = item[p];
                    }
                    rsd.push(obj);
                })

                let rs = [];
                //let rs = Territories.createRoute(s, this.state.selectedDay, this.state.selectedWeek);
                //let c = new TerritoriesClass(rsd);
                //let rs = c.createRoute(this.state.selectedDay, this.state.selectedWeek);
                ////console.log('crr');
                this.setState({routes: rs, regionStores: s, tStores: s, lastFunction: this.getRegion},()=>{
                    //this.getTerritoryRoutes();
                });

                // let rsr = this.state.regionStores;
                // s.map((item)=>{
                //     rsr.push(item);
                // })
                // this.setState({regionStores: rsr},()=>{
                //     //console.log('region stores', this.state.regionStores)
                // });
                

            })
        }
      )
    }



    

    getTerritory=()=>{
        if(this.state.selectedTerritory.charAt(2) == 'A' && this.state.selectedTerritory != 'All'){
            Service.get('/analytics/getStoresByTerritory',{territoryId: this.state.selectedTerritory, version: this.state.selectedVersion},(res)=>{
                let s = res.data.stores;
                console.log('s',s);
                
                s.map((item,index)=>{
                    item.x = parseFloat(item.longitude);
                    item.y = parseFloat(item.latitude);
                    item.sales = parseFloat(item.sellIn2019_prediction);
                    item.assigned = false;
                    //item.response_code_id = item.response_code_id;
                })

                let rsd = [];
                s.map((item,index)=>{
                    let obj = {}
                    for(var p in item){
                        obj[p] = item[p];
                    }
                    rsd.push(obj);
                })

                //let rs = Territories.createRoute(s, this.state.selectedDay, this.state.selectedWeek);
                let c = new TerritoriesClass(rsd, this.state.sections[this.state.selectedTerritory]);
                let rs = c.createRoute(this.state.selectedDay, this.state.selectedWeek);

                let sections= ['north','east','south', 'west', 'center'];
                let daysCheck = [0,0,0,0,0];
                console.log('rs', rs);
                rs.map((item)=>{
                    daysCheck[item.group] ++;
                    if(item.group != 0 && item.group != 1 && item.group != 2 && item.group != 3 && item.group != 4){
                        console.log('ig', item.group);
                    }
                })
                console.log('dc', daysCheck);
                this.setState({territoryStoreCount: daysCheck});
                this.setState({routes: rs, tStores: s, storesCount: s.length, lastFunction: this.getTerritory});
                

            })
        }
        // call full territory if all selected
        if(this.state.selectedTerritory === 'All'){

            // update map with all the stores
            this.updateMapOutside();
        }
    }

    
    getTerritoryRoutes=()=>{
        this.setState({loadingCsv: true},()=>{
            setTimeout(()=>{
                let d = [0,1,2,3,4];
                let dn = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
                let w = [1,2,3,4,5,6,7,8];
                //let w = [1];
                let r = [];
                let check = [];
                let excel = [];
                //console.log('tStores', this.state.tStores);
                this.state.tStores.map((item,index)=>{
                    if(!check.includes(item.id)){
                        check.push(item.id)
                    }
                })
                //console.log('check', check);
                let count = 0;
                    r.push(['id', 'territory', 'latitude', 'longitude', 'sales', 'type', 'day', 'week', 'visits', 'store name', 'address', 'municipality', 'city', 'active', 'MIS code']);                w.map((week)=>{
                    d.map((day)=>{
                        let rsdi = [];
                        this.state.tStores.map((item,index)=>{
                            let obj = {}
                            for(var p in item){
                                obj[p] = item[p];
                            }
                            rsdi.push(obj);
                        })
                        let cr = new TerritoriesClass(rsdi, this.state.sections[this.state.selectedTerritory]);
                        //console.log('aaaaaa');
                        let rslt = cr.createRoute(day, week);
                        
                        //console.log('dwr', day, week, cr, rslt);
                        
                        rslt.map((item)=>{
                                //console.log('dwk', item.weeks.toString());
                              let visits= '';
                              if(item.weeks.length == 8){
                                visits = 1;
                              }else if(item.weeks.length == 4){
                                visits = 2;
                              }else if(item.weeks.length == 2){
                                visits = 4;
                              }else if(item.weeks.length == 1){
                                visits = 8;
                              }else{
                                  //console.log('missing')
                              }
                                count ++;
                                //console.log(item);
                                r.push([item.id, item.territory, item.latitude, item.longitude, item.sales, item.type, dn[day], week, visits, item.outlet_name, item.store_address, item.municipality, item.city_vllage, item.active, item.doncafe_code]);
                                excel.push({id: item.id, nielsen_id: item.store_response_id, territory: item.territory, label: item.label, latitude: item.latitude, longitude: item.longitude, sales: item.sales, type: item.type, day: dn[day], week: week, visits: visits, 
                                        outlet_name: item.outlet_name, address: item.store_address, municipality: item.municipality, city: item.city_vllage, active: item.active, doncafe_code: item.doncafe_code});
                        })
                        //r.push(rslt);
                    })
                })
                let checkExcel = [];
                excel.map((item,index)=>{
                    if(!checkExcel.includes(item.id)){
                        checkExcel.push(item.id)
                    }
                })
                //console.log('che',checkExcel, count);

                this.setState({csvData: r, loadingCsv: false});
                let ex = this.state.excelData.concat(excel);
                this.setState({excelData: ex});
                //console.log('excel data', excel);
                
            }, 1000);
            
            ////console.log('csv data', r);
        });
        
    }

    getTerritories=(update=null)=>{
        Service.get('/analytics/getAllTerritories',{},(res)=>{
            let t = ['all']
            let sections = {};
            res.data.map(function(item, index){
                t.push(item.label);
                sections[item.label] = {};
                if(item.north){
                   sections[item.label].north = JSON.parse(item.north);
                }else{
                    sections[item.label].north = null;
                }
                if(item.north){
                   sections[item.label].east = JSON.parse(item.east);
                }else{
                    sections[item.label].east = null;
                }
                if(item.north){
                   sections[item.label].south = JSON.parse(item.south);
                }else{
                    sections[item.label].south = null;
                }
                if(item.north){
                   sections[item.label].west = JSON.parse(item.west);
                }else{
                    sections[item.label].west = null;
                }
                if(item.north){
                   sections[item.label].center = JSON.parse(item.center);
                }else{
                    sections[item.label].center = null;
                }
                //sections[item.label] = {north: JSON.parse(item.north), east: JSON.parse(item.east), south: JSON.parse(item.south), west: JSON.parse(item.west), center: JSON.parse(item.center)}
            })
            this.setState({territories: t, lastFunction: this.getTerritories, sections: sections},()=>{
                //console.log('territories', this.state.territories);
                if(update){
                    this.getTerritory();
                }
            })
        })
        
    }


    selectTerritory=(event)=>{
        this.setState({selectedTerritory: event.target.value},()=>{
            this.getTerritory();
        })
    }

 

    selectDay=(event)=>{
        this.setState({selectedDay: event.target.value},()=>{
            this.getTerritory();
        })
    }
    selectWeek=(event)=>{
        this.setState({selectedWeek: event.target.value},()=>{
            this.getTerritory();
        })
    }

    selectRegion=(event)=>{
      this.setState({selectedRegion: event.target.value},()=>{
          this.getStoresByRegion();
          this.getRegion();
      })
  }



    assignStore=(event)=>{
      let tid= event.target.getAttribute("data-territory");
      Service.get('/analytics/setStoreTerritory',{territoryId: tid, nielsenId: this.state.selectedStore, version: this.state.selectedVersion},(res)=>{
        alert(`Store ${this.state.selectedStore} assigned to territory ${tid}`)
        this.getTerritory();
      })
    }

    moveStores=(event)=>{
        let tid= event.target.getAttribute("data-territory");
        //console.log('ms', {polygon: this.state.polygon, territoryFrom: this.state.selectedTerritory, territoryTo: tid, version: this.state.selectedVersion})
      
      Service.get('/import/moveSelectedStores',{polygon: this.state.polygon, territoryFrom: this.state.selectedTerritory, territoryTo: tid, version: this.state.selectedVersion},(res)=>{
        alert(`Stores moved to territory ${tid}`)
            this.getTerritory();
      })
    }

    

    getStoresByRegion=()=>{
      Service.get('/analytics/getStoresByRegion',{regionId: this.state.selectedRegion, limit: "3500"},(res)=>{
        this.setState({regions: res.data.stores},()=>{
          this.updateRegions();
        })
        
      })
    }

    updateRegions=()=>{
      let data=[];
      this.state.regions.map(function(item, index){
        let cls = 'lmr-default';
        if(item.tim){
          cls = 'lmr-'+item.tim;
        }
        // data.push({lat: item.latitude, lng: item.longitude, territory: item.territory, class: 'leaflet-marker-'+item.territory, label: item.territory})
        data.push({lat: item.latitude, lng: item.longitude, territory: item.territory, class: 'lmr-default', label: item.territory})
      })
      this.setState({routes: data})
    }

    selectVersion=(event)=>{
      this.setState({selectedVersion: event.target.value},()=>{
        //   if(this.state.lastFunction){
        //     this.state.lastFunction.apply();
        //   }
        this.getTerritory();
      })
    }

    selectGroup = (event) => {
        this.setState({selectedGroup: event.target.value},()=>{
            this.updateMapOutside();
        });
    }

   

  render() {
    return (
      <div className="content">
          <h1>Map</h1>
          <p>Please select groups range:</p>
          <div>
          <select  value={this.state.selectedGroup} onChange={this.selectGroup}>
            {displayGroups.map((item) => {
                return(
                    <option value={item}>{((item * groupCount) - groupCount)+'-'+ (item * groupCount)}</option>
                )
            })

            }
          </select>
          </div>
          <div id="open-map">
            {/* {this.state.defMap == true &&
                <OpenMap coordinates={this.state.coordinates}/>
            } */}
            {this.state.defMap == false &&
                <OpenMap coordinates={this.state.routes} getMarkerData={this.getMarkerData} setPolygon={this.setPolygon}/>
            }
          </div>
          <div className='route-teams'>
            {teams.map(item =>{
              return <button onClick={() => this.setState({selectedTeam: item}, ()=>{this.generateExcelData()})} className={this.state.selectedTeam == item ? 'team-selected lmr-'+item+' team-button' : 'lmr-'+item+' team-button'} data-team-id={item} >TIM {item}</button>
            })}
          </div>
          <CSVLink 
                    // data={this.state.storesOutside}
                    data={this.state.excelData}
                    filename={"doncafe-routes-tim-"+this.state.selectedTeam+".csv"}
                    className="keyword"
                >Export CSV</CSVLink>
            {/* <Ex storesOutside={this.state.storesOutside} /> */}
          {/* <ExcelFile element={<button className="keyword">Export excel</button>} filename={"doncafe-sales-routes"}>
            <ExcelSheet data={this.state.storesOutside} name={"doncafe-sales-routes"}>
              <ExcelColumn label="Tim" value="tim" />
              <ExcelColumn label="Grupa" value="grupa" />
            </ExcelSheet>
          </ExcelFile> */}

          
      </div>
      
    )
  }
}

export default Routes;